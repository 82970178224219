import React, { useState } from "react";
import ReactFromJSON from "react-from-json";
import Node from "./Nodes";
import Arrows from "../../../../components/chart/links/arrow/Arrows";
import { Row, Button, Typography, Tooltip, Popover } from "antd";

import * as Scroll from "react-scroll";

import { ContentComponents } from "../../../../components/chart/mapping/ContentComponents";
import { ContentLinkExternal } from "../../../../components/chart/mapping/ContentLinkExternal";
import { ClientExamplesComponents } from "../../../../components/chart/mapping/ClientExamplesComponents";

import "./styles.scss";
import { InfoCircleOutlined, FolderOpenOutlined } from "@ant-design/icons";
const { Title, Text, Paragraph, Link } = Typography;
const scroller = Scroll.scroller;

export default function Chart3ieSchematic({
  entry,
  firstNodeId,
  startDemo,
  useStartDemoProp,
  language,
  useShowAllNodes,
  showNodeType,
}) {
  const [nodePath, setNodePath] = useState([firstNodeId]);
  const [isLastOnPath, setIsLastOnPath] = useState(firstNodeId);
  const [showAllNodes, setShowAllNodes] = useState(useShowAllNodes);

  const onNext = (nodeId) => {
    const nodeCurrentPosition = nodePath.indexOf(nodeId);
    const previousNode = nodePath.slice(0, nodeCurrentPosition);
    if (previousNode.length === 0) {
      setShowAllNodes(true);
    }
    console.log(nodePath);
    if (nodeCurrentPosition === -1) {
      const addNextNode = nodePath.concat([nodeId]);
      setNodePath(addNextNode);
      const nextNodeId = nodeId.toString();
      setIsLastOnPath(nextNodeId);
      const windowHeight = window.innerHeight;
      const scrollOffset = 0.33 * windowHeight;
      setTimeout(function () {
        if (!("parentIFrame" in window)) {
          scroller.scrollTo(nextNodeId, {
            duration: 300,
            delay: 25,
            smooth: true,
            offset: -scrollOffset,
            isDynamic: true,
          });
        }
      }, 50);
      setTimeout(function () {
        const contentHeight = document.getElementById(nodeId).offsetTop;
        const contentHeightNew = contentHeight - 125;
        if ("parentIFrame" in window) {
          window.parentIFrame.scrollToOffset(0, contentHeightNew);
        }
      }, 50);
    } else {
      onGoTo(nodeId);
    }
  };

  const onGoTo = (nodeId) => {
    const nodeCurrentPosition = nodePath.indexOf(nodeId);
    if (nodeCurrentPosition !== -1) {
      const goToNode = nodePath.slice(0, nodeCurrentPosition + 1);
      setNodePath(goToNode);
      const goToNodeId = nodeId.toString();
      setIsLastOnPath(goToNodeId);
      const windowHeight = window.innerHeight;
      const scrollOffset = 0.33 * windowHeight;
      setTimeout(function () {
        if (!("parentIFrame" in window)) {
          scroller.scrollTo(goToNodeId, {
            duration: 300,
            delay: 25,
            smooth: true,
            offset: -scrollOffset,
            isDynamic: true,
          });
        }
      }, 50);
      setTimeout(function () {
        const contentHeight = document.getElementById(nodeId).offsetTop;
        const contentHeightNew = contentHeight - 125;
        if ("parentIFrame" in window) {
          window.parentIFrame.scrollToOffset(0, contentHeightNew);
        }
      }, 50);
    }
  };

  const onPrevious = (nodeId) => {
    const nodeCurrentPosition = nodePath.indexOf(nodeId);
    const previousNode = nodePath.slice(0, nodeCurrentPosition);
    if (previousNode.length > 0) {
      setNodePath(previousNode);
      const previousNodeId = nodePath[nodePath.length - 2].toString();
      setIsLastOnPath(previousNodeId);
      const windowHeight = window.innerHeight;
      const scrollOffset = 0.25 * windowHeight;
      setTimeout(function () {
        if (!("parentIFrame" in window)) {
          scroller.scrollTo(previousNodeId, {
            duration: 300,
            delay: 25,
            smooth: true,
            offset: -scrollOffset,
            isDynamic: true,
          });
        }
      }, 50);
      setTimeout(function () {
        const contentHeight = document.getElementById(nodeId).offsetTop;
        const contentHeightNew = contentHeight + 125;
        if ("parentIFrame" in window) {
          window.parentIFrame.scrollToOffset(0, contentHeightNew);
        }
      }, 50);
    }
  };

  // const onResetTo = (nodeId) => {
  //   const nodeCurrentPosition = nodePath.indexOf(nodeId);
  //   const resetToNode = nodePath.slice(0, nodeCurrentPosition - 1);
  //   if (resetToNode.length > 0) {
  //     setNodePath(resetToNode);
  //     const resetToNodeId = nodePath[nodePath.length - 3].toString();
  //     setIsLastOnPath(resetToNodeId);
  //     const windowHeight = window.innerHeight;
  //     const scrollOffset = 0.25 * windowHeight;
  //     setTimeout(function () {
  //       if (!("parentIFrame" in window)) {
  //         scroller.scrollTo(resetToNodeId, {
  //           duration: 300,
  //           delay: 25,
  //           smooth: true,
  //           offset: -scrollOffset,
  //           isDynamic: true,
  //         });
  //       }
  //     }, 50);
  //     setTimeout(function () {
  //       const contentHeight = document.getElementById(nodeId).offsetTop;
  //       const contentHeightNew = contentHeight - 125;
  //       if ("parentIFrame" in window) {
  //         window.parentIFrame.scrollToOffset(0, contentHeightNew);
  //       }
  //     }, 50);
  //   }
  // };

  // const onGoToNode11 = (nodeId) => {
  //   const nodeCurrentPosition = nodePath.indexOf(nodeId);
  //   if (nodeCurrentPosition !== -1) {
  //     const goToNode = nodePath.slice(0, nodeCurrentPosition + 1);
  //     setNodePath(goToNode);
  //     const goToNodeId = nodeId.toString();
  //     setIsLastOnPath(goToNodeId);
  //     const windowHeight = window.innerHeight;
  //     const scrollOffset = 0.33 * windowHeight;
  //     setTimeout(function () {
  //       if (!("parentIFrame" in window)) {
  //         scroller.scrollTo(goToNodeId, {
  //           duration: 300,
  //           delay: 25,
  //           smooth: true,
  //           offset: -scrollOffset,
  //           isDynamic: true,
  //         });
  //       }
  //     }, 50);
  //     setTimeout(function () {
  //       const contentHeight = document.getElementById(nodeId).offsetTop;
  //       const contentHeightNew = contentHeight - 125;
  //       if ("parentIFrame" in window) {
  //         window.parentIFrame.scrollToOffset(0, contentHeightNew);
  //       }
  //     }, 50);
  //   }
  // };

  const onResetToSix = (nodeId) => {
    const nodeCurrentPosition = nodePath.indexOf(nodeId);
    const resetToNode = nodePath.slice(0, nodeCurrentPosition - 2);
    if (resetToNode.length > 0) {
      setNodePath(["node6"]);
      const resetToNodeId = "node6";
      console.log(resetToNodeId);
      setIsLastOnPath(resetToNodeId);
      const windowHeight = window.innerHeight;
      const scrollOffset = 0.25 * windowHeight;
      setTimeout(function () {
        if (!("parentIFrame" in window)) {
          scroller.scrollTo(resetToNodeId, {
            duration: 300,
            delay: 25,
            smooth: true,
            offset: -scrollOffset,
            isDynamic: true,
          });
        }
      }, 50);
      setTimeout(function () {
        const contentHeight = document.getElementById("node6").offsetTop;
        const contentHeightNew = contentHeight - 125;
        if ("parentIFrame" in window) {
          window.parentIFrame.scrollToOffset(0, contentHeightNew);
        }
      }, 50);
    }
  };

  const onResetTo1c = (nodeId) => {
    const nodeCurrentPosition = nodePath.indexOf(nodeId);
    const resetToNode = nodePath.slice(0, nodeCurrentPosition - 2);
    if (resetToNode.length > 0) {
      setNodePath(["node1c"]);
      const resetToNodeId = "node1c";
      console.log(resetToNodeId);
      setIsLastOnPath(resetToNodeId);
      const windowHeight = window.innerHeight;
      const scrollOffset = 0.25 * windowHeight;
      setTimeout(function () {
        if (!("parentIFrame" in window)) {
          scroller.scrollTo(resetToNodeId, {
            duration: 300,
            delay: 25,
            smooth: true,
            offset: -scrollOffset,
            isDynamic: true,
          });
        }
      }, 50);
      setTimeout(function () {
        const contentHeight = document.getElementById("node1c").offsetTop;
        const contentHeightNew = contentHeight - 125;
        if ("parentIFrame" in window) {
          window.parentIFrame.scrollToOffset(0, contentHeightNew);
        }
      }, 50);
    }
  };

  const onReset = () => {
    const resetNode = firstNodeId;
    setNodePath([resetNode]);
    const resetNodeId = firstNodeId.toString();
    setIsLastOnPath(resetNodeId);
    const windowHeight = window.innerHeight;
    const scrollOffset = 0.25 * windowHeight;
    setTimeout(function () {
      if (!("parentIFrame" in window)) {
        scroller.scrollTo(resetNodeId, {
          duration: 300,
          delay: 25,
          smooth: true,
          offset: -scrollOffset,
          isDynamic: true,
        });
      }
    }, 50);
    setTimeout(function () {
      if ("parentIFrame" in window) {
        window.parentIFrame.scrollToOffset(0, -90);
      }
    }, 50);
  };

  const mapping = {
    chart: ({ nodes }) => <>{nodes}</>,
    node: ({
      alignSelf,
      content,
      disableDefaultOptionsButtons,
      extraContent,
      extraContentTitle,
      isEndNode,
      links,
      nodeId,
      nodeTitle,
      nodeType,
      offset,
      options,
      span,
    }) => (
      <>
        <Node
          alignSelf={alignSelf}
          disableDefaultOptionsButtons={disableDefaultOptionsButtons}
          enableScrolling
          extraContent={extraContent}
          extraContentTitle={extraContentTitle}
          isEndNode={isEndNode}
          isFirstOnPath={firstNodeId === nodeId}
          isLastOnPath={isLastOnPath === nodeId}
          isOnPath={nodePath.indexOf(nodeId) !== -1}
          key={nodeId}
          language={language}
          links={links}
          linksActive={
            nodePath[nodePath.length - 1] !== nodeId
              ? nodePath[nodePath.indexOf(nodeId) + 1]
              : null
          }
          nodeId={nodeId}
          nodeTitle={nodeTitle}
          nodeType={nodeType}
          offset={offset}
          onGoTo={onGoTo}
          onNext={onNext}
          onPrevious={onPrevious}
          onReset={onReset}
          onResetToSix={onResetToSix}
          onResetTo1c={onResetTo1c}
          options={options}
          previousNode={nodePath}
          showAllNodes={showAllNodes}
          span={span}
          startDemo={startDemo}
          useStartDemoProp={useStartDemoProp}
          showNodeType={showNodeType}
        >
          {content}
        </Node>
        <Arrows
          arrowColorActive="#8FB132"
          arrowColorInactive="#adb5bd"
          gridBreak="100%-20"
          isFirstOnPath={firstNodeId === nodeId}
          isLastOnPath={isLastOnPath}
          links={links}
          linksActive={
            nodePath[nodePath.length - 1] !== nodeId
              ? nodePath[nodePath.indexOf(nodeId) + 1]
              : null
          }
          nodeId={nodeId}
        />
      </>
    ),
    ...ClientExamplesComponents,
    ...ContentComponents,
    ...ContentLinkExternal,
    ResetToNode6: () => (
      <>
        <Button
          type="primary"
          style={{ marginTop: "6px", marginRight: "6px", whitespace: "normal" }}
          onClick={() => onNext("node11")}
        >
          Yes
        </Button>
        <Tooltip
          placement="top"
          title="Selecting this will take you to some advice for alternative ways to search, and give you the option to restart from the beginning."
        >
          <Button
            type="primary"
            style={{
              marginTop: "6px",
              marginRight: "6px",
              whitespace: "normal",
            }}
            onClick={() => onResetToSix()}
          >
            No
          </Button>
        </Tooltip>
      </>
    ),
    ResetToNode1c: () => (
      <>
        <Button
          type="primary"
          style={{
            marginTop: "6px",
            marginRight: "6px",
            whitespace: "normal",
          }}
          onClick={() => onResetTo1c()}
        >
          Next
        </Button>
      </>
    ),
    row: ({ children }) => <Row>{children}</Row>,
    guidanceHint: ({ children }) => (
      <Paragraph style={{ fontSize: "10px" }}>
        <InfoCircleOutlined style={{ marginRight: "5px" }} />
        See '{children}' in the sidebar for guidance
      </Paragraph>
    ),
    popover: ({ trigger, title, content }) => (
      <Popover
        autoAdjustOverflow
        content={content}
        title={title}
        trigger="click"
        overlayStyle={{ width: "32.5vw" }}
        overlayInnerStyle={{ fontSize: "85%" }}
      >
        <Paragraph>
          <Link type="link" style={{ paddingLeft: "3px" }}>
            <FolderOpenOutlined /> {trigger}
          </Link>
        </Paragraph>
      </Popover>
    ),
  };

  return (
    <>
      <Row className="chart-basic-schematic-test chart-3ie" align="top">
        <ReactFromJSON entry={entry} mapping={mapping} />
      </Row>
    </>
  );
}
