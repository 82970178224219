import React, { useState } from "react";
import { Drawer, Button, Typography } from "antd";
import { useDebounceFn } from "ahooks";

const { Link, Paragraph, Title } = Typography;

export default function ContentModalWithButtons({
  content,
  key,
  links,
  onNext,
  title,
  trigger,
  nodeTitle,
}) {
  const [isClicked, setIsClicked] = useState(false);
  const [showDrawer, setShowDrawer] = useState(false);
  const { run } = useDebounceFn(
    (k) => {
      onNext(k);
    },
    {
      wait: 250,
    }
  );

  return (
    <>
      <Paragraph strong>
        <Link
          type="link"
          onClick={() => {
            setShowDrawer(true);
          }}
          className="trigger-modal link"
        >
          {trigger} »
        </Link>
      </Paragraph>
      <Drawer
        drawerStyle={{ backgroundColor: "white" }}
        className="modal-katnh styles-katnh"
        width="85%"
        onCancel={() => {
          setShowDrawer(false);
        }}
        onClose={() => {
          setShowDrawer(false);
        }}
        title={title}
        visible={showDrawer}
      >
        <img
          src="/client/katnh/small_dots.svg"
          alt="divider"
          style={{ height: "3px", margin: "16px 0" }}
        />
        {content}
        <img
          src="/client/katnh/small_dots.svg"
          alt="divider"
          style={{ height: "3px", margin: "16px 0" }}
        />
        <div
          style={{
            backgroundColor: "#1d396e",
            padding: "15px",
            color: "#c9d22as",
          }}
        >
          <Title
            style={{
              fontSize: "20px",
              fontWeight: "normal",
              letterSpacing: "0.66px",
              color: "#c9d22a",
            }}
            className="green"
          >
            Maak uw keuze:
          </Title>
          {links.link.map((item) => (
            <Button
              key={key}
              onClick={() => {
                setIsClicked(true);
                run(item.nodeId);
              }}
              style={{
                height: "41px",
                padding: "0 15px",
                marginTop: "15px",
                marginRight: "15px",
                whitespace: "normal",
                border: "2px solid white",
                borderRadius: "0",
                backgroundColor: "#1d396e",
                color: "white",
                fontSize: "18px",
              }}
            >
              {item.text}
            </Button>
          ))}
        </div>
      </Drawer>
    </>
  );
}
