import React, { Component } from "react";
import ReactFromJSON from "react-from-json";
import Node from "./ChartNodes";
import {
  Button,
  Carousel,
  Descriptions,
  Divider,
  Result,
  Row,
  Typography,
} from "antd";
import { Link } from "gatsby";
import * as Scroll from "react-scroll";
import "../styles/testclient_chart.scss";
import Weather from "../widgets/weather";

const scroller = Scroll.scroller;
const { Paragraph, Text, Title } = Typography;

export default class ChartCollapse extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // The 'path' of 'nodes' is being tracked in the 'nodePath' array in the react state.
      nodePath: [this.props.firstNodeId],
      // Set firstNodeId with props
      firstNodeId: this.props.firstNodeId,
      // isLastOnPath
      isLastOnPath: this.props.firstNodeId,
    };
  }

  // Next Node
  onNext = (nodeId) => {
    // Check what the current position of the node is in our Path
    const nodeCurrentPosition = this.state.nodePath.indexOf(nodeId);
    // console.log("nodeCurrentPosition:", nodeCurrentPosition);
    // Check if the next node is on our Path already. If not, this node will be added to our Path.
    if (nodeCurrentPosition === -1) {
      // Concat the node to our path / state.
      const addNextNode = this.state.nodePath.concat([nodeId]);
      // console.log("addNextNode:", addNextNode);
      this.setState({
        nodePath: addNextNode,
      });
      // Get the value of the next node as a string to be able to scroll and update state.
      const nextNodeId = nodeId.toString();
      // console.log("nextNodeId:", nextNodeId);
      this.setState({
        isLastOnPath: nextNodeId,
      });
      // Scroll to the next Node if not iFramed
      const windowHeight = window.innerHeight;
      const scrollOffset = 0.2 * windowHeight;
      setTimeout(function () {
        if (!("parentIFrame" in window)) {
          scroller.scrollTo(nextNodeId, {
            duration: 300,
            delay: 25,
            smooth: true,
            offset: -scrollOffset,
            isDynamic: true,
          });
        }
      }, 50);
      // Scroll Parent
      setTimeout(function () {
        const contentHeight = document.getElementById(nodeId).offsetTop;
        const contentHeightNew = contentHeight - 125;
        if ("parentIFrame" in window) {
          window.parentIFrame.scrollToOffset(0, contentHeightNew);
        }
      }, 50);
      // If the next node is already in our Path we can't add it again. We handle it with goTo.
    } else {
      this.onGoTo(nodeId);
    }
  };

  // Go To Node
  onGoTo = (nodeId) => {
    // Check what the current position of the node is in our Path.
    const nodeCurrentPosition = this.state.nodePath.indexOf(nodeId);
    console.log("nodeCurrentPosition:", nodeCurrentPosition);
    // If the position of this node exists so we can update with this node.
    if (nodeCurrentPosition !== -1) {
      // Get to value of the next node and setState nodePath.
      const goToNode = this.state.nodePath.slice(0, nodeCurrentPosition + 1);
      // console.log("goToNode:", goToNode);
      this.setState({
        nodePath: goToNode,
      });
      const goToNodeId = nodeId.toString();
      // console.log("goToNodeId:", goToNodeId);
      this.setState({
        isLastOnPath: goToNodeId,
      });
      // Scroll to the next Node if not iFramed
      const windowHeight = window.innerHeight;
      const scrollOffset = 0.2 * windowHeight;
      setTimeout(function () {
        if (!("parentIFrame" in window)) {
          scroller.scrollTo(goToNodeId, {
            duration: 300,
            delay: 25,
            smooth: true,
            offset: -scrollOffset,
            isDynamic: true,
          });
        }
      }, 50);

      // Scroll Parent
      setTimeout(function () {
        const contentHeight = document.getElementById(nodeId).offsetTop;
        const contentHeightNew = contentHeight - 125;
        if ("parentIFrame" in window) {
          window.parentIFrame.scrollToOffset(0, contentHeightNew);
        }
      }, 50);
    }
  };

  // Previous Node (back)
  onPrevious = (nodeId) => {
    // Check what the current position of the node is in our Path
    const nodeCurrentPosition = this.state.nodePath.indexOf(nodeId);
    // console.log("nodeCurrentPosition:", nodeCurrentPosition);
    const previousNode = this.state.nodePath.slice(0, nodeCurrentPosition);
    // console.log("previousNode:", previousNode);
    // Check if previous node exits
    if (previousNode.length > 0) {
      // setState nodePath
      this.setState({
        nodePath: previousNode,
      });
      // ScrollTo and update state
      const previousNodeId =
        this.state.nodePath[this.state.nodePath.length - 2].toString();
      // console.log("previousNodeId:", previousNodeId);
      this.setState({
        isLastOnPath: previousNodeId,
      });
      const windowHeight = window.innerHeight;
      const scrollOffset = 0.15 * windowHeight;
      // scroll to previous node if not iframed
      setTimeout(function () {
        if (!("parentIFrame" in window)) {
          scroller.scrollTo(previousNodeId, {
            duration: 300,
            delay: 25,
            smooth: true,
            offset: -scrollOffset,
            isDynamic: true,
          });
        }
      }, 50);
      // Scroll Parent
      setTimeout(function () {
        const contentHeight = document.getElementById(nodeId).offsetTop;
        const contentHeightNew = contentHeight - 125;
        if ("parentIFrame" in window) {
          window.parentIFrame.scrollToOffset(0, contentHeightNew);
        }
      }, 50);
    }
  };

  // Reset Nodes
  onReset = () => {
    // Get value of the first node in our chart and reset path
    const resetNode = this.state.firstNodeId;
    // console.log("resetNode:", resetNode);
    this.setState({
      nodePath: [resetNode],
    });
    // Scroll to
    const resetNodeId = this.state.firstNodeId.toString();
    // console.log("resetNodeId:", resetNodeId);
    // Update state
    this.setState({
      isLastOnPath: resetNodeId,
    });
    const windowHeight = window.innerHeight;
    const scrollOffset = 0.15 * windowHeight;
    // scroll to previous node if not iframed
    setTimeout(function () {
      if (!("parentIFrame" in window)) {
        scroller.scrollTo(resetNodeId, {
          duration: 300,
          delay: 25,
          smooth: true,
          offset: -scrollOffset,
          isDynamic: true,
        });
      }
    }, 50);
    // Scroll parent
    setTimeout(function () {
      if ("parentIFrame" in window) {
        window.parentIFrame.scrollToOffset(0, -90);
      }
    }, 50);
  };

  render = () => {
    const mapping = {
      chart: ({ nodes }) => <>{nodes}</>,
      node: ({ nodeId, nodeTitle, links, content, isEndNode }) => (
        <Node
          nodeId={nodeId}
          nodeTitle={nodeTitle}
          links={links}
          //
          key={nodeId}
          onNext={this.onNext}
          onGoTo={this.onGoTo}
          onPrevious={this.onPrevious}
          onReset={this.onReset}
          //
          linksActive={
            this.state.nodePath[this.state.nodePath.length - 1] !== nodeId
              ? this.state.nodePath[this.state.nodePath.indexOf(nodeId) + 1]
              : null
          }
          //
          isOnPath={this.state.nodePath.indexOf(nodeId) !== -1}
          isFirstOnPath={this.state.firstNodeId === nodeId}
          isLastOnPath={this.state.isLastOnPath === nodeId}
          isEndNode={isEndNode}
        >
          {content}
        </Node>
      ),
      button: ({ text, type, ghost, to, disabled }) => (
        <Paragraph>
          <Button type={type} ghost={ghost} disabled={disabled}>
            <Link to={to}>{text}</Link>
          </Button>
        </Paragraph>
      ),
      carousel: ({ slides }) => (
        <Carousel
          autoplay
          style={{
            height: "400px",
            backgroundColor: "#364d79",
            textAlign: "center",
          }}
          effect="fade"
        >
          {slides}
        </Carousel>
      ),
      divider: () => <Divider dashed style={{ margin: "12px 0" }} />,
      description: ({ items }) => (
        <Descriptions
          bordered
          size="small"
          column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
        >
          {items}
        </Descriptions>
      ),
      descriptionItem: ({ label, text }) => (
        <Descriptions.Item label={label} span={3}>
          {text}
        </Descriptions.Item>
      ),
      paragraph: ({ children, text }) => (
        <Paragraph>
          {text} {children}
        </Paragraph>
      ),
      result: ({ title, status }) => <Result title={title} status={status} />,
      title: ({ title, level }) => <Title level={level}>{title}</Title>,
      text: ({
        text,
        type,
        code,
        copyable,
        disabled,
        ellipsis,
        mark,
        keyboard,
        underline,
        strong,
      }) => (
        <Text
          type={type}
          code={code}
          copyable={copyable}
          disabled={disabled}
          ellipsis={ellipsis}
          mark={mark}
          keyboard={keyboard}
          underline={underline}
          strong={strong}
        >
          {text}
        </Text>
      ),
      image: ({ src, alt }) => (
        <div
          style={{
            // height: '250px',
            textAlign: "center",
          }}
        >
          <img
            src={src}
            alt={alt}
            style={{ height: "400px", margin: "0 auto" }}
          />
        </div>
      ),
      weather: () => <Weather />,
    };

    return (
      <>
        <Row
          type="flex"
          align={this.props.align}
          className="chart-testclient"
          style={{ position: "relative" }}
        >
          <ReactFromJSON entry={this.props.entry} mapping={mapping} />
          <div id="bottom"></div>
        </Row>
      </>
    );
  };
}
