import React from "react";

import { Steps, Button, Icon, Row, Col, Divider } from "antd";
import ReactHtmlParser from "react-html-parser";

const ButtonGroup = Button.Group;

const Step = Steps.Step;

const steps = [
  {
    title: "Therapietrouw",
    content:
      "<ul><li>Door INR controles is er goed zicht op de therapietrouw bij VKA-patiënten</li><li>Vraag vooral naar therapietrouw en interacterende medicatie en voedingssupplementen bij slecht ingestelde INR waarde</li></ul>",
  },
  {
    title: "Bijwerkingen",
    content: "<p>Vraag gericht naar bijwerkingen, vooral bloedingen.</p>",
  },
  {
    title: "Dosering",
    content:
      "<p>Instelling van de dosering vindt plaats door de trombosedienst.</p>",
  },
  {
    title: "Trombo-embolie",
    content:
      "<ul><li>Beoordeel of indicatie nog geldig is</li><li>Herbeoordeel trombotische risicofactoren</li><li>Vraag naar opgetreden trombo-embolische complicaties</li><li>Indien opgetreden, informeer de trombosedienst</li></ul>",
  },
  {
    title: "Bloeding",
    content:
      "<ul><li>Benadruk belang therapietrouw, juist bij klinisch relevante niet-majeure bloedingen die een (grote) impact kunnen hebben op kwaliteit van leven en therapietrouw.</li><li>Beoordeel en pak potentieel beïnvloedbare risicofactoren op een bloeding aan, zoals RR systolisch > 160 mm Hg, gebruik NSAIDs/TARS, overmatig alcoholgebruik, diabetische retinopathie, nierfalen, leverenzymstoornis of leverinsufficiëntie , slecht ingestelde INR-waarde, onderliggend lijden zoals maligniteit (cave trombopenie), interacterende medicatie.</li><li>Indien opgetreden, informeer de trombosedienst</li></ul>",
  },
  {
    title: "Interacties",
    content:
      '<ul><li>Zorg voor een volledig medicatie overzicht</li><li>Check interacties met andere geneesmiddelen, zie <a href="https://www.fnt.nl/kwaliteit/cumarine-interacties" target="_blank" rel="noreffer noopener">FNT</a> of <a href="https://www.farmacotherapeutischkompas.nl/" target="_blank" rel="noreffer noopener">Farmacotherapeutisch Kompas</a>. Let op effect van wegnemen van interacterende geneesmiddelen.</li></ul>',
  },
  {
    title: "Geplande operaties / ingrepen",
    content:
      "<ul><li>De (tand)arts die de operatie of ingreep doet is verantwoordelijk voor het periprocedurele beleid van de antitrombotica</li><li>Informeer de trombosedienst</li></ul>",
  },
  {
    title: "Nierfunctie, Hb en leverfunctie",
    content: "<p>Dit is bij VKA-behandeling niet standaard nodig.</p>",
  },
  {
    title: "Veelgestelde vragen",
    content:
      '<p>Voor een overzicht van de veelvoorkomende vragen van patiënten, zie <a href="https://lta-antistollingszorg.nl/communicatie-met-patienten/veelgestelde-vragen-over-vka" target="_blank" rel="noreffer noopener">veelgestelde vragen over VKA</a>.</p>',
  },
];

class Stepper extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      current: 0,
    };
  }

  onChange = (current) => {
    this.setState({ current });
  };

  next() {
    const current = this.state.current + 1;
    this.setState({ current });
  }

  prev() {
    const current = this.state.current - 1;
    this.setState({ current });
  }

  reset() {
    const current = (this.state.current = 0);
    this.setState({ current });
  }

  render() {
    const { current } = this.state;

    return (
      <div className="wrapper-interactive">
        <Row>
          <Col xs={3} sm={6} md={9} lg={9} xl={9}>
            <Steps
              current={current}
              onChange={this.onChange}
              size="small"
              direction="vertical"
            >
              {steps.map((item) => (
                <Step key={item.title} title={item.title} />
              ))}
            </Steps>
          </Col>
          <Col xs={21} sm={18} md={15} lg={15} xl={15}>
            <h4>{steps[current].title}</h4>
            <Divider />
            <div className="steps-content">
              {ReactHtmlParser(steps[current].content)}
            </div>
          </Col>
        </Row>
        <Row type="flex" justify="center">
          <Col xs={3} sm={6} md={9} lg={9} xl={9}></Col>
          <Col xs={21} sm={18} md={15} lg={15} xl={15}>
            <ButtonGroup>
              {current > 0 && (
                <Button type="primary" onClick={() => this.prev()}>
                  <Icon type="left" />
                  Terug
                </Button>
              )}
              {current === 0 && (
                <Button type="primary" onClick={() => this.prev()} disabled>
                  <Icon type="left" />
                  Terug
                </Button>
              )}
              {current < steps.length - 1 && (
                <Button type="primary" onClick={() => this.next()}>
                  Volgende
                  <Icon type="right" />
                </Button>
              )}
            </ButtonGroup>
          </Col>
        </Row>
      </div>
    );
  }
}

export default Stepper;
