import React from "react";
import { Button } from "antd";
import _ from "lodash";
import { CheckSquareOutlined } from "@ant-design/icons";

export default function LinksButtonActive({
  links,
  linksActive,
  onNext,
  size,
  className,
}) {
  return links.link.map((item) => {
    const buttonActive = {
      backgroundColor: "#ebfbee",
      color: "#0D6739",
      borderColor: "#0D6739",
      marginTop: "7.5px",
      marginRight: "7.5px",
    };
    const buttonInactive = {
      marginRight: "7.5px",
      marginTop: "7.5px",
      display: "none",
    };
    const buttonStyle =
      item.nodeId === linksActive ? buttonActive : buttonInactive;
    const buttonIcon =
      item.nodeId === linksActive ? <CheckSquareOutlined /> : null;
    if (onNext) {
      return (
        <>
          <Button
            disabled
            icon={buttonIcon}
            key={item.nodeId}
            style={buttonStyle}
            type="primary"
            size={size}
            className={className}
          >
            {item.text}
          </Button>
        </>
      );
    }
  });
}
