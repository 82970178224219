import React from "react";
import {
  Card,
  Col,
  Divider,
  List,
  Popover,
  Row,
  Statistic,
  Typography,
} from "antd";
const { Title, Link } = Typography;

const gridStyle = {
  width: "50%",
  textAlign: "center",
};

const Risicofactoren = () => (
  <List size="small" bordered>
    <List.Item>Atriumfibrilleren</List.Item>
    <List.Item>Linker ventrikel ejectiefractie &lt; 35%</List.Item>
    <List.Item>
      Voorgeschiedenis van een doorgemaakte trombo-embolie (cerebro vasculair
      accident, transient ischemic attack, of arteriële embolie)
    </List.Item>
  </List>
);

class TableTromboRisico extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Row gutter={20}>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Title level={3}>Hoog risico</Title>
          <Card>
            <Card.Grid style={gridStyle}>
              <Statistic
                title="Hoog risico voor arteriële trombo-embolie"
                value={"> 10% per jaar"}
                valueStyle={{ color: "#cf1322" }}
              />
            </Card.Grid>
            <Card.Grid style={gridStyle}>
              <Statistic
                title="Hoog risico voor veneuze trombo-embolie"
                value={"> 10% per maand"}
                valueStyle={{ color: "#cf1322" }}
              />
            </Card.Grid>
          </Card>
          <Divider />
          <Title level={3}>Klinische status hoog risico</Title>
          <List size="large" bordered>
            <List.Item>
              Geïsoleerd atriumfibrilleren, zonder klepgebrek, met CHA
              <sup>2</sup>DS
              <sup>2</sup>-VASc-score: <b>8-9</b>
              {/* <ModalContent
          trigger="Toon score"
          title="CHA2DS2-VASc-score"
          content={<TableChadCalc />}
        /> */}
            </List.Item>
            <List.Item>
              Geïsoleerd atriumfibrilleren met reumatische hartziekte
            </List.Item>
            <List.Item>
              Atriumfibrilleren met mechanische hartklep of recent (&lt;6
              maanden) herseninfarct/TIA ongeacht de CHA<sup>2</sup>DS
              <sup>2</sup>
              -VASc-score:
              {/* <ModalContent
          trigger="Toon score"
          title="CHA2DS2-VASc-score"
          content={<TableChadCalc />}
        /> */}
            </List.Item>
            <List.Item>
              Bij biokleppen (inclusief TAVI) &lt; 3 maanden geleden geplaatst,
              alleen op indicatie van de operateur
            </List.Item>
            <List.Item>
              Mechanische hartklep in mitralis, tricuspidalis of pulmonalis
              positie
            </List.Item>
            <List.Item>
              Aortamechanoprothesen met extra{" "}
              <Popover content={<Risicofactoren />} title="Risicofactoren">
                <Link>risicofactor</Link>
              </Popover>{" "}
              in overleg met de operateur
            </List.Item>
            <List.Item>
              Hartklepprothese (inclusief{" "}
              <Popover
                content={
                  "De eerste drie maanden na chirurgie een bioklep beschouwen als zijnde ‘mechanoprothese’."
                }
                title="Bioklep"
              >
                <Link>bioklep</Link>
              </Popover>
              ) met extra{" "}
              <Popover content={<Risicofactoren />} title="Risicofactoren">
                <Link>risicofactor</Link>
              </Popover>
            </List.Item>
            <List.Item>
              Mechanische hartklep oud model: caged ball, tilting disc
              (Starr-Edwards, Björk-Shiley)
            </List.Item>
            <List.Item>Intracardiale trombus</List.Item>
            <List.Item>
              &lt; 3 maanden na een veneuze trombo-embolie (VTE) (eerste episode
              of recidief)
            </List.Item>
            <List.Item>Recidief VTE onder antistolling</List.Item>
            <List.Item>
              Recidief VTE bij eerdere tijdelijke onderbreking van antistolling
              (ook > 3 maanden laatste event)
            </List.Item>
            <List.Item>
              Recidiverend herseninfarct bij symptomatische ACI stenose
            </List.Item>
          </List>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Title level={3}>Laag risico</Title>
          <Card>
            <Card.Grid style={gridStyle}>
              <Statistic
                title="Laag risico voor arteriële trombo-embolie"
                value={"< 10% per jaar"}
                valueStyle={{ color: "#3f8600" }}
              />
            </Card.Grid>
            <Card.Grid style={gridStyle}>
              <Statistic
                title="Laag risico voor veneuze trombo-embolie"
                value={"< 10% per maand"}
                valueStyle={{ color: "#3f8600" }}
              />
            </Card.Grid>
          </Card>
          <Divider />
          <Title level={3}>Klinische status laag risico</Title>
          <List size="large" bordered>
            <List.Item>
              Geïsoleerd atriumfibrilleren, CHA<sup>2</sup>DS<sup>2</sup>
              -VASc-score: <b>0-7</b> en geen recent (&lt; 6 maanden)
              herseninfarct/ TIA
              {/* <ModalContent
          trigger="Toon score"
          title="CHA2DS2-VASc-score"
          content={<TableChadCalc />}
        /> */}
            </List.Item>
            <List.Item>
              Mechanische hartklep in aortapositie &gt; 3 maanden geleden
              geplaatst zonder extra{" "}
              <Popover
                content={<Risicofactoren />}
                title="Extra risicofactoren hartklepprothese"
              >
                <Link href="#">risicofactor</Link>
              </Popover>
            </List.Item>
            <List.Item>Bioklep > 3 maanden geleden geplaatst</List.Item>
            <List.Item>
              Recidiverend TIA/herseninfarct zonder cardiale emboliebron
            </List.Item>
            <List.Item>Eenmalig TIA/herseninfarct</List.Item>
            <List.Item>
              &gt; 3 maanden na eerste of recidief VTE en geen recidief onder
              antistolling
            </List.Item>
          </List>
        </Col>
      </Row>
    );
  }
}

export default TableTromboRisico;
