import React, { Component } from "react";
import _ from "lodash";
import { Col, Button, Typography, Icon } from "antd";

const { Title } = Typography;

export default class Node extends Component {
  constructor(props) {
    super(props);
  }

  render = () => {
    const { nodeId, isLastOnPath, isOnPath, onGoTo, isEndNode } = this.props;
    const stepClass = isOnPath
      ? "decision-step-visible"
      : "decision-step-invisible";
    const onNodeGoTo = () => onGoTo(nodeId);

    if (!isOnPath) {
      return <Col id={nodeId} key={nodeId} span={24} offset={0}></Col>;
    } else if (isOnPath && !isLastOnPath) {
      return (
        <Col
          id={nodeId}
          key={nodeId}
          span={24}
          offset={0}
          style={{
            backgroundColor: "#c9d22a",
            padding: "15px",
            marginBottom: "30px",
          }}
          className={stepClass}
        >
          <Title
            onClick={onNodeGoTo}
            style={{
              fontSize: "20px",
              fontWeight: "normal",
              letterSpacing: "0.66px",
              color: "#1d396e",
            }}
          >
            {this.props.nodeTitle}
          </Title>
          <img
            src="/client/katnh/small_dots.svg"
            alt="divider"
            style={{ height: "3px", marginTop: "16px" }}
          />
          <div style={{ marginTop: "16px" }}>{this.props.children}</div>
          <div style={{ marginTop: "16px" }}>{this.linksButtonsActive()}</div>
        </Col>
      );
    } else if (isEndNode) {
      return (
        <Col
          id={nodeId}
          key={nodeId}
          span={24}
          offset={0}
          className={`decision-step-focused ${stepClass}`}
          style={{ backgroundColor: "#1d396e", padding: "15px" }}
        >
          <Title
            style={{
              fontSize: "20px",
              fontWeight: "normal",
              letterSpacing: "0.66px",
              color: "#c9d22a",
            }}
          >
            {this.props.nodeTitle}
          </Title>
          <img
            src="/client/katnh/small_dots_green.svg"
            alt="divider"
            style={{ height: "3px", marginTop: "16px" }}
          />
          <div style={{ marginTop: "16px" }}>{this.props.children}</div>
        </Col>
      );
    } else if (isLastOnPath) {
      return (
        <Col
          id={nodeId}
          key={nodeId}
          span={24}
          offset={0}
          style={{ backgroundColor: "#1d396e", padding: "15px" }}
          className={`decision-step-focused ${stepClass}`}
        >
          <Title
            style={{
              fontSize: "20px",
              fontWeight: "normal",
              letterSpacing: "0.66px",
              color: "#c9d22a",
            }}
          >
            {this.props.nodeTitle}
          </Title>
          <img
            src="/client/katnh/small_dots_green.svg"
            alt="divider"
            style={{ height: "3px", marginTop: "16px" }}
          />
          {!this.props.options && (
            <div style={{ marginTop: "16px" }}>{this.props.children}</div>
          )}
          {this.props.extraContent && (
            <div style={{ marginTop: "16px" }}>{this.props.extraContent}</div>
          )}
          {this.props.options && (
            <div style={{ marginTop: "16px" }}>{this.props.options}</div>
          )}
          {!this.props.disableDefaultOptionsButtons && (
            <>
              <div style={{ marginTop: "16px" }}>{this.props.widget}</div>
              <div style={{ marginTop: "16px" }}>{this.linksButtons()}</div>
            </>
          )}
        </Col>
      );
    }
  };

  linksButtons = () => {
    return this.props.links.link.map((item) => {
      return (
        <Button
          key={`${item.text}_${item.nodeId}`}
          onClick={() => this.props.onNext(item.nodeId)}
          style={{
            height: "41px",
            padding: "0 15px",
            marginTop: "15px",
            marginRight: "15px",
            whitespace: "normal",
            border: "2px solid white",
            borderRadius: "0",
            backgroundColor: "#1d396e",
            color: "white",
            fontSize: "18px",
          }}
        >
          {item.text}
        </Button>
      );
    });
  };

  linksButtonsActive = () => {
    const { onNext, onGoTo, nodeId, linksActive } = this.props;
    const onContentClick = () => onGoTo(nodeId);
    return this.props.links.link.map((item) => {
      const inactiveStyle = {
        height: "41px",
        padding: "0 15px",
        marginTop: "15px",
        marginRight: "15px",
        whitespace: "normal",
        border: "2px solid #1d396e",
        borderRadius: "0",
        backgroundColor: "#c9d22a",
        color: "#1d396e",
        fontSize: "18px",
      };
      const activeStyle = {
        height: "41px",
        padding: "0 15px",
        marginTop: "15px",
        marginRight: "15px",
        whitespace: "normal",
        border: "2px solid #1d396e",
        borderRadius: "0",
        backgroundColor: "#1d396e",
        color: "#c9d22a",
        fontSize: "18px",
      };
      const buttonStyle =
        item.nodeId === linksActive ? activeStyle : inactiveStyle;
      const buttonIcon =
        item.nodeId === linksActive ? <Icon type="check-square" /> : null;
      if (onNext) {
        return (
          <>
            <Button
              type="primary"
              key={`${item.text}_${item.nodeId}`}
              onClick={onContentClick}
              style={buttonStyle}
            >
              {buttonIcon}
              {item.text}
            </Button>
          </>
        );
      }
    });
  };
}
