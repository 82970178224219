import React from "react";
import Xarrow from "react-xarrows";

const ArrowsActiveWithoutLabel = ({
  arrowColorActive,
  arrowColorInactive,
  gridBreak,
  isLastOnPath,
  links,
  linksActive,
  nodeId,
}) => {
  return links.link.map((item) => {
    const animateDrawing = item.nodeId === isLastOnPath ? 0.2 : false;
    const color =
      item.nodeId === linksActive ? arrowColorActive : arrowColorInactive;
    const curveness = item.nodeId === linksActive ? 0.5 : 0.3;
    const headSize = item.nodeId === linksActive ? 4 : 3;
    const path = item.nodeId === linksActive ? "smooth" : "grid";
    const zIndex = item.nodeId === linksActive ? 1000 : 0;
    const showArrow = item.nodeId === linksActive ? true : false;
    return (
      <Xarrow
        animateDrawing={animateDrawing}
        color={color}
        curveness={curveness}
        end={`${item.nodeId}-top`}
        endAnchor="top"
        gridBreak={gridBreak}
        headSize={headSize}
        key={`${item.nodeId}-${item.arrowEndAnchor}`}
        path={path}
        showXarrow={showArrow}
        start={`${nodeId}-bottom`}
        startAnchor="bottom"
        zIndex={zIndex}
      />
    );
  });
};

export default ArrowsActiveWithoutLabel;
