import React from "react";
import { Typography, Popover } from "antd";
import { StaticImage } from "gatsby-plugin-image";
import { ExportOutlined } from "@ant-design/icons";

const { Text } = Typography;

const style = {
  margin: "0 -0.4em",
  padding: "0.1em 0.4em",
  borderRadius: "0.75em 0.45em",
  background: "transparent",
  backgroundImage:
    "linear-gradient(to right,rgba(	2, 168, 225, 0.025),rgba(	2, 168, 225, 0.35) 4%,rgba(	2, 168, 225, 0.15))",
  boxDecorationBreak: "clone",
  cursor: "context-menu",
};

const ContentLinkExternalKf = ({ title, url }) => {
  return (
    <>
      <Popover
        color="#02a8e1"
        placement="topRight"
        content={
          <div>
            <a
              href={url}
              target="_blank"
              rel="noreferrer noopener"
              style={{
                color: "#fff",
                marginBottom: "24px",
              }}
            >
              <>
                <StaticImage
                  src="../../images/logo/logoKF.png"
                  alt="Logo KF"
                  width={150}
                  style={{ marginRight: "8px", top: "-2px" }}
                />
                {"> "}
              </>
              {title}
            </a>
            <br />
            <Text
              style={{ fontSize: "12px", color: "#fff", marginRight: "10px" }}
            >
              <ExportOutlined style={{ transform: "rotate(-90deg)" }} /> opent
              in een nieuw venster
            </Text>
          </div>
        }
        trigger="hover"
      >
        <Text style={style}>
          <ExportOutlined
            style={{
              transform: "rotate(-90deg)",
              color: "#02a8e1",
              marginLeft: "2px",
            }}
          />{" "}
          {title}
        </Text>
      </Popover>
    </>
  );
};

export default ContentLinkExternalKf;
