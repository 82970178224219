import React, { Component } from "react";
import { Checkbox, Divider, Statistic, Typography } from "antd";
const { Text } = Typography;

const ITEM_GROUPS = [
  {
    id: "group_seven",
    choices: [
      {
        id: "choice_one",
        title: "Leeftijd 65 - 74 jaar",
        score: 1,
      },
      {
        id: "choice_two",
        title: "Leeftijd ≥ 75 jaar",
        score: 2,
      },
    ],
  },
  {
    id: "group_eight",
    choices: [
      {
        id: "choice_one",
        title: "Vrouwelijk geslacht",
        score: 2,
      },
    ],
  },
  {
    id: "group_one",
    choices: [
      {
        id: "choice_one",
        title: "Hartfalen",
        score: 1,
      },
    ],
  },
  {
    id: "group_two",
    choices: [
      {
        id: "choice_one",
        title: "Hypertensie",
        score: 1,
      },
    ],
  },
  {
    id: "group_four",
    choices: [
      {
        id: "choice_one",
        title: "Diabetes mellitus",
        score: 1,
      },
    ],
  },
  {
    id: "group_five",
    choices: [
      {
        id: "choice_one",
        title: "CVA/TIA",
        score: 2,
      },
    ],
  },
  {
    id: "group_six",
    choices: [
      {
        id: "choice_one",
        title:
          "Arterieel vaatlijden (o.a. coronairlijden, perifeer vaatlijden)",
        score: 1,
      },
    ],
  }
];

export default class Chadsvasc extends Component {
  constructor(props) {
    super(props);

    const selectedChoices = {};
    for (const itemGroup of ITEM_GROUPS) {
      selectedChoices[itemGroup.id] = null;
    }

    this.state = {
      selectedChoices,
    };
  }

  render = () => {
    return (
      <>
        <div className="wrapper-interactive">
          <h4 style={{ color: "#c9d22a" }}>CHA2DS2VASc-score</h4>
          <Divider />
          {this._renderGroups()}
          <Statistic title="Score:" value={this._renderScore()} />
          <p style={{ color: "white", marginTop: "30px", color: "white" }}>
            Gebaseerd op de publicatie van Lip et al. Refining clinical risk
            stratification for predicting stroke and thromboembolism in atrial
            fibrillation using a novel risk factor-based approach: the euro
            heart survey on atrial fibrillation. Chest. 2010 Feb 137(2):263-72
            <br />
            <a href="https://pubmed.ncbi.nlm.nih.gov/19762550/" target="_blank" rel="noreferrer noopener">Bekijk het artikel</a>
          </p>
        </div>
      </>
    );
  };

  _renderGroups = () => {
    return ITEM_GROUPS.map((group) => {
      return (
        <div key={group.id}>
          <Text>{group.title}</Text>
          {group.choices.map((choice) => {
            return (
              <div style={{ padding: "12px 0" }}>
                <Checkbox
                  key={choice.id}
                  id={choice.title}
                  onChange={() => this._selectChoice(group, choice)}
                  checked={this.state.selectedChoices[group.id] === choice.id}
                  style={{ marginRight: "16px" }}
                />
                <label for={choice.title} style={{ color: "white" }}>
                  {choice.title}{" "}
                  {/* <Tag style={{ marginLeft: "15px" }}>{choice.score}</Tag> */}
                </label>
              </div>
            );
          })}
        </div>
      );
    });
  };

  _renderScore = () => {
    let score = 0;
    for (const group of ITEM_GROUPS) {
      for (const choice of group.choices) {
        if (choice.id === this.state.selectedChoices[group.id]) {
          score += choice.score;
        }
      }
    }

    return score;
  };

  _selectChoice = (group, choice) => {
    const groupId = group.id;
    const choiceId = choice.id;
    const selectedChoiceId = this.state.selectedChoices[groupId];
    const newChoiceId = selectedChoiceId === choiceId ? null : choiceId;

    this.setState({
      selectedChoices: Object.assign(this.state.selectedChoices, {
        [groupId]: newChoiceId,
      }),
    });
  };
}
