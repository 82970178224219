import React from "react";
import NodeCardActiveWithGrid from "../node/NodeCardActiveWithGrid.js";
import NodeCardEndWithGrid from "../node/NodeCardEndWithGrid";
import NodeCardOnPathWithGrid from "../node/NodeCardOnPathWithGrid";
import NodeCardWithGrid from "../node/NodeCardWithGrid";

export default function Node({
  children,
  disableDefaultOptionsButtons,
  extraContent,
  isEndNode,
  isLastOnPath,
  isOnPath,
  language,
  links,
  linksActive,
  nodeId,
  nodeTitle,
  nodeType,
  offset,
  onGoTo,
  onNext,
  onReset,
  onPrevious,
  onResetTo,
  options,
  showAllNodes,
  showNodeType,
  previousNode,
  span,
  startDemo,
  useStartDemoProp,
}) {
  const onNodeGoTo = () => onGoTo(nodeId);
  const onNodeResetTo = () => onResetTo(nodeId);
  if (!isOnPath) {
    return (
      <NodeCardWithGrid
        children={children}
        isLastOnPath={isLastOnPath}
        language={language}
        links={links}
        linksActive={linksActive}
        nodeId={nodeId}
        nodeTitle={nodeTitle}
        nodeType={nodeType}
        offset={offset}
        onNext={onNext}
        onNodeGoTo={onNodeGoTo}
        onNodeResetTo={onNodeResetTo}
        showAllNodes={showAllNodes}
        span={span}
        showNodeType={showNodeType}
      />
    );
  } else if (!isLastOnPath) {
    return (
      <NodeCardOnPathWithGrid
        children={children}
        isLastOnPath={isLastOnPath}
        language={language}
        links={links}
        linksActive={linksActive}
        nodeId={nodeId}
        nodeTitle={nodeTitle}
        nodeType={nodeType}
        offset={offset}
        onNext={onNext}
        onNodeGoTo={onNodeGoTo}
        onNodeResetTo={onNodeResetTo}
        span={span}
        showNodeType={showNodeType}
      />
    );
  } else if (isEndNode) {
    return (
      <NodeCardEndWithGrid
        children={children}
        extraContent={extraContent}
        language={language}
        nodeId={nodeId}
        nodeTitle={nodeTitle}
        nodeType={nodeType}
        offset={offset}
        onReset={onReset}
        onNodeResetTo={onNodeResetTo}
        span={span}
        showNodeType={showNodeType}
      />
    );
  } else {
    return (
      <NodeCardActiveWithGrid
        children={children}
        disableDefaultOptionsButtons={disableDefaultOptionsButtons}
        extraContent={extraContent}
        language={language}
        links={links}
        nodeId={nodeId}
        nodeTitle={nodeTitle}
        nodeType={nodeType}
        offset={offset}
        onNext={onNext}
        onReset={onReset}
        onNodeResetTo={onNodeResetTo}
        onPrevious={onPrevious}
        options={options}
        span={span}
        startDemo={startDemo}
        useStartDemoProp={useStartDemoProp}
        showNodeType={showNodeType}
        previousNode={previousNode}
      />
    );
  }
}
