import React from "react";
import { Typography } from "antd";
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  InfoCircleOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";

const ButtonElearning = ({ onNext, k, text, previous }) => {
  return (
    <div key={k} onClick={() => onNext(k)} className="button-katnh-elearning">
      <img
        src="/client/katnh/small_dots_green.svg"
        alt="divider"
        style={{ height: "1.5px" }}
        className="dots-green"
      />
      <img
        src="/client/katnh/small_dots.svg"
        alt="divider"
        style={{ height: "1.5px" }}
        className="dots-blue"
      />
      <span style={{ display: "block", padding: "2px 0 4px" }}>{text}</span>
      {previous && (
        <>
          <img
            src="/client/katnh/arrow_green.svg"
            alt="arrow"
            style={{
              height: "17px",
              marginTop: "5px",
              transform: "rotate(180deg)",
            }}
            className="icon-green"
          />
          <img
            src="/client/katnh/arrow_blue.svg"
            alt="arrow"
            style={{
              height: "17px",
              marginTop: "5px",
              transform: "rotate(180deg)",
            }}
            className="icon-blue"
          />
        </>
      )}
      {!previous && (
        <>
          <img
            src="/client/katnh/arrow_green.svg"
            alt="arrow"
            style={{ height: "17px", marginTop: "5px" }}
            className="icon-green"
          />
          <img
            src="/client/katnh/arrow_blue.svg"
            alt="arrow"
            style={{ height: "17px", marginTop: "5px" }}
            className="icon-blue"
          />
        </>
      )}
    </div>
  );
};

export default ButtonElearning;
