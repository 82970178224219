import React, { Component } from "react";
import { Col, Checkbox, Card, Typography, Tag, Divider, List } from "antd";
import ButtonWithIndicator from "../ui/ButtonWithIndicator";
const { Text } = Typography;

export default class OptionsItemsPointsComponent extends Component {
  constructor(props) {
    super(props);

    const selectedChoices = {};
    for (const itemGroup of this.props.steps) {
      selectedChoices[itemGroup.id] = null;
    }

    this.state = {
      selectedChoices,
    };
  }

  render = () => {
    return (
      <>
        <Card
          size="small"
          title={this.props.title}
          type="inner"
          extra={
            <>
              <Text style={{ marginRight: "15px" }}>Score:</Text>
              <Tag color="#108ee9">{this._renderScore()}</Tag>
            </>
          }
        >
          <List bordered>{this._renderGroups()}</List>
        </Card>
        {!this.props.hideProceed && (
          <div>
            <Divider
              orientation="left"
              plain
              style={{
                margin: "15px 0 10px 0",
                fontSize: "12px",
                borderColor: "#882941",
              }}
            >
              Uitkomst:
            </Divider>
            {this._renderProceed()}
          </div>
        )}
      </>
    );
  };

  _renderGroups = () => {
    return this.props.steps.map((group) => {
      return (
        <div key={group.id}>
          {group.title && (
            <List.Item style={{ fontWeight: "bold" }}>{group.title}</List.Item>
          )}
          {group.choices.map((choice) => {
            return (
              <List.Item>
                <Checkbox
                  key={choice.id}
                  id={choice.title}
                  onChange={() => this._selectChoice(group, choice)}
                  checked={this.state.selectedChoices[group.id] === choice.id}
                >
                  {choice.title}
                </Checkbox>
              </List.Item>
            );
          })}
        </div>
      );
    });
  };

  _renderScore = () => {
    let score = 0;
    for (const group of this.props.steps) {
      for (const choice of group.choices) {
        if (choice.id === this.state.selectedChoices[group.id]) {
          score += choice.score;
        }
      }
    }

    return score;
  };

  _renderProceed = () => {
    let score = 0;
    for (const group of this.props.steps) {
      for (const choice of group.choices) {
        if (choice.id === this.state.selectedChoices[group.id]) {
          score += choice.score;
        }
      }
    }

    if (score < this.props.cutoffValue) {
      return (
        <>
          <Col xs={24}>
            <ButtonWithIndicator
              key={this.props.nodeLinkOne}
              onNext={this.props.onNext}
              k={this.props.nodeLinkOne}
              v={`${this.props.nodeLinkOneLabel} (=${this._renderScore()})`}
            />
          </Col>
        </>
      );
    } else {
      return (
        <Col xs={24}>
          <ButtonWithIndicator
            key={this.props.nodeLinkTwo}
            onNext={this.props.onNext}
            k={this.props.nodeLinkTwo}
            v={`${this.props.nodeLinkTwoLabel} (=${this._renderScore()})`}
          />
        </Col>
      );
    }
  };

  _selectChoice = (group, choice) => {
    const groupId = group.id;
    const choiceId = choice.id;
    const selectedChoiceId = this.state.selectedChoices[groupId];
    const newChoiceId = selectedChoiceId === choiceId ? null : choiceId;

    this.setState({
      selectedChoices: Object.assign(this.state.selectedChoices, {
        [groupId]: newChoiceId,
      }),
      buttonDisabled: false,
    });
  };
}
