import React from "react";
import {
  Button,
  Row,
  Col,
  Divider,
  Select,
  Descriptions,
  Modal,
  Typography,
} from "antd";

const { Title, Paragraph, Link } = Typography;
const Option = Select.Option;

const steps = [
  {
    index: 0,
    title: "Colorectaalcarcinoom",
    soort: "Colorectaalcarcinoom",
    prevalentie: "0.70%",
    aantal: "2/286",
    bron: "Stransky et al, 2014",
  },
  {
    index: 1,
    title: "Galwegcarcinoom",
    soort: "Intrahepatisch cholangiocarcinoom",
    prevalentie: "3.60%",
    aantal: "1/28",
    bron: "Ross et al, 2014",
  },
  {
    index: 2,
    title: "Speekselkliercarcinoom",
    soort: "‘Mammary analogue secretrory cancers’ van de speekselklieren",
    prevalentie: "100%",
    aantal: "15/15",
    bron: "Bishop et al, 2013",
  },
];

class Prevalentie extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      current: 0,
      visible: false,
    };
  }

  onChange = (value) => {
    const current = value;
    this.setState({ current });
  };

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = (e) => {
    this.setState({
      visible: false,
    });
  };

  handleCancel = (e) => {
    this.setState({
      visible: false,
    });
  };

  render() {
    const { current } = this.state;

    return (
      <>
        <Paragraph>
          <Link onClick={this.showModal}>
            Zie tabel 'Prevalentie van NTRK-genfusie in gepubliceerde
            literatuur'
          </Link>
        </Paragraph>
        <Modal
          title="Prevalentie van NTRK-genfusie in gepubliceerde literatuur"
          visible={this.state.visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          footer={[
            <Button key="submit" type="primary" onClick={this.handleOk}>
              Sluiten
            </Button>,
          ]}
        >
          <Row>
            <Col xs={24}>
              <Title level={4}>Soort:</Title>
              <Select
                size="large"
                style={{ width: "100%", marginTop: "8px" }}
                onChange={this.onChange}
                showSearch
                defaultValue="Colorectaalcarcinoom"
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                placeholder="Selecteer een soort"
              >
                {steps.map((item) => (
                  <Option
                    value={item.index}
                    key={item.title}
                    title={item.title}
                  >
                    {item.title}
                  </Option>
                ))}
              </Select>
            </Col>
            <Col xs={24}>
              <Divider />
              <Title level={4}>Details:</Title>
              <Descriptions
                column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
                bordered
                border
                size="small"
                style={{ background: "white", marginTop: "16px" }}
              >
                <Descriptions.Item label="Ziekte soort">
                  {steps[current].soort}
                </Descriptions.Item>
                <Descriptions.Item label="Prevalentie">
                  {steps[current].prevalentie}
                </Descriptions.Item>
                <Descriptions.Item label="Aantal fusies">
                  {steps[current].aantal}
                </Descriptions.Item>
                <Descriptions.Item label="Bron">
                  {steps[current].bron}
                </Descriptions.Item>
              </Descriptions>
            </Col>
          </Row>
        </Modal>
      </>
    );
  }
}

export default Prevalentie;
