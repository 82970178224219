import React from "react";

import { Row, Col, Divider, Select, Descriptions, Typography } from "antd";

import ContentAlert from "../ui/ContentAlert";

const Title = Typography.Title;
const Option = Select.Option;

const steps = [
  {
    index: 0,
    title: "Acenocoumarol < 70 jaar",
    merknaam: "-",
    sterkte: "1 mg",
    thalf: "14 uur",
    dosis:
      "Startdosering is afhankelijk van de leeftijd en het al dan niet aanwezig zijn van relatieve contra-indicaties (ernstig ziek zijn/slechte voedsel-intake/leverziekte/hartfalen/verhoogde bloedingsneiging)",
    one: "6 tabletten",
    two: "4",
    three: "1",
    four: "op geleide van INR",
  },
  {
    index: 1,
    title: "Acenocoumarol ≥ 70 jaar",
    merknaam: "-",
    sterkte: "1 mg",
    thalf: "14 uur",
    dosis:
      "Startdosering is afhankelijk van de leeftijd en het al dan niet aanwezig zijn van relatieve contra-indicaties (ernstig ziek zijn/slechte voedsel-intake/leverziekte/hartfalen/verhoogde bloedingsneiging)",
    one: "4 of 3 tabletten",
    two: "2",
    three: "1",
    four: "op geleide van INR",
  },
  {
    index: 2,
    title: "Fenprocoumon < 70 jaar",
    merknaam: "Marcoumar ®",
    sterkte: "3mg",
    thalf: "150 uur",
    dosis:
      "Startdosering is afhankelijk van de leeftijd en het al dan niet aanwezig zijn van relatieve contra-indicaties (ernstig ziek zijn/slechte voedsel-intake/leverziekte/hartfalen/verhoogde bloedingsneiging)",
    one: "4 of 3 tabletten",
    two: "2",
    three: "1",
    four: "op geleide van INR",
  },
  {
    index: 3,
    title: "Fenprocoumon ≥ 70 jaar",
    merknaam: "Marcoumar ®",
    sterkte: "3mg",
    thalf: "150 uur",
    dosis:
      "Startdosering is afhankelijk van de leeftijd en het al dan niet aanwezig zijn van relatieve contra-indicaties (ernstig ziek zijn/slechte voedsel-intake/leverziekte/hartfalen/verhoogde bloedingsneiging)",
    one: "3 of 2 tabletten",
    two: "1",
    three: "1/2",
    four: "op geleide van INR",
  },
];

class Stepper extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      current: 0,
    };
  }

  onChange = (value) => {
    const current = value;
    this.setState({ current });
  };

  render() {
    const { current } = this.state;

    return (
      <div className="wrapper-interactive">
        <Row>
          <Col xs={24}>
            <Title level={4}>Startdosering vitamine K antagonisten:</Title>
            <Select
              size="large"
              style={{ width: "100%", marginTop: "8px" }}
              onChange={this.onChange}
              showSearch
              autoFocus
              defaultValue="Acenocoumarol < 70 jaar"
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
              placeholder="Selecteer een VKA.."
            >
              {steps.map((item) => (
                <Option value={item.index} key={item.title} title={item.title}>
                  {item.title}
                </Option>
              ))}
            </Select>
          </Col>
          <Col xs={24}>
            <Divider />
            <Title level={4}>Informatie:</Title>
            <Descriptions
              column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
              bordered
              size="small"
              style={{ background: "white", marginTop: "16px" }}
            >
              <Descriptions.Item label="Merknaam">
                {steps[current].merknaam}
              </Descriptions.Item>
              <Descriptions.Item label="Sterkte in mg/tablet">
                {steps[current].sterkte}
              </Descriptions.Item>
              <Descriptions.Item label="Halfwaarde tijd (T ½)">
                {steps[current].thalf}
              </Descriptions.Item>
              <Descriptions.Item label="Startdosis (aantal tabletten)">
                {steps[current].dosis}
              </Descriptions.Item>
              <Descriptions.Item label="1ste dag">
                {steps[current].one}
              </Descriptions.Item>
              <Descriptions.Item label="2e dag">
                {steps[current].two}
              </Descriptions.Item>
              <Descriptions.Item label="3e dag">
                {steps[current].three}
              </Descriptions.Item>
              <Descriptions.Item label="4e dag">
                {steps[current].four}
              </Descriptions.Item>
            </Descriptions>
            {steps[current].alert ? (
              <ContentAlert
                message={steps[current].alert}
                type="info"
                showIcon
                style={{ margin: "8px 0" }}
              />
            ) : null}
          </Col>
        </Row>
      </div>
    );
  }
}

export default Stepper;
