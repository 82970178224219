import React from "react";
import { Modal, Typography } from "antd";
import {
  FolderOpenOutlined
} from '@ant-design/icons';
const { Link } = Typography;

class ContentModal extends React.Component {
  constructor() {
    super();
    this.state = { visible: false };
  }

  showDrawer = () => {
    this.setState({
      visible: true,
    });
  };

  onClose = () => {
    this.setState({
      visible: false,
    });
  };

  render() {
    return (
      <>
        <p>
          <Link
            type="link"
            onClick={this.showDrawer}
            style={{ paddingLeft: "3px" }}
          >
            <FolderOpenOutlined /> {" "}{this.props.trigger}
          </Link>
        </p>
        <Modal
          title={this.props.title}
          visible={this.state.visible}
          onClose={this.onClose}
          onCancel={this.onClose}
          drawerStyle={{ backgroundColor: "white" }}
          footer={null}
        >
          {this.props.content}
        </Modal>
      </>
    );
  }
}

export default ContentModal;
