exports.components = {
  "component---src-client-3-ieimpact-layouts-layout-js": () => import("./../../../src/client/3ieimpact/layouts/layout.js" /* webpackChunkName: "component---src-client-3-ieimpact-layouts-layout-js" */),
  "component---src-client-katnh-layout-layout-js": () => import("./../../../src/client/katnh/layout/layout.js" /* webpackChunkName: "component---src-client-katnh-layout-layout-js" */),
  "component---src-client-niv-acuteboekje-layouts-layout-js": () => import("./../../../src/client/niv_acuteboekje/layouts/layout.js" /* webpackChunkName: "component---src-client-niv-acuteboekje-layouts-layout-js" */),
  "component---src-client-testclient-layout-layout-js": () => import("./../../../src/client/testclient/layout/layout.js" /* webpackChunkName: "component---src-client-testclient-layout-layout-js" */),
  "component---src-client-testclient-pharma-layouts-testclient-pharma-js": () => import("./../../../src/client/testclient-pharma/layouts/testclient-pharma.js" /* webpackChunkName: "component---src-client-testclient-pharma-layouts-testclient-pharma-js" */),
  "component---src-layouts-client-examples-js": () => import("./../../../src/layouts/client/examples.js" /* webpackChunkName: "component---src-layouts-client-examples-js" */),
  "component---src-layouts-client-lta-antistolling-js": () => import("./../../../src/layouts/client/lta_antistolling.js" /* webpackChunkName: "component---src-layouts-client-lta-antistolling-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-docs-embedding-charts-getting-started-mdx": () => import("./../../../src/pages/docs/embedding-charts/getting-started.mdx" /* webpackChunkName: "component---src-pages-docs-embedding-charts-getting-started-mdx" */),
  "component---src-pages-docs-embedding-charts-introduction-mdx": () => import("./../../../src/pages/docs/embedding-charts/introduction.mdx" /* webpackChunkName: "component---src-pages-docs-embedding-charts-introduction-mdx" */),
  "component---src-pages-docs-embedding-charts-setup-mdx": () => import("./../../../src/pages/docs/embedding-charts/setup.mdx" /* webpackChunkName: "component---src-pages-docs-embedding-charts-setup-mdx" */),
  "component---src-pages-docs-introduction-mdx": () => import("./../../../src/pages/docs/introduction.mdx" /* webpackChunkName: "component---src-pages-docs-introduction-mdx" */),
  "component---src-pages-docs-performance-introduction-mdx": () => import("./../../../src/pages/docs/performance/introduction.mdx" /* webpackChunkName: "component---src-pages-docs-performance-introduction-mdx" */),
  "component---src-pages-docs-using-urls-introduction-mdx": () => import("./../../../src/pages/docs/using-urls/introduction.mdx" /* webpackChunkName: "component---src-pages-docs-using-urls-introduction-mdx" */),
  "component---src-pages-embed-test-js": () => import("./../../../src/pages/embed-test.js" /* webpackChunkName: "component---src-pages-embed-test-js" */),
  "component---src-pages-examples-embed-example-1-js": () => import("./../../../src/pages/examples/embed/example-1.js" /* webpackChunkName: "component---src-pages-examples-embed-example-1-js" */),
  "component---src-pages-examples-embed-example-2-js": () => import("./../../../src/pages/examples/embed/example-2.js" /* webpackChunkName: "component---src-pages-examples-embed-example-2-js" */),
  "component---src-pages-examples-index-js": () => import("./../../../src/pages/examples/index.js" /* webpackChunkName: "component---src-pages-examples-index-js" */),
  "component---src-pages-examples-medical-example-licht-traumatisch-hoofd-hersenletsel-kinderen-tot-16-jaar-js": () => import("./../../../src/pages/examples/medical/example-licht-traumatisch-hoofd-hersenletsel-kinderen-tot-16-jaar.js" /* webpackChunkName: "component---src-pages-examples-medical-example-licht-traumatisch-hoofd-hersenletsel-kinderen-tot-16-jaar-js" */),
  "component---src-pages-examples-medical-example-periprocedureel-beleid-apixaban-js": () => import("./../../../src/pages/examples/medical/example-periprocedureel-beleid-apixaban.js" /* webpackChunkName: "component---src-pages-examples-medical-example-periprocedureel-beleid-apixaban-js" */),
  "component---src-pages-examples-medical-example-tachycardia-js": () => import("./../../../src/pages/examples/medical/example-tachycardia.js" /* webpackChunkName: "component---src-pages-examples-medical-example-tachycardia-js" */),
  "component---src-pages-examples-medical-example-verdenking-op-longembolie-js": () => import("./../../../src/pages/examples/medical/example-verdenking-op-longembolie.js" /* webpackChunkName: "component---src-pages-examples-medical-example-verdenking-op-longembolie-js" */),
  "component---src-pages-examples-other-example-first-line-customer-support-js": () => import("./../../../src/pages/examples/other/example-first-line-customer-support.js" /* webpackChunkName: "component---src-pages-examples-other-example-first-line-customer-support-js" */),
  "component---src-pages-examples-premium-example-medical-assessment-js": () => import("./../../../src/pages/examples/premium/example-medical-assessment.js" /* webpackChunkName: "component---src-pages-examples-premium-example-medical-assessment-js" */),
  "component---src-pages-examples-premium-example-reservation-js": () => import("./../../../src/pages/examples/premium/example-reservation.js" /* webpackChunkName: "component---src-pages-examples-premium-example-reservation-js" */),
  "component---src-pages-examples-premium-example-shipping-process-js": () => import("./../../../src/pages/examples/premium/example-shipping-process.js" /* webpackChunkName: "component---src-pages-examples-premium-example-shipping-process-js" */),
  "component---src-pages-features-charts-js": () => import("./../../../src/pages/features/charts.js" /* webpackChunkName: "component---src-pages-features-charts-js" */),
  "component---src-pages-features-content-js": () => import("./../../../src/pages/features/content.js" /* webpackChunkName: "component---src-pages-features-content-js" */),
  "component---src-pages-features-integration-js": () => import("./../../../src/pages/features/integration.js" /* webpackChunkName: "component---src-pages-features-integration-js" */),
  "component---src-pages-features-layout-js": () => import("./../../../src/pages/features/layout.js" /* webpackChunkName: "component---src-pages-features-layout-js" */),
  "component---src-pages-get-a-quote-js": () => import("./../../../src/pages/get-a-quote.js" /* webpackChunkName: "component---src-pages-get-a-quote-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

