import React, { useState } from "react";
import {
  Alert,
  Row,
  Col,
  Divider,
  Card,
  Select,
  Descriptions,
  List,
  Tag,
  Typography,
} from "antd";

const { Paragraph, Text, Title } = Typography;
const Option = Select.Option;

export const data = [
  {
    index: 0,
    title: "Esmolol",
    beginWerking: "1 tot 2 min",
    duurWerking: "10 - 30 min",
    dosering: "0,5-1 mg/kg als bolus; 50-300 mcg/kg/min als continu infuus",
    contraIndicaties:
      "2de of 3de graads AV blok, systolisch hart falen, astma bronchiale, bradycardie",
    bijwerkingen: "Bradycardie",
  },
  {
    index: 1,
    title: "Metoprolol",
    beginWerking: "1 tot 2 min",
    duurWerking: "5 - 8 uur",
    dosering:
      "5-15 mg iv als bolus, meestal gegeven als 5 mg iv, met herhaling na 5 min",
    contraIndicaties:
      "2de of 3de graads AV blok, systolisch hart falen, astma bronchiale, bradycardie",
    bijwerkingen: "Bradycardie",
  },
  {
    index: 2,
    title: "Labetalol",
    beginWerking: "5 tot 10 min",
    duurWerking: "3 - 6 uur",
    dosering:
      "0,25-0,5 mg/kg als bolus OF 2-4 mg/min. als continu infuus tot streefwaarde is bereikt, daarna 5-20 mg/uur",
    contraIndicaties:
      "2de of 3de graads AV blok, systolisch hart falen, astma bronchiale, bradycardie",
    bijwerkingen: "Broncho-constrictie",
  },
  {
    index: 3,
    title: "Clevidipine ",
    beginWerking: "2 tot 3 min",
    duurWerking: "5 - 15 min",
    dosering:
      "2-32 mg/uur als continu infuus, startdosis 2 mg/uur, iedere 2 min verhogen met 2 mg/uur tot streefwaarde bereikt",
    contraIndicaties: "",
    bijwerkingen: "Hoofdpijn, reflex-tachycardie",
  },
  {
    index: 4,
    title: "Nicardipine",
    beginWerking: "5 tot 15 min",
    duurWerking: "30 - 40 min",
    dosering:
      "5-15 mg/h als continu infuus, startdosis 5 mg/uur, iedere 15-30 min met 2,5 mg verhogen tot streefwaarde bereikt, daarna 3 mg/uur",
    contraIndicaties: "Leverfalen",
    bijwerkingen: "Hoofdpijn, reflex-tachycardie",
  },
  {
    index: 5,
    title: "Nitroglycerine",
    beginWerking: "1 tot 5 min",
    duurWerking: "3 - 5 min",
    dosering:
      "5-200 mcg/min, startdosis 5 mcg/min, iedere 3-5 min verhogen met 5 mcg/min",
    contraIndicaties: "",
    bijwerkingen: "Hoofdpijn, reflex-tachycardie",
  },
  {
    index: 6,
    title: "Nitroprusside",
    beginWerking: "Onmiddelijk",
    duurWerking: "1 - 2 min",
    dosering:
      "0,3-5 mcg/kg/min, startdosis 0,5 mcg/kg/min iedere 3-5 min verhogen met 0,5 mcg/kg/min tot streefwaarde bereikt",
    contraIndicaties: "Lever/nierfalen (relatief)",
    bijwerkingen: "Cyanide intoxicatie",
  },
  {
    index: 7,
    title: "Urapidil",
    beginWerking: "3 tot 5 min",
    duurWerking: "4 - 6 uur",
    dosering:
      "12,5-25 mg als bolus injectie, daarna 1-8 mg/uur als continu infuus",
    contraIndicaties: "",
    bijwerkingen: "",
  },
  {
    index: 8,
    title: "Fentolamine",
    beginWerking: "1 tot 2 min",
    duurWerking: "10 - 30 min",
    dosering:
      "1-5 mg als bolus, evt. herhalen na 15 min OF 6-40 mg/uur als continu infuus",
    contraIndicaties: "",
    bijwerkingen: "Tachyaritmie, pijn op de borst",
  },
];

export default function SelectTableIntraveneuzeGeneesmiddelenVoorDeBehandelingVanHypertensieveSpoedgevallen() {
  const [current, setCurrent] = useState(0);
  return (
    <>
      <Card>
        <Row>
          <Col xs={24}>
            <Title level={4}>Geneesmiddel:</Title>
            <Select
              size="large"
              style={{ width: "100%", marginTop: "8px" }}
              onChange={setCurrent}
              showSearch
              autoFocus
              defaultValue="Esmolol "
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
              placeholder="Selecteer een geneesmiddel.."
            >
              {data.map((item) => (
                <Option value={item.index} key={item.title} title={item.title}>
                  {item.title}
                </Option>
              ))}
            </Select>
          </Col>
          <Col xs={24}>
            <Divider />
            <Title level={4}>Informatie:</Title>
            <Descriptions
              column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
              bordered
              size="small"
              style={{ background: "white", marginTop: "16px" }}
            >
              <Descriptions.Item label="Begin werking">
                {data[current].beginWerking}
              </Descriptions.Item>
              <Descriptions.Item label="Duur werking">
                {data[current].duurWerking}
              </Descriptions.Item>
              <Descriptions.Item label="Dosering">
                {data[current].dosering}
              </Descriptions.Item>
              <Descriptions.Item label="Contra-indicaties">
                {data[current].contraIndicaties}
              </Descriptions.Item>
              <Descriptions.Item label="Bijwerkingen">
                {data[current].bijwerkingen}
              </Descriptions.Item>
            </Descriptions>
          </Col>
        </Row>
      </Card>
    </>
  );
}
