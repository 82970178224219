import React, { useState } from "react";
import { Card, Divider, Select, Descriptions, Button } from "antd";
import ButtonWithIndicator from "../ui/ButtonWithIndicator";

const Option = Select.Option;

const OptionsSelectComponent = ({ onNext, steps, title }) => {
  const [current, setCurrent] = useState(0);
  const [buttonDisabled, setButtonDisabled] = useState(true);

  const onChange = (value) => {
    setCurrent(value);
    setButtonDisabled(false);
  };

  const buttonStyleActive = {
    backgroundColor: "#882941",
    borderColor: "#882941",
  };

  const buttonStyle = buttonDisabled ? null : buttonStyleActive;

  return (
    <>
      <Card size="small" title={title} type="inner" extra={<></>}>
        <Select
          size="large"
          style={{ width: "100%" }}
          onChange={onChange}
          showSearch
          filterOption={(input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
            0
          }
          placeholder="Selecteer een optie:"
        >
          {steps.map((item) => (
            <Option value={item.index} key={item.index}>
              {item.optionTitle}
            </Option>
          ))}
        </Select>
        <Descriptions bordered size="small">
          <Descriptions.Item label="Selectie:" span={4}>
            {steps[current].optionTitle}
          </Descriptions.Item>
        </Descriptions>
      </Card>
      <div>
        <Divider
          orientation="left"
          plain
          style={{
            margin: "15px 0 10px 0",
            fontSize: "12px",
            borderColor: "#882941",
          }}
        >
          Opties:
        </Divider>
        {!buttonDisabled > 0 && (
          <ButtonWithIndicator
            key={steps[current].nodeId}
            onNext={onNext}
            k={steps[current].nodeId}
            v="Volgende stap"
          />
        )}
        {buttonDisabled > 0 && <Button disabled>Volgende stap</Button>}
      </div>
    </>
  );
};

export default OptionsSelectComponent;
