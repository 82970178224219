import React from "react";

import ContentLinkExternalAcuteBoekje from "../ui/ContentLinkExternalAcuteBoekje";
import ContentLinkExternalFk from "../ui/ContentLinkExternalFk.js";
import ContentLinkExternalFNT from "../ui/ContentLinkExternalFNT";
import ContentLinkExternalKf from "../ui/ContentLinkExternalKf.js";
import ContentLinkExternalRldb from "../ui/ContentLinkExternalRldb";

/* eslint-disable react/display-name */
export const ContentLinkExternal = {
  ContentLinkExternalAcuteBoekje: ({ title, url }) => (
    <ContentLinkExternalAcuteBoekje title={title} url={url} />
  ),
  ContentLinkExternalFk: ({ title, url }) => (
    <ContentLinkExternalFk title={title} url={url} />
  ),
  ContentLinkExternalFNT: ({ title, url }) => (
    <ContentLinkExternalFNT title={title} url={url} />
  ),
  ContentLinkExternalKf: ({ title, url }) => (
    <ContentLinkExternalKf title={title} url={url} />
  ),
  ContentLinkExternalRldb: ({ title, url }) => (
    <ContentLinkExternalRldb title={title} url={url} />
  ),
};
