import React from "react";
import { Row, Col, Modal, Typography } from "antd";
const { Title } = Typography;

class ModalContentKatnhElearning extends React.Component {
  constructor() {
    super();
    this.state = { visible: false };
  }

  showDrawer = () => {
    this.setState({
      visible: true,
    });
  };

  onClose = () => {
    this.setState({
      visible: false,
    });
  };

  render() {
    return (
      <>
        <div
          onClick={this.showDrawer}
          style={{
            width: "160px",
            height: "160px",
            backgroundColor: this.props.danger ? "#DE6161" : "#c9d22a",
            color: "#1d396e",
            padding: "10px",
            margin: "5px",
            cursor: "pointer",
            display: "inline-block",
            position: "relative",
          }}
          className="styles-katnh"
        >
          <Title
            level={4}
            style={{
              fontFamily: "Roboto Slab",
              fontSize: "16px",
              textDecoration: "none",
              color: "#1d396e",
              paddingBottom: "5px",
            }}
          >
            {this.props.trigger}
          </Title>
          {this.props.description && (
            <>
              <span
                style={{
                  display: "block",
                  fontWeight: "400",
                  fontSize: "13px",
                }}
              >
                Zoals
              </span>
              <span
                style={{
                  display: "block",
                  fontWeight: "300",
                  fontSize: "13px",
                }}
              >
                {this.props.description}
              </span>
            </>
          )}
          <img
            src="/client/katnh/arrow_blue.svg"
            alt="arrow"
            style={{ height: "17px", position: "absolute", bottom: "10px" }}
          />
        </div>
        <Modal
          title={this.props.title}
          visible={this.state.visible}
          onClose={this.onClose}
          onCancel={this.onClose}
          drawerStyle={{ backgroundColor: "white" }}
          className="modal-katnh modal-katnh-elearning styles-katnh"
          width="75%"
          footer={null}
          closable={false}
          bodyStyle={{ padding: "30px 45px" }}
          centered
        >
          {!this.props.hideTitle && (
            <>
              <img
                src="/client/katnh/small_dots.svg"
                alt="divider"
                style={{ height: "2px" }}
              />
              <Title
                level={4}
                style={{
                  fontFamily: "Roboto Slab",
                  fontSize: "20px",
                  textDecoration: "none",
                  color: "#1d396e",
                  paddingBottom: "5px",
                }}
              >
                {this.props.title}
              </Title>
            </>
          )}
          <Row justify="space-between">
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <img
                src="/client/katnh/small_dots.svg"
                alt="divider"
                style={{ height: "2px" }}
              />
              {this.props.content}
            </Col>
            <Col xs={24} sm={24} md={9} lg={9} xl={9} className="image-wrapper">
              {!this.props.hideImage && (
                <img
                  src={this.props.image}
                  alt="image"
                  style={{
                    maxHeight: "350px",
                    maxWidth: "100%",
                    margin: "0 auto",
                    position: "relative",
                    top: "-20px",
                    right: "0px",
                  }}
                  className="image-main"
                />
              )}
            </Col>
          </Row>
          {!this.props.hideTitle && (
            <Row>
              <Col>
                <div onClick={this.onClose} className="button-close">
                  <img
                    src="/client/katnh/small_dots.svg"
                    alt="divider"
                    style={{ height: "2px" }}
                  />
                  <span
                    style={{
                      display: "block",
                      padding: "0 0 8px 0",
                      fontSize: "14px",
                    }}
                  >
                    Sluit
                  </span>
                  <img
                    src="/client/katnh/icon_close.svg"
                    alt="arrow"
                    style={{ height: "17px", marginTop: "5px" }}
                    className="icon-blue"
                  />
                  <img
                    src="/client/katnh/icon_close_white.svg"
                    alt="arrow"
                    style={{ height: "17px", marginTop: "5px" }}
                    className="icon-white"
                  />
                </div>
              </Col>
            </Row>
          )}
        </Modal>
      </>
    );
  }
}

export default ModalContentKatnhElearning;
