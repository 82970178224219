import React, { useState } from "react";
import { Button } from "antd";
import { useDebounceFn } from "ahooks";

export default function ButtonWithIndicator({ onNext, k, v, disabled, size }) {
  const [isClicked, setIsClicked] = useState(false);
  const { run } = useDebounceFn(
    (k) => {
      onNext(k);
    },
    {
      wait: 250,
    }
  );

  return (
    <Button
      loading={isClicked}
      onClick={() => {
        setIsClicked(true);
        run(k);
      }}
      style={{
        marginTop: "7.5px",
        marginRight: "7.5px",
        whitespace: "normal",
      }}
      type="primary"
      disabled={disabled}
      size={size}
    >
      {v}
    </Button>
  );
}
