import { Alert, Divider } from "antd";
import DataUniPolaireDepressie from "../../../../src/client/nvvp/json/unipolaire-depressie-zonder-melancholische-of-psychotische-kenmerken.json";
import ChartCollapse from "../../../../src/components/interactions/basic-collapse/Chart.js";
import * as React from 'react';
export default {
  Alert,
  Divider,
  DataUniPolaireDepressie,
  ChartCollapse,
  React
};