import React, { Component } from "react";
import ReactFromJSON from "react-from-json";
import Node from "./Nodes";

import { Carousel, Row, Divider, Typography, Col } from "antd";

import { Link } from "gatsby";
import * as Scroll from "react-scroll";
import ContentAlert from "../../ui/ContentAlert";
import ModalContentKatnhElearning from "../modalContentElearning";
import ButtonElearning from "../../ui/ButtonElearning";
import CarouselModalModuleBPlaatjesremmer from "../../ui/CarouselModalModuleBPlaatjesremmer";
import CarouselModalModuleBVka from "../../ui/CarouselModalModuleBVka";
const scroller = Scroll.scroller;
const { Paragraph, Text, Title } = Typography;

export default class ChartElearning extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // The 'path' of 'nodes' is being tracked in the 'nodePath' array in the react state.
      nodePath: [this.props.firstNodeId],
      // Set firstNodeId with props
      firstNodeId: this.props.firstNodeId,
      // isLastOnPath
      isLastOnPath: this.props.firstNodeId,
    };
  }

  // Next Node
  onNext = (nodeId) => {
    // Check what the current position of the node is in our Path
    const nodeCurrentPosition = this.state.nodePath.indexOf(nodeId);
    // console.log("nodeCurrentPosition:", nodeCurrentPosition);
    // Check if the next node is on our Path already. If not, this node will be added to our Path.
    if (nodeCurrentPosition === -1) {
      // Concat the node to our path / state.
      const addNextNode = this.state.nodePath.concat([nodeId]);
      // console.log("addNextNode:", addNextNode);
      this.setState({
        nodePath: addNextNode,
      });
      // Get the value of the next node as a string to be able to scroll and update state.
      const nextNodeId = nodeId.toString();
      // console.log("nextNodeId:", nextNodeId);
      this.setState({
        isLastOnPath: nextNodeId,
      });
      // Scroll to the next Node if not iFramed
      const windowHeight = window.innerHeight;
      const scrollOffset = 0.33 * windowHeight;
      setTimeout(function () {
        if (!("parentIFrame" in window)) {
          scroller.scrollTo(nextNodeId, {
            duration: 300,
            delay: 25,
            smooth: true,
            offset: -scrollOffset,
            isDynamic: true,
          });
        }
      }, 50);
      // Scroll Parent
      setTimeout(function () {
        const contentHeight = document.getElementById(nodeId).offsetTop;
        const contentHeightNew = contentHeight - 125;
        if ("parentIFrame" in window) {
          window.parentIFrame.scrollToOffset(0, contentHeightNew);
        }
      }, 50);
      // If the next node is already in our Path we can't add it again. We handle it with goTo.
    } else {
      this.onGoTo(nodeId);
    }
  };

  // Go To Node
  onGoTo = (nodeId) => {
    // Check what the current position of the node is in our Path.
    const nodeCurrentPosition = this.state.nodePath.indexOf(nodeId);
    // console.log("nodeCurrentPosition:", nodeCurrentPosition);
    // If the position of this node exists so we can update with this node.
    if (nodeCurrentPosition !== -1) {
      // Get to value of the next node and setState nodePath.
      const goToNode = this.state.nodePath.slice(0, nodeCurrentPosition + 1);
      // console.log("goToNode:", goToNode);
      this.setState({
        nodePath: goToNode,
      });
      const goToNodeId = nodeId.toString();
      // console.log("goToNodeId:", goToNodeId);
      this.setState({
        isLastOnPath: goToNodeId,
      });
      // Scroll to the next Node if not iFramed
      const windowHeight = window.innerHeight;
      const scrollOffset = 0.33 * windowHeight;
      setTimeout(function () {
        if (!("parentIFrame" in window)) {
          scroller.scrollTo(goToNodeId, {
            duration: 300,
            delay: 25,
            smooth: true,
            offset: -scrollOffset,
            isDynamic: true,
          });
        }
      }, 50);
      // Scroll Parent
      setTimeout(function () {
        const contentHeight = document.getElementById(nodeId).offsetTop;
        const contentHeightNew = contentHeight - 125;
        if ("parentIFrame" in window) {
          window.parentIFrame.scrollToOffset(0, contentHeightNew);
        }
      }, 50);
    }
  };

  // Previous Node (back)
  onPrevious = (nodeId) => {
    // Check what the current position of the node is in our Path
    const nodeCurrentPosition = this.state.nodePath.indexOf(nodeId);
    // console.log("nodeCurrentPosition:", nodeCurrentPosition);
    const previousNode = this.state.nodePath.slice(0, nodeCurrentPosition);
    // console.log("previousNode:", previousNode);
    // Check if previous node exits
    if (previousNode.length > 0) {
      // setState nodePath
      this.setState({
        nodePath: previousNode,
      });
      // ScrollTo and update state
      const previousNodeId =
        this.state.nodePath[this.state.nodePath.length - 2].toString();
      // console.log("previousNodeId:", previousNodeId);
      this.setState({
        isLastOnPath: previousNodeId,
      });
      const windowHeight = window.innerHeight;
      const scrollOffset = 0.25 * windowHeight;
      // scroll to previous node if not iframed
      setTimeout(function () {
        if (!("parentIFrame" in window)) {
          scroller.scrollTo(previousNodeId, {
            duration: 300,
            delay: 25,
            smooth: true,
            offset: -scrollOffset,
            isDynamic: true,
          });
        }
      }, 50);
      // Scroll Parent
      setTimeout(function () {
        const contentHeight = document.getElementById(nodeId).offsetTop;
        const contentHeightNew = contentHeight - 125;
        if ("parentIFrame" in window) {
          window.parentIFrame.scrollToOffset(0, contentHeightNew);
        }
      }, 50);
    }
  };

  // Reset Nodes
  onReset = () => {
    // Get value of the first node in our chart and reset path
    const resetNode = this.state.firstNodeId;
    // console.log("resetNode:", resetNode);
    this.setState({
      nodePath: [resetNode],
    });
    // Scroll to
    const resetNodeId = this.state.firstNodeId.toString();
    // console.log("resetNodeId:", resetNodeId);
    // Update state
    this.setState({
      isLastOnPath: resetNodeId,
    });
    const windowHeight = window.innerHeight;
    const scrollOffset = 0.25 * windowHeight;
    // scroll to previous node if not iframed
    setTimeout(function () {
      if (!("parentIFrame" in window)) {
        scroller.scrollTo(resetNodeId, {
          duration: 300,
          delay: 25,
          smooth: true,
          offset: -scrollOffset,
          isDynamic: true,
        });
      }
    }, 50);
    // Scroll parent
    setTimeout(function () {
      if ("parentIFrame" in window) {
        window.parentIFrame.scrollToOffset(0, -90);
      }
    }, 50);
  };

  render = () => {
    const mapping = {
      chart: ({ nodes, title }) => <>{nodes}</>,
      node: ({
        nodeId,
        nodeTitle,
        nodeCount,
        module,
        links,
        content,
        isEndNode,
        widget,
        widget2,
        linksButtons,
        image,
      }) => (
        <Node
          nodeId={nodeId}
          nodeTitle={nodeTitle}
          nodeCount={nodeCount}
          links={links}
          //
          key={nodeId}
          onNext={this.onNext}
          onGoTo={this.onGoTo}
          onPrevious={this.onPrevious}
          onReset={this.onReset}
          //
          linksActive={
            this.state.nodePath[this.state.nodePath.length - 1] !== nodeId
              ? this.state.nodePath[this.state.nodePath.indexOf(nodeId) + 1]
              : null
          }
          //
          isOnPath={this.state.nodePath.indexOf(nodeId) !== -1}
          isFirstOnPath={this.state.firstNodeId === nodeId}
          isLastOnPath={this.state.isLastOnPath === nodeId}
          isEndNode={isEndNode}
          widget={widget}
          widget2={widget2}
          image={image}
          linksButtons={linksButtons}
          module={module}
        >
          {content}
        </Node>
      ),
      button: ({ text, to }) => (
        <Paragraph>
          <Link className="link" style={{ color: "white" }} to={to}>
            {text} »
          </Link>
        </Paragraph>
      ),
      buttonNavigation: ({ onNext, k, text, previous }) => (
        <ButtonElearning
          onNext={this.onNext}
          k={k}
          text={text}
          previous={previous}
        />
      ),
      alert: ({ type, message }) => (
        <ContentAlert message={message} type={type} />
      ),
      carouselModalModuleBPlaatjesremmer: ({ onClose }) => (
        <CarouselModalModuleBPlaatjesremmer onClose={onClose} />
      ),
      carouselModalModuleBVka: ({ onClose }) => (
        <CarouselModalModuleBVka onClose={onClose} />
      ),
      list: ({ items, size, header }) => (
        <Paragraph>
          <ul style={{ color: "white", fontSize: "15px" }}>
            {items.map((item, index) => (
              <li
                key={index}
                style={{ listStyleType: "disc", padding: "3px 0" }}
              >
                {item}
              </li>
            ))}
          </ul>
        </Paragraph>
      ),
      paragraph: ({ text, children }) => (
        <Paragraph style={{ fontSize: "15px", lineHeight: "1.66" }}>
          {text} {children}
        </Paragraph>
      ),
      link: ({ to, text }) => (
        <Paragraph style={{ fontSize: "15px" }}>
          <a href={to} target="_blank" rel="noreferrer noopener">
            {text}
          </a>
        </Paragraph>
      ),
      title: ({ title, level }) => (
        <>
          <Divider
            style={{
              margin: "16px 0 4px 0",
              height: "0",
              color: "transparent",
            }}
          />
          <Title
            className="title"
            level={4}
            style={{
              fontSize: "20px",
              fontWeight: "normal",
              letterSpacing: "0.66px",
            }}
          >
            {title}
          </Title>
        </>
      ),
      text: ({
        text,
        type,
        code,
        copyable,
        disabled,
        ellipsis,
        mark,
        keyboard,
        underline,
        strong,
        italic,
        color,
      }) => (
        <Text
          type={type}
          code={code}
          copyable={copyable}
          disabled={disabled}
          ellipsis={ellipsis}
          mark={mark}
          keyboard={keyboard}
          underline={underline}
          strong={strong}
          italic={italic}
          style={{ fontSize: "15px", color: color }}
        >
          {text}
        </Text>
      ),
      image: ({ src, alt }) => (
        <Col flex>
          <img
            src={src}
            alt={alt}
            style={{
              maxHeight: "350px",
              maxWidth: "100%",
              margin: "0 auto",
              position: "relative",
              top: "30px",
            }}
            className="image-main"
          />
        </Col>
      ),
      ModalContentKatnhElearning: ({
        trigger,
        title,
        description,
        content,
        image,
        danger,
        hideImage,
        hideTitle,
      }) => (
        <ModalContentKatnhElearning
          trigger={trigger}
          title={title}
          description={description}
          content={content}
          image={image}
          danger={danger}
          hideImage={hideImage}
          hideTitle={hideTitle}
        />
      ),
    };

    return (
      <>
        <Row
          type="flex"
          align={this.props.align}
          style={{ position: "relative", maxWidth: "1120px", margin: "0 auto" }}
        >
          <ReactFromJSON entry={this.props.entry} mapping={mapping} />
          <div id="bottom"></div>
        </Row>
      </>
    );
  };
}
