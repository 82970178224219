import React from "react";
import TrackVisibility from "react-on-screen";
import {
  BrowserView,
  CustomView,
  TabletView,
  isMobileOnly,
} from "react-device-detect";
import { Card, Col, Typography, Tooltip, Button } from "antd";
import LinksButtons from "../../ui/LinksButtons";
import NodeArrowAnchors from "./components/NodeArrowAnchors";
import NodeTypeIndicator from "../../ui/NodeTypeIndicator";
import { RollbackOutlined, UndoOutlined } from "@ant-design/icons";

export default function NodeCardActiveWithGrid({
  children,
  disableDefaultOptionsButtons,
  extraContent,
  links,
  nodeId,
  nodeType,
  onNext,
  options,
  offset,
  onReset,
  span,
  onPrevious,
  language,
  previousNode,
}) {
  const textButtonRestart = language === "nl" ? "Herstarten" : "Restart";
  const textButtonRestartTooltip =
    language === "nl" ? "Herstart deze chart" : "Restart this chart";
  return (
    <Col
      className="node-wrapper"
      id={nodeId}
      key={nodeId}
      offset={offset}
      span={span}
      style={{ zIndex: "1000" }}
    >
      <Card
        title={<NodeTypeIndicator nodeType={nodeType} />}
        style={{
          border: "2px solid #153A80",
          borderRadius: "4px",
          boxShadow:
            "rgba(17, 17, 26, 0.1) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 48px",
          cursor: "help",
        }}
        className="node-active"
      >
        <BrowserView>
          <>{children}</>
          {extraContent && <>{extraContent}</>}
          {options && <>{options}</>}
          {!disableDefaultOptionsButtons && (
            <div>
              <LinksButtons links={links} onNext={onNext} />
            </div>
          )}
          {previousNode.length > 1 && (
            <Tooltip placement="right" title="To previous step">
              <Button
                size="small"
                type="primary"
                ghost
                onClick={onPrevious}
                style={{ marginTop: "10px" }}
              >
                <>
                  <RollbackOutlined /> Previous
                </>
              </Button>
            </Tooltip>
          )}
          {previousNode.length < 2 && (
            <>
            {nodeId !== "nodeStart" && (
            <Tooltip placement="top" title={textButtonRestartTooltip}>
              <Button size="small" onClick={() => onReset()} style={{ marginTop: "10px" }}>
                <UndoOutlined /> {textButtonRestart}
              </Button>
            </Tooltip>
            )}
            </>
          )}
        </BrowserView>
        <TabletView>
          <>{children}</>
          {extraContent && <>{extraContent}</>}
          {options && <>{options}</>}
          {!disableDefaultOptionsButtons && (
            <>
              <LinksButtons links={links} onNext={onNext} />
            </>
          )}
          {previousNode.length > 1 && (
            <Tooltip placement="right" title="To previous step">
              <Button
                size="small"
                type="primary"
                ghost
                onClick={onPrevious}
                style={{ marginTop: "10px" }}
              >
                <>
                  <RollbackOutlined /> Previous
                </>
              </Button>
            </Tooltip>
          )}
        </TabletView>
        <CustomView condition={isMobileOnly}>
          <TrackVisibility offset={50}>
            {({ isVisible }) =>
              isVisible ? (
                <>
                  <div
                    style={{
                      position: "fixed",
                      left: "0",
                      bottom: "0",
                      backgroundColor: "white",
                      width: "100%",
                      padding: "24px",
                      boxShadow: "-6px -6px 10px rgba(17, 17, 26, 0.1)",
                      borderTop: "2px solid #153A80",
                    }}
                  >
                    <>{children}</>
                    {extraContent && <>{extraContent}</>}
                    {options && <>{options}</>}
                    {!disableDefaultOptionsButtons && (
                      <>
                        <LinksButtons links={links} onNext={onNext} />
                      </>
                    )}
                    {previousNode.length > 1 && (
                      <Tooltip placement="right" title="To previous step">
                        <Button
                          size="small"
                          type="primary"
                          ghost
                          onClick={onPrevious}
                          style={{ marginTop: "10px" }}
                        >
                          <>
                            <RollbackOutlined /> Previous
                          </>
                        </Button>
                      </Tooltip>
                    )}
                  </div>
                </>
              ) : null
            }
          </TrackVisibility>
        </CustomView>
      </Card>
      <NodeArrowAnchors nodeId={nodeId} />
    </Col>
  );
}
