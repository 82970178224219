import React from "react";
import { Row, Col, Typography } from "antd";
import { FullscreenOutlined } from "@ant-design/icons";
const { Text } = Typography;

const ButtonSchematic = ({ url }) => {
  return (
    <>
      <Row type="flex" justify="center">
        <Col style={{ textAlign: "center" }}>
          <a
            href={url}
            target="_blank"
            rel="noreferrer noopener"
            className="ant-btn ant-btn-lg"
            style={{ marginBottom: "12px", display: "block" }}
          >
            Bekijk de schematische weergave
            <FullscreenOutlined style={{ marginLeft: "12px" }} />
          </a>
          <Text
            style={{ fontSize: "10px", marginBottom: "36px", display: "block" }}
          >
            <i>Opent in nieuw venster</i>
          </Text>
        </Col>
      </Row>
    </>
  );
};

export default ButtonSchematic;
