import React from "react";
import { Typography, Popover } from "antd";
import { StaticImage } from "gatsby-plugin-image";
import { ExportOutlined } from "@ant-design/icons";
const { Text } = Typography;

const style = {
  margin: "0 -0.4em",
  padding: "0.1em 0.4em",
  borderRadius: "0.8em 0.3em",
  background: "transparent",
  backgroundImage:
    "linear-gradient(to right,rgba(136, 41, 65, 0.05),rgba(136, 41, 65, 0.45) 4%,rgba(136, 41, 65, 0.2))",
  boxDecorationBreak: "clone",
  cursor: "context-menu",
};

const ContentLinkExternalAcuteBoekje = ({ title, url }) => {
  return (
    <>
      <Popover
        color="#882941"
        placement="topRight"
        content={
          <div>
            <a
              href={url}
              target="_blank"
              rel="noreferrer noopener"
              style={{
                color: "#fff",
                marginBottom: "24px",
                textDecoration: "underline",
              }}
            >
              <StaticImage
                src="../../images/logo/logoAcuteBoekje.png"
                alt="Logo Acute Boekje"
                width={48}
                style={{ marginRight: "8px", top: "-2px" }}
              />
              <span style={{ fontSize: "14px" }}>Het Acute Boekje {"> "}</span>
              {title}
            </a>
            <br />
            <Text style={{ fontSize: "11px", color: "#fff" }}>
              <ExportOutlined style={{ transform: "rotate(-90deg)" }} /> opent
              in een nieuw venster
            </Text>
          </div>
        }
        trigger="hover"
      >
        <Text style={style}>
          <ExportOutlined
            style={{
              transform: "rotate(-90deg)",
              color: "#882941",
              marginLeft: "1.5px",
            }}
          />{" "}
          {title}
        </Text>
      </Popover>
    </>
  );
};

export default ContentLinkExternalAcuteBoekje;
