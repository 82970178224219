import React, { Component } from "react";
import _ from "lodash";
import { Row, Col, Button, Typography, Icon } from "antd";
import { ArrowRightOutlined } from "@ant-design/icons";
const { Title } = Typography;

export default class Node extends Component {
  constructor(props) {
    super(props);
  }

  render = () => {
    const { nodeId, isLastOnPath, isOnPath, onGoTo, isEndNode } = this.props;
    const stepClass = isOnPath
      ? "decision-step-visible"
      : "decision-step-invisible";
    const onNodeGoTo = () => onGoTo(nodeId);

    if (!isOnPath) {
      return (
        <Col
          id={nodeId}
          key={nodeId}
          span={24}
          offset={0}
          style={{ display: "none" }}
        ></Col>
      );
    } else if (isOnPath && !isLastOnPath) {
      return (
        <Col
          id={nodeId}
          key={nodeId}
          span={24}
          offset={0}
          style={{ display: "none" }}
        ></Col>
      );
    } else if (isLastOnPath) {
      return (
        <Col
          id={nodeId}
          key={nodeId}
          span={24}
          offset={0}
          className={`decision-step-focused elearning-wrapper ${stepClass}`}
        >
          <Row>
            <Col
              style={{
                color: "#c9d22a",
                padding: "0 0 30px 0",
                fontSize: "14px",
                textTransform: "uppercase",
              }}
            >
              {this.props.module}
              <br />
              <span style={{ fontWeight: "300" }}>
                STAP {this.props.nodeCount}
              </span>
            </Col>
          </Row>
          <Row justify="space-between">
            <Col
              xs={24}
              sm={12}
              md={11}
              lg={12}
              xl={11}
              style={{ paddingBottom: "30px", minHeight: "350px" }}
            >
              <img
                src="/client/katnh/small_dots_green.svg"
                alt="divider"
                style={{ height: "2px", marginTop: "16px" }}
              />
              <Title
                style={{
                  fontSize: "22px",
                  fontWeight: "normal",
                  letterSpacing: "0.66px",
                  color: "white",
                }}
              >
                {this.props.nodeTitle}
              </Title>
              <img
                src="/client/katnh/small_dots_green.svg"
                alt="divider"
                style={{
                  height: "2px",
                  marginTop: "16px",
                  marginBottom: "5p-x",
                }}
              />
              {this.props.children}
            </Col>
            {this.props.widget && (
              <Col
                xs={24}
                sm={10}
                md={11}
                lg={10}
                xl={10}
                className="widget-main"
              >
                <Row justify="end">{this.props.widget}</Row>
              </Col>
            )}
            {this.props.widget2 && (
              <Col
                xs={24}
                sm={11}
                md={11}
                lg={11}
                xl={11}
                className="widget-main"
              >
                <Row style={{ flexDirection: "column" }} justify="start">
                  {this.props.widget2}
                  <img
                    src="/client/katnh/image_7.svg"
                    alt="alt"
                    style={{
                      width: "35%",
                      position: "absolute",
                      bottom: "-10px",
                      right: "0px",
                    }}
                  />
                </Row>
              </Col>
            )}
            {this.props.image && (
              <Col xs={24} sm={10} md={11} lg={12} xl={12}>
                <Row justify="end">{this.props.image}</Row>
              </Col>
            )}
          </Row>
          <Row>
            <Col>{this.props.linksButtons}</Col>
          </Row>
        </Col>
      );
    }
  };
}
