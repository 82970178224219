import React, { Component } from "react";
import { Checkbox, Divider, Statistic, Typography } from "antd";
import ContentAlert from "../ui/ContentAlert";

const { Text } = Typography;

const ITEM_GROUPS = [
  {
    id: "group_one",
    title: "Pijn",
    choices: [
      {
        id: "choice_one_zero",
        title: "afwezig",
        score: 0,
      },
      {
        id: "choice_one_one",
        title: "mild",
        score: 1,
      },
      {
        id: "choice_one_two",
        title: "matig",
        score: 2,
      },
      {
        id: "choice_one_three",
        title: "ernstig",
        score: 3,
      },
    ],
  },
  {
    id: "group_two",
    title: "Krampen",
    choices: [
      {
        id: "choice_two_zero",
        title: "afwezig",
        score: 0,
      },
      {
        id: "choice_two_one",
        title: "mild",
        score: 1,
      },
      {
        id: "choice_two_two",
        title: "matig",
        score: 2,
      },
      {
        id: "choice_two_three",
        title: "ernstig",
        score: 3,
      },
    ],
  },
  {
    id: "group_three",
    title: "Zwaar gevoel",
    choices: [
      {
        id: "choice_three_zero",
        title: "afwezig",
        score: 0,
      },
      {
        id: "choice_three_one",
        title: "mild",
        score: 1,
      },
      {
        id: "choice_three_two",
        title: "matig",
        score: 2,
      },
      {
        id: "choice_three_three",
        title: "ernstig",
        score: 3,
      },
    ],
  },
  {
    id: "group_four",
    title: "Tintelingen",
    choices: [
      {
        id: "choice_four_zero",
        title: "afwezig",
        score: 0,
      },
      {
        id: "choice_four_one",
        title: "mild",
        score: 1,
      },
      {
        id: "choice_four_two",
        title: "matig",
        score: 2,
      },
      {
        id: "choice_four_three",
        title: "ernstig",
        score: 3,
      },
    ],
  },
  {
    id: "group_five",
    title: "Jeuk",
    choices: [
      {
        id: "choice_five_zero",
        title: "afwezig",
        score: 0,
      },
      {
        id: "choice_five_one",
        title: "mild",
        score: 1,
      },
      {
        id: "choice_five_two",
        title: "matig",
        score: 2,
      },
      {
        id: "choice_five_three",
        title: "ernstig",
        score: 3,
      },
    ],
  },
  {
    id: "group_six",
    title: "Oedeem",
    choices: [
      {
        id: "choice_six_zero",
        title: "afwezig",
        score: 0,
      },
      {
        id: "choice_six_one",
        title: "mild",
        score: 1,
      },
      {
        id: "choice_six_two",
        title: "matig",
        score: 2,
      },
      {
        id: "choice_six_three",
        title: "ernstig",
        score: 3,
      },
    ],
  },
  {
    id: "group_seven",
    title: "Induratie huid",
    choices: [
      {
        id: "choice_seven_zero",
        title: "afwezig",
        score: 0,
      },
      {
        id: "choice_seven_one",
        title: "mild",
        score: 1,
      },
      {
        id: "choice_seven_two",
        title: "matig",
        score: 2,
      },
      {
        id: "choice_seven_three",
        title: "ernstig",
        score: 3,
      },
    ],
  },
  {
    id: "group_eight",
    title: "Hyperpigmentatie",
    choices: [
      {
        id: "choice_eight_zero",
        title: "afwezig",
        score: 0,
      },
      {
        id: "choice_eight_one",
        title: "mild",
        score: 1,
      },
      {
        id: "choice_eight_two",
        title: "matig",
        score: 2,
      },
      {
        id: "choice_eight_three",
        title: "ernstig",
        score: 3,
      },
    ],
  },
  {
    id: "group_nine",
    title: "Erytheem",
    choices: [
      {
        id: "choice_nine_zero",
        title: "afwezig",
        score: 0,
      },
      {
        id: "choice_nine_one",
        title: "mild",
        score: 1,
      },
      {
        id: "choice_nine_two",
        title: "matig",
        score: 2,
      },
      {
        id: "choice_nine_three",
        title: "ernstig",
        score: 3,
      },
    ],
  },
  {
    id: "group_ten",
    title: "Veneuze ectasieën",
    choices: [
      {
        id: "choice_ten_zero",
        title: "afwezig",
        score: 0,
      },
      {
        id: "choice_ten_one",
        title: "mild",
        score: 1,
      },
      {
        id: "choice_ten_two",
        title: "matig",
        score: 2,
      },
      {
        id: "choice_ten_three",
        title: "ernstig",
        score: 3,
      },
    ],
  },
  {
    id: "group_eleven",
    title: "Pijn bij knijpen in de kuit",
    choices: [
      {
        id: "choice_eleven_zero",
        title: "afwezig",
        score: 0,
      },
      {
        id: "choice_eleven_one",
        title: "mild",
        score: 1,
      },
      {
        id: "choice_eleven_two",
        title: "matig",
        score: 2,
      },
      {
        id: "choice_eleven_three",
        title: "ernstig",
        score: 3,
      },
    ],
  },
];

export default class WellsScore extends Component {
  constructor(props) {
    super(props);

    const selectedChoices = {};
    for (const itemGroup of ITEM_GROUPS) {
      selectedChoices[itemGroup.id] = null;
    }

    this.state = {
      selectedChoices,
    };
  }

  render = () => {
    return (
      <>
        <div className="wrapper-interactive">
          <h4>Villalta-score</h4>
          <Divider />
          {this._renderGroups()}
          <Statistic title="Score:" value={this._renderScore()} />
          <ContentAlert
            showIcon
            type="info"
            message={this._renderScoreText()}
            style={{ marginTop: "10px" }}
          />
        </div>
      </>
    );
  };

  _renderGroups = () => {
    return ITEM_GROUPS.map((group) => {
      return (
        <div key={group.id}>
          <div style={{ padding: "15px 0" }}>
            <Text style={{ color: "white", fontWeight: 400 }}>
              {group.title}
            </Text>
          </div>
          {group.choices.map((choice) => {
            return (
              <>
                <Checkbox
                  key={choice.id}
                  id={choice.id}
                  onChange={() => this._selectChoice(group, choice)}
                  checked={this.state.selectedChoices[group.id] === choice.id}
                  style={{ margin: "0 15px 15px 0px" }}
                />
                <label
                  for={choice.id}
                  style={{ color: "white", marginRight: "22.5px" }}
                >
                  {choice.title}{" "}
                </label>
              </>
            );
          })}
        </div>
      );
    });
  };

  _renderScore = () => {
    let score = 0;
    for (const group of ITEM_GROUPS) {
      for (const choice of group.choices) {
        if (choice.id === this.state.selectedChoices[group.id]) {
          score += choice.score;
        }
      }
    }

    return score;
  };

  _renderScoreText = () => {
    let score = 0;
    let scoreText = "";

    for (const group of ITEM_GROUPS) {
      for (const choice of group.choices) {
        if (choice.id === this.state.selectedChoices[group.id]) {
          score += choice.score;
        }
      }
    }

    if (score < 5) {
      scoreText = "Geen PTS";
    } else if (score > 4 && score < 10) {
      scoreText = "Mild PTS";
    } else if (score > 9 && score < 15) {
      scoreText = "Matig-Ernstig PTS";
    } else {
      scoreText = "Ernstig PTS";
    }

    return scoreText;
  };

  _selectChoice = (group, choice) => {
    const groupId = group.id;
    const choiceId = choice.id;
    const selectedChoiceId = this.state.selectedChoices[groupId];
    const newChoiceId = selectedChoiceId === choiceId ? null : choiceId;

    this.setState({
      selectedChoices: Object.assign(this.state.selectedChoices, {
        [groupId]: newChoiceId,
      }),
    });
  };
}
