import React from "react";
import { Card, Statistic } from "antd";
import { CloudTwoTone } from "@ant-design/icons";

const Weather = () => {
  return (
    <>
      <Card
        title="Tomorrow 19:00"
        size="small"
        headStyle={{ borderRadius: "10px", padding: "2.5px 15px" }}
        bodyStyle={{ backgroundColor: "#ffe066", padding: "15px" }}
        style={{
          width: "100%",
          position: "relative",
          marginBottom: "20px",
          borderWidth: "5px",
          borderColor: "#ffe066",
          borderRadius: "10px",
        }}
      >
        <Statistic title="Temperature" value="19 °C" />
        <Statistic title="Forecast" value="Lightly clouded" />
        <CloudTwoTone
          twoToneColor="#fff"
          style={{
            position: "absolute",
            top: "96px",
            right: "40px",
            fontSize: "64px",
            transform: "rotate(5deg)",
          }}
        />
      </Card>
    </>
  );
};

export default Weather;
