import React, { Component } from "react";
import {
  Col,
  Checkbox,
  Card,
  Button,
  Typography,
  Tag,
  Divider,
  List,
  Select,
} from "antd";
const { Text } = Typography;
const Option = Select.Option;

export default class OptionsItemsPointsComponentWithSelect extends Component {
  constructor(props) {
    super(props);

    const selectedChoices = {};
    for (const itemGroup of this.props.steps) {
      selectedChoices[itemGroup.id] = null;
    }

    this.state = {
      selectedChoices,
    };
  }

  render = () => {
    return (
      <>
        <Card size="small" title={this.props.title2} type="inner">
          <Select
            size="large"
            style={{ width: "100%" }}
            // onChange={onChange}
            showSearch
            filterOption={(input, option) =>
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
            placeholder="Selecteer een optie:"
          >
            {this.props.selectOptions.map((item) => (
              <Option value={item.index} key={item.index}>
                {item.optionTitle}
              </Option>
            ))}
          </Select>
        </Card>
        <Card
          size="small"
          title={this.props.title}
          type="inner"
          extra={
            <>
              <Text style={{ marginRight: "15px" }}>Score:</Text>
              <Tag color="#108ee9">{this._renderScore()}</Tag>
            </>
          }
        >
          <List bordered>{this._renderGroups()}</List>
        </Card>
        <div>
          <Divider
            orientation="left"
            plain
            style={{
              margin: "15px 0 10px 0",
              fontSize: "12px",
              borderColor: "#882941",
            }}
          >
            Uitkomst:
          </Divider>
          {this._renderProceed()}
        </div>
      </>
    );
  };

  _renderGroups = () => {
    return this.props.steps.map((group) => {
      return (
        <div key={group.id}>
          <Text>{group.title}</Text>
          {group.choices.map((choice) => {
            return (
              <List.Item>
                <Checkbox
                  key={choice.id}
                  id={choice.title}
                  onChange={() => this._selectChoice(group, choice)}
                  checked={this.state.selectedChoices[group.id] === choice.id}
                >
                  {choice.title}
                </Checkbox>
              </List.Item>
            );
          })}
        </div>
      );
    });
  };

  _renderScore = () => {
    let score = 0;
    for (const group of this.props.steps) {
      for (const choice of group.choices) {
        if (choice.id === this.state.selectedChoices[group.id]) {
          score += choice.score;
        }
      }
    }

    return score;
  };

  _renderProceed = () => {
    let score = 0;
    for (const group of this.props.steps) {
      for (const choice of group.choices) {
        if (choice.id === this.state.selectedChoices[group.id]) {
          score += choice.score;
        }
      }
    }

    if (score < this.props.cutoffValue) {
      return (
        <>
          <Col xs={24}>
            <Button
              type="primary"
              onClick={() => this.props.onNext(this.props.nodeLinkOne)}
              style={{
                backgroundColor: "#882941",
                borderColor: "#882941",
              }}
            >
              {this.props.nodeLinkOneLabel} ({this._renderScore()})
            </Button>
          </Col>
        </>
      );
    } else {
      return (
        <Col xs={24}>
          <Button
            type="primary"
            onClick={() => this.props.onNext(this.props.nodeLinkTwo)}
            style={{
              backgroundColor: "#882941",
              borderColor: "#882941",
            }}
          >
            {this.props.nodeLinkTwoLabel} ({this._renderScore()})
          </Button>
        </Col>
      );
    }
  };

  _selectChoice = (group, choice) => {
    const groupId = group.id;
    const choiceId = choice.id;
    const selectedChoiceId = this.state.selectedChoices[groupId];
    const newChoiceId = selectedChoiceId === choiceId ? null : choiceId;

    this.setState({
      selectedChoices: Object.assign(this.state.selectedChoices, {
        [groupId]: newChoiceId,
      }),
      buttonDisabled: false,
    });
  };
}
