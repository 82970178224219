import React from "react";
import { Button } from "antd";
import { CheckSquareOutlined } from "@ant-design/icons";

export default function LinksButtonActive3ie({ links, linksActive, onNext, size }) {
  return links.link.map((item) => {
    const buttonActive = {
      backgroundColor: "#f6f9eb",
      borderColor: "#8FB132",
      color: "#8FB132",
      marginRight: "6px",
      marginTop: "6px",
    };
    const buttonInactive = {
      marginRight: "6px",
      marginTop: "6px",
    };
    const buttonStyle =
      item.nodeId === linksActive ? buttonActive : buttonInactive;
    const buttonIcon =
      item.nodeId === linksActive ? <CheckSquareOutlined style={{ marginRight: "7px" }} /> : null;
    if (onNext) {
      return (
        <>
          <Button
            disabled
            key={item.nodeId}
            style={buttonStyle}
            type="primary"
            size={size}
          >
            <>{buttonIcon} {item.text}</>
          </Button>
        </>
      );
    }
  });
}
