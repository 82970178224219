import React from "react";

import { Row, Col, Divider, Select, Descriptions, Typography } from "antd";

import ContentAlert from "../ui/ContentAlert";

const Title = Typography.Title;
const Option = Select.Option;

const steps = [
  {
    index: 0,
    indicatie: "Eerste DVT",
    title: "Nadroparine",
    merknaam: "Fraxiparine®/ Fraxiparine forte®",
    profylaxe: "1dd 2.850 IE. Bij hoog risico profylaxe: 1dd 5.700 IE.",
    therapeurisch: "",
    alert:
      "Er bestaat een groot verschil tussen Fraxiparine® 0,6 en 0,8 ml en Fraxiparine Forte® 0,6 en 0,8 ml, namelijk 5700 en 7600 AXa-IE versus 11.400 en 15.200 AXa-IE. Bij het voorschrijven dient het gewenste preparaat goed opgeschreven te worden.",
    DoseNadroparine: "-",
  },
  {
    index: 1,
    indicatie: "Recidief DVT",
    title: "Dalteparine",
    merknaam: "Fragmin®",
    profylaxe: "1dd 2.500 IE. Bij hoog risico profylaxe: 1dd 5.000 IE.",
    therapeurisch: "",
    DoseDalteparine: "-",
  },
  {
    index: 2,
    indicatie: "DVT bij maligniteit",
    title: "Enoxaparine",
    merknaam: "Clexane®",
    profylaxe: "1dd 20 mg. Bij hoog risico profylaxe: 1dd 40 mg",
    therapeurisch: "",
    DoseEnoxaparine: "-",
  },
];

export const DoseNadroparine = () => (
  <>
    <Title style={{ color: "white", marginTop: "7.5px" }}>
      1dd 171 IE/kg s.c.
    </Title>
    <Descriptions bordered size="small">
      <Descriptions.Item span={4} label="<50 kg">
        1dd 7.600 IE
      </Descriptions.Item>
      <Descriptions.Item span={4} label="50-70 kg">
        1dd 11.400 IE
      </Descriptions.Item>
      <Descriptions.Item span={4} label="70-90 kg">
        1dd 15.200 IE
      </Descriptions.Item>
      <Descriptions.Item span={4} label=">90 kg">
        1dd 19.000 IE
      </Descriptions.Item>
    </Descriptions>
    <Divider />
    <Title style={{ color: "white", marginTop: "7.5px" }}>
      2dd 86 IE/kg s.c.
    </Title>
    <Descriptions bordered size="small">
      <Descriptions.Item span={4} label="<50 kg">
        2dd 3.800 IE
      </Descriptions.Item>
      <Descriptions.Item span={4} label="50-70 kg">
        2dd 5.700 IE
      </Descriptions.Item>
      <Descriptions.Item span={4} label="70-90 kg">
        2dd 7.600 IE
      </Descriptions.Item>
      <Descriptions.Item span={4} label=">90 kg">
        2dd 9.500 IE
      </Descriptions.Item>
    </Descriptions>
  </>
);

export const DoseDalteparine = () => (
  <>
    <Title style={{ color: "white", marginTop: "7.5px" }}>
      1dd 200 IE/kg s.c.
    </Title>
    <Descriptions bordered border size="small">
      <Descriptions.Item span={4} label="<55 kg">
        1dd 10.000 IE
      </Descriptions.Item>
      <Descriptions.Item span={4} label="55-65 kg">
        1dd 12.500 IE
      </Descriptions.Item>
      <Descriptions.Item span={4} label="66-85 kg">
        1dd 15.000 IE
      </Descriptions.Item>
      <Descriptions.Item span={4} label=">85 kg">
        1dd 18.000 IE
      </Descriptions.Item>
    </Descriptions>
    <Divider />
    <Title style={{ color: "white", marginTop: "7.5px" }}>
      2dd 100 IE/kg s.c.{" "}
    </Title>
    <Descriptions bordered border size="small">
      <Descriptions.Item span={4} label="<60 kg">
        2dd 5.000 IE
      </Descriptions.Item>
      <Descriptions.Item span={4} label="60-85 kg">
        2dd 7.500 IE
      </Descriptions.Item>
      <Descriptions.Item span={4} label=">85 kg">
        2dd 10.000 IE
      </Descriptions.Item>
    </Descriptions>
  </>
);

export const DoseEnoxaparine = () => (
  <>
    <Title style={{ color: "white", marginTop: "7.5px" }}>
      1dd 1,5 mg/kg s.c.
    </Title>
    <Divider />
    <Title style={{ color: "white", marginTop: "7.5px" }}>
      2dd 1 mg/kg s.c.{" "}
    </Title>
  </>
);

export const DoseTinzaparine = () => (
  <>
    <Title style={{ color: "white", marginTop: "7.5px" }}>
      1dd 175 IE/kg s.c.
    </Title>
    <Descriptions bordered border size="small">
      <Descriptions.Item span={4} label="<60 kg">
        1dd 10.000 IE
      </Descriptions.Item>
      <Descriptions.Item span={4} label="<60-80 kg">
        1dd 14.000 IE
      </Descriptions.Item>
      <Descriptions.Item span={4} label=">80 kg">
        1dd 18.000 IE
      </Descriptions.Item>
    </Descriptions>
  </>
);

class Stepper extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      current: 0,
    };
  }

  onChange = (value) => {
    const current = value;
    this.setState({ current });
  };

  render() {
    const { current } = this.state;

    return (
      <div className="wrapper-interactive">
        <Row>
          <Col xs={24}>
            <Title level={4}>
              Overzicht met profylactische en therapeutische doseringen:
            </Title>
            <Select
              size="large"
              style={{ width: "100%", marginTop: "8px" }}
              onChange={this.onChange}
              showSearch
              autoFocus
              defaultValue="Nadroparine"
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
              placeholder="Maak uw keuze"
            >
              {steps.map((item) => (
                <Option value={item.index} key={item.title} title={item.title}>
                  {item.title}
                </Option>
              ))}
            </Select>
          </Col>
          <Col xs={24}>
            <Divider />
            <Title level={4}>Details:</Title>
            <Descriptions
              column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
              bordered
              border
              size="small"
              style={{ background: "white", marginTop: "16px" }}
            >
              <Descriptions.Item label="Merknaam">
                {steps[current].merknaam}
              </Descriptions.Item>
              <Descriptions.Item label="Profylaxe">
                {steps[current].profylaxe}
              </Descriptions.Item>
              <Descriptions.Item label="Therapeutisch">
                {steps[current].therapeurisch}
                {steps[current].DoseNadroparine ? <DoseNadroparine /> : null}
                {steps[current].DoseDalteparine ? <DoseDalteparine /> : null}
                {steps[current].DoseEnoxaparine ? <DoseEnoxaparine /> : null}
                {steps[current].DoseTinzaparine ? <DoseTinzaparine /> : null}
              </Descriptions.Item>
            </Descriptions>
            {steps[current].alert ? (
              <ContentAlert
                message={steps[current].alert}
                type="warning"
                showIcon
                style={{ margin: "8px 0" }}
              />
            ) : null}
          </Col>
        </Row>
      </div>
    );
  }
}

export default Stepper;
