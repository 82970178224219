import React from "react";

import { Row, Col, Select, Descriptions, Typography } from "antd";

import ContentAlert from "../ui/ContentAlert";

const { Title } = Typography;
const Option = Select.Option;

const steps = [
  {
    index: 0,
    title: "Anesthesiologie",
    riskLow: "-",
    riskIntermediar: "-",
    riskHigh:
      "Epiduraal anesthesie (zie richtlijn neuraxis blokkade), Spinaal anesthesie (zie richtlijn neuraxis blokkade)",
  },
  {
    index: 1,
    title: "Cardiologie",
    riskLow: "Hartkatheterisatie",
    riskIntermediar:
      "Pacemaker/ICD implantatie bij hoog trombose risico (streef INR i.o.m. cardioloog)",
    riskHigh: "-",
  },
  {
    index: 2,
    title: "Gynaecologie",
    riskLow:
      "Diagnostische hysteroscopie, Laser vulva, IVF punctie, Onderzoek onder narcose ",
    riskIntermediar:
      "Laparotomie, Reconstructies, Bekkenbodemchirurgie, Voor/achterwand plastiek",
    riskHigh:
      "Sectio caesarea, Abortuscurettage, Partus, Debulking, Myoom enucleati, Radicale uterusextirpati, Exenteratie, Stadiering ovarium/endometriumcarcinoom",
  },
  {
    index: 3,
    title: "Heelkunde",
    riskLow: "-",
    riskIntermediar:
      "Open cholecystectomie, Adrenalectomie, Mamma amputatie, Onco/trauma amputatie, Laparoscopische chirurgie, Hemorroïden",
    riskHigh:
      "Vaatchirurgie, Niertransplantatie, Hoofd/halschirurgie, Open resecties van oesofagus/maag/darm/ lever/pancreas/milt",
  },
  {
    index: 4,
    title: "Huisartsen",
    riskLow:
      "Alle huisartsgeneeskundige ingrepen waarbij lokale hemostase mogelijk is. Injecties intramusculair, mits ingespoten hoeveelheid < 1ml",
    riskIntermediar: "-",
    riskHigh:
      "Intra-articulaire injecties; het wordt huisartsen afgeraden om intra-articulaire injecties uit te voeren onder antistolling.",
  },
  {
    index: 5,
    title: "KNO",
    riskLow:
      "Paracentese/buisjes, Panendoscopie zonder biopten, Microlaryngoscopie, Beengeleidersimplantaat",
    riskIntermediar:
      "Septumcorrectie, Panendoscopie+ biopten, Externe rhinoplastiek",
    riskHigh:
      "(Adeno) tonsillectomie, Neusbijholte operaties, Hoofd/hals weke delen chirurgie, Sanerende en reconstructieve ooroperaties, Stapesoperatie, Translabyrinthaire brughoek operatie",
  },
  {
    index: 6,
    title: "MKA-chirurgie",
    riskLow:
      "Extractie gebitselement(en), Parodontale behandeling, Plaatsen implantaat, Biopteren, Chirurgische verwijdering gebitselement(en), Apexresectie, sinusbodemelevatie, (Peri)implantaire chirurgie, Abces incisie, Geleidingsanesthesie, Cave: risico verhogende factoren*",
    riskIntermediar:
      "Mond(bodem) chirurgie, Osteotomie onderkaak (BSSO), Osteotomie bovenkaak (Le Fort I osteotomie), Bimaxillaire osteotomie, Corticotomie, Fracturen aangezicht, exclusief orbita, Bottransplantaties met extra oraal bot, Open kaakgewrichtschirurgie Kies- en tandextracties en overige chirurgische mondzorg ingrepen bij een of meer risico verhogende factoren *)",
    riskHigh: "Orbitafracturen, kaakreconstructie",
    alert:
      "* Risico verhogende factoren bij tandheelkundige ingrepen: een te verwachten gecompliceerd uit te voeren behandeling, een groot wondoppervlak, geen mogelijkheid om de wond primair te kunnen sluiten, een geïnfecteerd wondgebied, geen mogelijkheid tot wondcompressie.",
  },
  {
    index: 7,
    title: "Tandheelkunde",
    riskLow:
      " Extractie gebitselement(en), Parodontale behandeling, Plaatsen implantaat, Biopteren, Chirurgische verwijdering gebitselement(en), Apexresectie, Sinusbodemelevatie, (Peri)implantaire chirurgie, Abces incisie, Geleidingsanesthesie, Cave: risicoverhogende factoren*",
    riskIntermediar:
      "Kies – en tandextracties bij een of meer risico verhogende factoren **",
    riskHigh: "-",
    alertThird:
      "Voor de meeste tandheelkundige ingrepen, uitgevoerd door de tandarts of door de mondhygiënist is het niet noodzakelijk de behandeling met VKA uit routine te staken, mits er aan bepaalde voorwaarden wordt voldaan. Eén van de voorwaarden is dat de INR niet hoger is dan 3,5 tijdens de behandeling.",
    alertSecond:
      "** Risico verhogende factoren bij tandheelkundige ingrepen: een te verwachten gecompliceerd uit te voeren behandeling, een groot wondoppervlak, geen mogelijkheid om de wond primair te kunnen sluiten, een geïnfecteerd wondgebied, geen mogelijkheid tot wondcompressie.",
    alertFour:
      "Meer informatie over de voorwaarden voor het continueren van VKA en DOACs bij een aantal tandheelkundige ingrepen is te vinden onder ‘Extra opmerkingen tandheelkundige ingrepen’",
  },
  {
    index: 8,
    title: "Longziekten",
    riskLow: "Pleurapunctie (dunne naald)",
    riskIntermediar: "Bronchoscopie zonder biopten",
    riskHigh:
      "Bronchoscopie met biopten, Therapeutische pleuradrainage, Starre bronchoscopie, Immunologische BAL (i.v.m. interpretatie), Therapeutische bronchoscopie (laser, stentplaatsing), Lymfeklierpunctie TBNA/EBUS Cryobiopten",
  },
  {
    index: 9,
    title: "MDL/ Interne geneeskunde",
    riskLow:
      "Diagnostische endoscopie (gastro-, sigmoïdo-, colonoscopie) inclusief biopten, ERCP met endoprotheseplaatsing zonder papillotomie, Enteroscopie, inclusief biopten (maar zonder interventie), Video capsule endoscopie, Stentplaatsing (zonder dilatatie), Inbrengen voedingssonde, Endo-echografie zonder interventie, RFA Barrett",
    riskIntermediar: "-",
    riskHigh:
      "Lever- en nierbiopsie Poliepectomie, Mucosaresectie, Endoscopische submucosale dissectie, Perorale endoscopische myotomie, Submucosal tunneling endoscopic resection, ERCP met papillotomie (biliair of pancreas), PEG-plaatsing, Endo-echografie met interventie. Endoscopische coagulatie, Therapeutische dubbelballon enteroscopie, Behandeling van varices en hemorroïden, Ampullectomie, Dilatatie oesophagus",
  },
  {
    index: 10,
    title: "Neurochirurgie",
    riskLow: "-",
    riskIntermediar: "Carpale tunnel correctie",
    riskHigh: "Intracraniële chirurgie, Open wervelchirurgie",
  },
  {
    index: 11,
    title: "Neurologie",
    riskLow: "-",
    riskIntermediar: "-",
    riskHigh: "Lumbaalpunctie",
  },
  {
    index: 12,
    title: "Oogheelkunde",
    riskLow: "Cataract operatie met infiltratie anesthesie (peribulbair block)",
    riskIntermediar: "Netvlieschirurgie",
    riskHigh:
      "Cataractoperatie met retrobulbair anesthesie, Orbita operaties, Trabeculectomie, Conjunctiva operaties, Endocsopische dacryocysthorhinostomie",
  },
  {
    index: 13,
    title: "Orthopedie",
    riskLow: "Intra articulaire injectie (niet door huisartsen)",
    riskIntermediar: "Schouder-, voet- en handchirurgie, Arthroscopie",
    riskHigh:
      "Open wervelchirurgie, Knie- en heupchirurgie/THP, Arthroplastiek",
  },
  {
    index: 14,
    title: "Plastische chirurgie",
    riskLow: "-",
    riskIntermediar: "Huidkankerexcisie",
    riskHigh: "Alle grote reconstructies, Vaatmalformaties",
  },
  {
    index: 15,
    title: "Traumatologie",
    riskLow: "-",
    riskIntermediar: "-",
    riskHigh: "Bekkenchirurgie, Heup/femur chirurgie",
  },
  {
    index: 16,
    title: "Thoraxchirurgie",
    riskLow: "-",
    riskIntermediar: "Thoracoscopie ",
    riskHigh: "Longoperatie, Mediastinoscopie, Hartoperatie",
  },
  {
    index: 17,
    title: "Urologie",
    riskLow: "Diagnostische Transurethrale endoscopie (excl resectie/ bioptie)",
    riskIntermediar:
      "Laparoscopische chirurgie (excl. partiele nefrectomie), Urethraplastiek, Transurethrale blaas- en ureterchirurgie, Transurethrale prostaat laserchirurgie.",
    riskHigh:
      "Transurethrale prostaat electrochirurgie, Partiele nefrectomie, Open transabdominale chirurgie, Percutane niersteenchirurgie, Scrotale chirurgie (excl. vasectomie), Abd chirurgie",
  },
  {
    index: 18,
    title: "Radiologie",
    riskLow:
      "Intraveneuze procedures, Cytologische puncties (inclusief ascites en pleurapunctie), Histologische punctie (waarbij afdrukken goed mogelijk is), Centraal veneuze lijn (ongetunnelde), Echografische / röntgengeleide gewrichtspunctie en injectie van medicatie in het gewricht",
    riskIntermediar:
      "Intra-arteriële procedures, Drainage vochtcollecties en opheffen afvloedbelemmering (o.a. Nefrostomie, abcesdrainage, ascitesdrainage, inclusief wissels), JJ-katheter, Getunnelde veneuze lijnen (PAC, Hickmann en Permacath), PEG-wissel, Barbotage, EVAR abdominaal / thoracaal",
    riskHigh:
      "Echografische / CT-geleide diepe, orgaanbiopsie (afdrukken punctieplaats niet mogelijk), nierbiopsie, leverbiopsie, botbiopsie, sommige wekedelen tumorbiopsie, RFA diep orgaan, Galwegdrainage en wissel, PEG plaatsing, Cementeren (heupprothese en wervel), TIPS, Eilandjestransplantatie via vena portae, Porta-embolisatie, ABC-embolisatie, Diagnostisch lumbaalpunctie",
  },
  {
    index: 19,
    title: "Dermatologie",
    riskLow: "-",
    riskIntermediar:
      "Mohs chirurgie, Huidtransplantatie, Lokale huidplastieken, Nagelchirurgie",
    riskHigh: "-",
  },
  {
    index: 20,
    title: "Biopsieën en puncties",
    riskLow:
      "Beenmergaspiratie, Ascitespunctie(dunne naald), Pleurapunctie (dunne naald), Plaatsen en verwijderen centraal veneuze katheter",
    riskIntermediar:
      "Beenmergbiopsie, Ascitesdrainage, Pleuradrainage, Diagnostisch lumbaalpunctie",
    riskHigh: "-",
  },
];

class SelectBloedRisicoKatnh extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      current: 0,
    };
  }

  onChange = (value) => {
    const current = value;
    this.setState({ current });
  };

  render() {
    const { current } = this.state;

    return (
      <div className="modal-katnh">
        <Row>
          <Col xs={24}>
            <div style={{ background: "#1d396e", padding: "10px" }}>
              <Title
                level={4}
                className="text-green"
                style={{ marginTop: "10px" }}
              >
                Categorie:
              </Title>
              <Select
                size="large"
                style={{ width: "100%", marginTop: "10px" }}
                onChange={this.onChange}
                showSearch
                defaultValue="Anesthesiologie"
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                placeholder="Selecteer een categorie.."
              >
                {steps.map((item) => (
                  <Option
                    value={item.index}
                    key={item.title}
                    title={item.title}
                  >
                    {item.title}
                  </Option>
                ))}
              </Select>
              <Title
                level={4}
                className="text-green"
                style={{ marginTop: "20px" }}
              >
                Risico:
              </Title>
              <Descriptions
                column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
                style={{ marginTop: "20px" }}
              >
                <Descriptions.Item label="Laag">
                  {steps[current].riskLow}
                </Descriptions.Item>
                <Descriptions.Item label="Intermediair">
                  {steps[current].riskIntermediar}
                </Descriptions.Item>
                <Descriptions.Item label="Hoog">
                  {steps[current].riskHigh}
                </Descriptions.Item>
              </Descriptions>
              {steps[current].alert ? (
                <ContentAlert
                  message={steps[current].alert}
                  type="info"
                  showIcon
                  style={{ margin: "8px 0" }}
                />
              ) : null}
              {steps[current].alertSecond ? (
                <ContentAlert
                  message={steps[current].alertSecond}
                  type="info"
                  showIcon
                  style={{ margin: "8px 0" }}
                />
              ) : null}
              <ContentAlert
                message="Deze tabel is niet volledig en risico’s kunnen op basis van de casuïstiek anders ingeschat worden en kunnen per regio verschillen."
                type="warning"
                showIcon
              />
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

export default SelectBloedRisicoKatnh;
