import React from "react";
import { Typography, Popover, Tag } from "antd";
import { StaticImage } from "gatsby-plugin-image";
import { ExportOutlined } from "@ant-design/icons";
const { Text } = Typography;

const ContentLinkExternalRldb = ({ title, url }) => {
  return (
    <>
      <Popover
        color="#009ab6"
        placement="topRight"
        content={
          <div>
            <a
              href={url}
              target="_blank"
              rel="noreferrer noopener"
              style={{
                color: "#fff",
                marginBottom: "24px",
                textDecoration: "underline",
              }}
            >
              <StaticImage
                src="../../../images/logo/logoRldb.svg"
                alt="Logo Federatie Medisch Specialisten"
                width={48}
                style={{ marginRight: "8px", top: "-2px" }}
              />
              <span style={{ fontSize: "14px" }}>
                Richtlijnendatabase {"> "}
              </span>
              {title}
            </a>
            <br />
            <Text style={{ fontSize: "11px", color: "#fff" }}>
              <ExportOutlined style={{ transform: "rotate(-90deg)" }} /> opent
              in een nieuw venster
            </Text>
          </div>
        }
        trigger="hover"
      >
        <Tag style={{ margin: "0" }}>
          <Text
            style={{
              textDecoration: "underline",
              textDecorationColor: "#777c00",
            }}
          >
            {title}
            <ExportOutlined
              style={{
                transform: "rotate(-90deg)",
                color: "#009ab6",
                marginLeft: "2px",
              }}
            />
          </Text>
        </Tag>
      </Popover>
    </>
  );
};

export default ContentLinkExternalRldb;
