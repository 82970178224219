import React from "react";
import { Drawer, Typography } from "antd";
const { Paragraph, Text } = Typography;

class ModalContentKatnh extends React.Component {
  constructor() {
    super();
    this.state = { visible: false };
  }

  showDrawer = () => {
    this.setState({
      visible: true,
    });
  };

  onClose = () => {
    this.setState({
      visible: false,
    });
  };

  render() {
    return (
      <>
        <Paragraph
          strong
          type="link"
          onClick={this.showDrawer}
          className="trigger-modal link"
        >
          {this.props.trigger} »
        </Paragraph>
        <Drawer
          title={this.props.title}
          visible={this.state.visible}
          onClose={this.onClose}
          onCancel={this.onClose}
          drawerStyle={{ backgroundColor: "white" }}
          className="modal-katnh styles-katnh"
          width="85%"
        >
          <img
            src="/client/katnh/small_dots.svg"
            alt="divider"
            style={{ height: "3px", margin: "16px 0" }}
          />
          {this.props.content}
        </Drawer>
      </>
    );
  }
}

export default ModalContentKatnh;
