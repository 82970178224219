import React, { useState } from "react";
import { Card, Divider, Select, Descriptions, Button } from "antd";
import { useDebounceFn } from "ahooks";
const Option = Select.Option;

export default function OptionsSelectComponent({
  onNext,
  steps,
  title,
  language,
}) {
  const [current, setCurrent] = useState(0);
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [isClicked, setIsClicked] = useState(false);

  const onChange = (value) => {
    setCurrent(value);
    setButtonDisabled(false);
  };

  const { run } = useDebounceFn(
    (k) => {
      onNext(k);
    },
    {
      wait: 250,
    }
  );

  const textButtonProceed = language === "nl" ? "Doorgaan" : "Next step";
  const textDivider =
    language === "nl" ? "Beschikbare opties" : "Available options";
  const textSelected = language === "nl" ? "Geselecteerd:" : "Selected:";
  const textSelectPlaceholder =
    language === "nl"
      ? "Selecteer een optie om door te gaan"
      : "Select an option to proceed";

  return (
    <>
      <Card
        size="small"
        title={title}
        type="inner"
        bodyStyle={{ padding: "15px" }}
      >
        <Select
          size="large"
          style={{ width: "100%" }}
          onChange={onChange}
          showSearch
          filterOption={(input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
            0
          }
          placeholder={textSelectPlaceholder}
        >
          {steps.map((item) => (
            <Option value={item.index} key={item.index}>
              {item.optionTitle}
            </Option>
          ))}
        </Select>
        <Descriptions bordered size="small">
          <Descriptions.Item label={textSelected} span={4}>
            {steps[current].optionTitle}
          </Descriptions.Item>
        </Descriptions>
      </Card>
      <div>
        <Divider
          orientation="left"
          plain
          style={{
            margin: "16px 0 10px 0",
            fontSize: "12px",
            borderColor: "#ced4da",
          }}
        >
          {textDivider}
        </Divider>
        <Button
          type="primary"
          disabled={buttonDisabled}
          onClick={() => {
            setIsClicked(true);
            run(steps[current].nodeId);
          }}
          loading={isClicked}
        >
          {textButtonProceed}
        </Button>
      </div>
    </>
  );
}
