import React from "react";
import { Tag } from "antd";

const NodeTypeIndicator = ({ nodeType }) => {
  if (nodeType === "key decision point") {
    return (
      <Tag style={{ textTransform: "capitalize" }} color="#6A589F">
        {nodeType}
      </Tag>
    );
  } else if (nodeType === "decision") {
    return (
      <Tag style={{ textTransform: "capitalize" }} color="#008B8A">
        {nodeType}
      </Tag>
    );
  } else if (nodeType === "action") {
    return (
      <Tag style={{ textTransform: "capitalize" }} color="#617D95">
        {nodeType}
      </Tag>
    );
  } else if (nodeType === "end point") {
    return (
      <Tag style={{ textTransform: "capitalize" }} color="#8FB132">
        {nodeType}
      </Tag>
    );
  } else if (nodeType === "acceptable end point") {
    return (
      <Tag style={{ textTransform: "capitalize" }} color="#8FB132">
        {nodeType}
      </Tag>
    );
  } else if (nodeType === "recommended end point") {
    return (
      <Tag style={{ textTransform: "capitalize" }} color="#8FB132">
        {nodeType}
      </Tag>
    );
  } else {
    return (
      <Tag style={{ textTransform: "capitalize" }}>
        {nodeType}
      </Tag>
    );
  }
};

export default NodeTypeIndicator;
