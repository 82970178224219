import React, { useState } from "react";
import ReactFromJSON from "react-from-json";
import Node from "./ChartNivSimpleNodes";
import {
  Button,
  Carousel,
  Descriptions,
  Divider,
  Result,
  Row,
  Typography,
  Alert,
} from "antd";
import { Link } from "gatsby";
import * as Scroll from "react-scroll";
import { Xwrapper } from "react-xarrows";
import OptionsSelectComponent from "../OptionsSelectComponent";
import OptionsItemsPointsComponent from "../OptionsItemsPointsComponent";
import OptionsItemsPointsComponentWithSelect from "../OptionsItemsPointsComponentWithSelect";
import ContentList from "../../../../components/ui/ContentList";
import ContentLinkExternalFk from "../../../../components/chart/ui/ContentLinkExternalFk.js";
import ContentLinkExternalKf from "../../../../components/chart/ui/ContentLinkExternalKf.js";
import ContentLinkExternalRldb from "../../../../components/chart/ui/ContentLinkExternalRldb";
import ContentLinkExternalAcuteBoekje from "../../../../components/chart/ui/ContentLinkExternalAcuteBoekje";
import ArrowsActiveWithoutLabel from "../../../../components/chart/links/arrow/ArrowsActiveWithoutLabel";

// import "../../styles/chart-deck.scss";
const scroller = Scroll.scroller;
const { Paragraph, Text, Title } = Typography;

export default function ChartNivSimple({ entry, firstNodeId }) {
  const [nodePath, setNodePath] = useState([firstNodeId]);
  const [isLastOnPath, setIsLastOnPath] = useState(firstNodeId);

  const onNext = (nodeId) => {
    const nodeCurrentPosition = nodePath.indexOf(nodeId);
    if (nodeCurrentPosition === -1) {
      const addNextNode = nodePath.concat([nodeId]);
      setNodePath(addNextNode);
      const nextNodeId = nodeId.toString();
      setIsLastOnPath(nextNodeId);
      const windowHeight = window.innerHeight;
      const scrollOffset = 0.33 * windowHeight;
      setTimeout(function () {
        if (!("parentIFrame" in window)) {
          scroller.scrollTo(nextNodeId, {
            duration: 300,
            delay: 25,
            smooth: true,
            offset: -scrollOffset,
            isDynamic: true,
          });
        }
      }, 50);
      setTimeout(function () {
        if ("parentIFrame" in window) {
          window.parentIFrame.scrollToOffset(0, 10);
        }
      }, 50);
    } else {
      onGoTo(nodeId);
    }
  };

  const onGoTo = (nodeId) => {
    const nodeCurrentPosition = nodePath.indexOf(nodeId);
    if (nodeCurrentPosition !== -1) {
      const goToNode = nodePath.slice(0, nodeCurrentPosition + 1);
      setNodePath(goToNode);
      const goToNodeId = nodeId.toString();
      setIsLastOnPath(goToNodeId);
      const windowHeight = window.innerHeight;
      const scrollOffset = 0.33 * windowHeight;
      setTimeout(function () {
        if (!("parentIFrame" in window)) {
          scroller.scrollTo(goToNodeId, {
            duration: 300,
            delay: 25,
            smooth: true,
            offset: -scrollOffset,
            isDynamic: true,
          });
        }
      }, 50);
      setTimeout(function () {
        const contentHeight = document.getElementById(nodeId).offsetTop;
        const contentHeightNew = contentHeight - 125;
        if ("parentIFrame" in window) {
          window.parentIFrame.scrollToOffset(0, contentHeightNew);
        }
      }, 50);
    }
  };

  const onPrevious = (nodeId) => {
    const nodeCurrentPosition = nodePath.indexOf(nodeId);
    const previousNode = nodePath.slice(0, nodeCurrentPosition);
    if (previousNode.length > 0) {
      setNodePath(previousNode);
      const previousNodeId = nodePath[nodePath.length - 2].toString();
      setIsLastOnPath(previousNodeId);
      const windowHeight = window.innerHeight;
      const scrollOffset = 0.25 * windowHeight;
      setTimeout(function () {
        if (!("parentIFrame" in window)) {
          scroller.scrollTo(previousNodeId, {
            duration: 300,
            delay: 25,
            smooth: true,
            offset: -scrollOffset,
            isDynamic: true,
          });
        }
      }, 50);
      setTimeout(function () {
        const contentHeight = document.getElementById(nodeId).offsetTop;
        const contentHeightNew = contentHeight - 125;
        if ("parentIFrame" in window) {
          window.parentIFrame.scrollToOffset(0, contentHeightNew);
        }
      }, 50);
    }
  };

  const onReset = () => {
    const resetNode = firstNodeId;
    setNodePath([resetNode]);
    const resetNodeId = firstNodeId.toString();
    setIsLastOnPath(resetNodeId);
    const windowHeight = window.innerHeight;
    const scrollOffset = 0.25 * windowHeight;
    setTimeout(function () {
      if (!("parentIFrame" in window)) {
        scroller.scrollTo(resetNodeId, {
          duration: 300,
          delay: 25,
          smooth: true,
          offset: -scrollOffset,
          isDynamic: true,
        });
      }
    }, 50);
    setTimeout(function () {
      if ("parentIFrame" in window) {
        window.parentIFrame.scrollToOffset(0, -90);
      }
    }, 50);
  };

  const mapping = {
    chart: ({ nodes }) => <>{nodes}</>,
    node: ({
      nodeId,
      nodeTitle,
      links,
      content,
      isEndNode,
      disableDefaultOptionsButtons,
      extraContent,
      extraContentTitle,
      options,
    }) => (
      <>
        <Node
          disableDefaultOptionsButtons={disableDefaultOptionsButtons}
          extraContent={extraContent}
          extraContentTitle={extraContentTitle}
          isEndNode={isEndNode}
          isFirstOnPath={firstNodeId === nodeId}
          isLastOnPath={isLastOnPath === nodeId}
          isOnPath={nodePath.indexOf(nodeId) !== -1}
          key={nodeId}
          links={links}
          nodeId={nodeId}
          nodeTitle={nodeTitle}
          onGoTo={onGoTo}
          onNext={onNext}
          onPrevious={onPrevious}
          onReset={onReset}
          options={options}
          linksActive={
            nodePath[nodePath.length - 1] !== nodeId
              ? nodePath[nodePath.indexOf(nodeId) + 1]
              : null
          }
        >
          {content}
        </Node>
        <ArrowsActiveWithoutLabel
          arrowColorActive="#0D6739"
          arrowColorInactive="#dee2e6"
          isLastOnPath={isLastOnPath}
          links={links}
          linksActive={
            nodePath[nodePath.length - 1] !== nodeId
              ? nodePath[nodePath.indexOf(nodeId) + 1]
              : null
          }
          nodeId={nodeId}
          gridBreak="70%"
        />
      </>
    ),
    row: ({ children }) => <>{children}</>,
    col: ({ children }) => <>{children}</>,
    button: ({ text, type, ghost, to, disabled }) => (
      <Paragraph>
        <Button type={type} ghost={ghost} disabled={disabled}>
          <Link to={to}>{text}</Link>
        </Button>
      </Paragraph>
    ),
    diagram: ({}) => <></>,
    alert: ({ type, message }) => (
      <Alert
        message={message}
        showIcon
        type={type}
        style={{ margin: "15px 0" }}
      />
    ),
    carousel: ({ slides }) => (
      <Carousel
        autoplay
        style={{
          height: "400px",
          backgroundColor: "#364d79",
          textAlign: "center",
        }}
        effect="fade"
      >
        {slides}
      </Carousel>
    ),
    divider: () => <Divider dashed style={{ margin: "12px 0" }} />,
    description: ({ items }) => (
      <Descriptions
        bordered
        size="small"
        column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
      >
        {items}
      </Descriptions>
    ),
    descriptionItem: ({ label, text }) => (
      <Descriptions.Item label={label} span={3}>
        {text}
      </Descriptions.Item>
    ),
    list: ({ items, size, header }) => (
      <ContentList
        size={size}
        header={header}
        bordered
        items={items}
        style={{ marginTop: "16px", marginBottom: "8px" }}
      />
    ),
    paragraph: ({ children, text }) => (
      <Paragraph>
        {text} {children}
      </Paragraph>
    ),
    result: ({ title, status }) => <Result title={title} status={status} />,
    title: ({ title, level }) => <Title level={level}>{title}</Title>,
    text: ({
      code,
      copyable,
      disabled,
      ellipsis,
      keyboard,
      mark,
      strong,
      text,
      type,
      underline,
    }) => (
      <Text
        code={code}
        copyable={copyable}
        disabled={disabled}
        ellipsis={ellipsis}
        keyboard={keyboard}
        mark={mark}
        strong={strong}
        type={type}
        underline={underline}
      >
        {text}
      </Text>
    ),
    image: ({ src, alt }) => (
      <div
        style={{
          textAlign: "center",
        }}
      >
        <img
          src={src}
          alt={alt}
          style={{ height: "400px", margin: "0 auto" }}
        />
      </div>
    ),
    ContentLinkExternalFk: ({ title, url }) => (
      <ContentLinkExternalFk title={title} url={url} />
    ),
    ContentLinkExternalKf: ({ title, url }) => (
      <ContentLinkExternalKf title={title} url={url} />
    ),
    ContentLinkExternalAcuteBoekje: ({ title, url }) => (
      <ContentLinkExternalAcuteBoekje title={title} url={url} />
    ),
    ContentLinkExternalRldb: ({ title, url }) => (
      <ContentLinkExternalRldb title={title} url={url} />
    ),
    OptionsSelectComponent: ({ steps, title }) => (
      <OptionsSelectComponent steps={steps} title={title} onNext={onNext} />
    ),
    OptionsItemsPointsComponent: ({
      steps,
      cutoffValue,
      nodeLinkOne,
      nodeLinkOneLabel,
      nodeLinkTwo,
      nodeLinkTwoLabel,
      title,
    }) => (
      <OptionsItemsPointsComponent
        steps={steps}
        cutoffValue={cutoffValue}
        nodeLinkOne={nodeLinkOne}
        nodeLinkOneLabel={nodeLinkOneLabel}
        nodeLinkTwo={nodeLinkTwo}
        nodeLinkTwoLabel={nodeLinkTwoLabel}
        onNext={onNext}
        title={title}
      />
    ),
    OptionsItemsPointsComponentWithSelect: ({
      steps,
      selectOptions,
      cutoffValue,
      nodeLinkOne,
      nodeLinkOneLabel,
      nodeLinkTwo,
      nodeLinkTwoLabel,
      title,
      title2,
    }) => (
      <OptionsItemsPointsComponentWithSelect
        steps={steps}
        selectOptions={selectOptions}
        cutoffValue={cutoffValue}
        nodeLinkOne={nodeLinkOne}
        nodeLinkOneLabel={nodeLinkOneLabel}
        nodeLinkTwo={nodeLinkTwo}
        nodeLinkTwoLabel={nodeLinkTwoLabel}
        onNext={onNext}
        title={title}
        title2={title2}
      />
    ),
  };

  return (
    <Xwrapper>
      <Row type="flex" className="chart-basic-deck">
        <ReactFromJSON entry={entry} mapping={mapping} />
        <div id="bottom"></div>
      </Row>
    </Xwrapper>
  );
}
