import React, { useState } from "react";
import ReactFromJSON from "react-from-json";
import Node from "./Nodes";
import {
  Alert,
  Button,
  Carousel,
  Descriptions,
  Result,
  Row,
  Divider,
  Typography,
} from "antd";
import { Link } from "gatsby";
import * as Scroll from "react-scroll";
import Arrows from "../../chart/links/arrow/Arrows";
import ContentList from "../../ui/ContentList";
import SelectBloedRisico from "../../../client/lta-antistolling/SelectBloedRisico";
import TableTromboRisico from "../../../client/lta-antistolling/TableTromboRisico";
import ABCDEApproach from "../../../client/examples/widgets/abcde-approach";
import IVaccess from "../../../client/examples/widgets/iv-access";
import ContentListAction from "../../ui/ContentListAction";
import OptionsSelectComponent from "../../chart/links/options/OptionsSelectComponent";
import OptionsItemsPointsComponent from "../../chart/links/options/OptionsItemsPointsComponent";
import ContentLinkExternalFk from "../../ui/ContentLinkExternalFk.js";
import ContentLinkExternalKf from "../../ui/ContentLinkExternalKf.js";
import ContentLinkExternalAcuteBoekje from "../../ui/ContentLinkExternalAcuteBoekje";
import ContentLinkExternalRldb from "../../ui/ContentLinkExternalRldb";
//
import ModalContent from "../../ui/modalContent";
import Overview from "../client/lta-antistolling/content/Overview";
//
import { ExportOutlined } from "@ant-design/icons";

import "./styles.scss";

const scroller = Scroll.scroller;
const { Paragraph, Text, Title } = Typography;

export default function ChartSchematic({
  entry,
  firstNodeId,
  startDemo,
  useStartDemoProp,
}) {
  const [nodePath, setNodePath] = useState([firstNodeId]);
  const [isLastOnPath, setIsLastOnPath] = useState(firstNodeId);

  const onNext = (nodeId) => {
    const nodeCurrentPosition = nodePath.indexOf(nodeId);
    if (nodeCurrentPosition === -1) {
      const addNextNode = nodePath.concat([nodeId]);
      setNodePath(addNextNode);
      const nextNodeId = nodeId.toString();
      setIsLastOnPath(nextNodeId);
      const windowHeight = window.innerHeight;
      const scrollOffset = 0.33 * windowHeight;
      setTimeout(function () {
        if (!("parentIFrame" in window)) {
          scroller.scrollTo(nextNodeId, {
            duration: 300,
            delay: 25,
            smooth: true,
            offset: -scrollOffset,
            isDynamic: true,
          });
        }
      }, 50);
      setTimeout(function () {
        if ("parentIFrame" in window) {
          window.parentIFrame.scrollToOffset(0, 10);
        }
      }, 50);
    } else {
      onGoTo(nodeId);
    }
  };

  const onGoTo = (nodeId) => {
    const nodeCurrentPosition = nodePath.indexOf(nodeId);
    if (nodeCurrentPosition !== -1) {
      const goToNode = nodePath.slice(0, nodeCurrentPosition + 1);
      setNodePath(goToNode);
      const goToNodeId = nodeId.toString();
      setIsLastOnPath(goToNodeId);
      const windowHeight = window.innerHeight;
      const scrollOffset = 0.33 * windowHeight;
      setTimeout(function () {
        if (!("parentIFrame" in window)) {
          scroller.scrollTo(goToNodeId, {
            duration: 300,
            delay: 25,
            smooth: true,
            offset: -scrollOffset,
            isDynamic: true,
          });
        }
      }, 50);
      setTimeout(function () {
        const contentHeight = document.getElementById(nodeId).offsetTop;
        const contentHeightNew = contentHeight - 125;
        if ("parentIFrame" in window) {
          window.parentIFrame.scrollToOffset(0, contentHeightNew);
        }
      }, 50);
    }
  };

  const onPrevious = (nodeId) => {
    const nodeCurrentPosition = nodePath.indexOf(nodeId);
    const previousNode = nodePath.slice(0, nodeCurrentPosition);
    if (previousNode.length > 0) {
      setNodePath(previousNode);
      const previousNodeId = nodePath[nodePath.length - 2].toString();
      setIsLastOnPath(previousNodeId);
      const windowHeight = window.innerHeight;
      const scrollOffset = 0.25 * windowHeight;
      setTimeout(function () {
        if (!("parentIFrame" in window)) {
          scroller.scrollTo(previousNodeId, {
            duration: 300,
            delay: 25,
            smooth: true,
            offset: -scrollOffset,
            isDynamic: true,
          });
        }
      }, 50);
      setTimeout(function () {
        const contentHeight = document.getElementById(nodeId).offsetTop;
        const contentHeightNew = contentHeight - 125;
        if ("parentIFrame" in window) {
          window.parentIFrame.scrollToOffset(0, contentHeightNew);
        }
      }, 50);
    }
  };

  const onReset = () => {
    const resetNode = firstNodeId;
    setNodePath([resetNode]);
    const resetNodeId = firstNodeId.toString();
    setIsLastOnPath(resetNodeId);
    const windowHeight = window.innerHeight;
    const scrollOffset = 0.25 * windowHeight;
    setTimeout(function () {
      if (!("parentIFrame" in window)) {
        scroller.scrollTo(resetNodeId, {
          duration: 300,
          delay: 25,
          smooth: true,
          offset: -scrollOffset,
          isDynamic: true,
        });
      }
    }, 50);
    setTimeout(function () {
      if ("parentIFrame" in window) {
        window.parentIFrame.scrollToOffset(0, -90);
      }
    }, 50);
  };

  const mapping = {
    chart: ({ nodes }) => <>{nodes}</>,
    node: ({
      alignSelf,
      content,
      disableDefaultOptionsButtons,
      extraContent,
      extraContentTitle,
      isEndNode,
      links,
      nodeId,
      nodeTitle,
      nodeType,
      offset,
      options,
      span,
    }) => (
      <>
        <Node
          alignSelf={alignSelf}
          disableDefaultOptionsButtons={disableDefaultOptionsButtons}
          enableScrolling
          extraContent={extraContent}
          extraContentTitle={extraContentTitle}
          isEndNode={isEndNode}
          isFirstOnPath={firstNodeId === nodeId}
          isLastOnPath={isLastOnPath === nodeId}
          isOnPath={nodePath.indexOf(nodeId) !== -1}
          key={nodeId}
          links={links}
          linksActive={
            nodePath[nodePath.length - 1] !== nodeId
              ? nodePath[nodePath.indexOf(nodeId) + 1]
              : null
          }
          nodeId={nodeId}
          nodeTitle={nodeTitle}
          nodeType={nodeType}
          offset={offset}
          onGoTo={onGoTo}
          onNext={onNext}
          onPrevious={onPrevious}
          onReset={onReset}
          options={options}
          span={span}
          startDemo={startDemo}
          useStartDemoProp={useStartDemoProp}
        >
          {content}
        </Node>
        <Arrows
          arrowColorActive="#40c057"
          arrowColorInactive="#adb5bd"
          links={links}
          linksActive={
            nodePath[nodePath.length - 1] !== nodeId
              ? nodePath[nodePath.indexOf(nodeId) + 1]
              : null
          }
          isLastOnPath={isLastOnPath}
          nodeId={nodeId}
          gridBreak="65%"
        />
      </>
    ),
    diagram: () => <></>,
    divider: ({ text }) => (
      <Divider
        orientation="left"
        plain
        style={{
          margin: "16px 0 16px 0",
          fontSize: "12px",
          borderColor: "#ced4da",
        }}
      >
        {text}
      </Divider>
    ),
    alert: ({ type, message }) => (
      <Paragraph>
        <Alert message={message} type={type} showIcon style={{ margin: "0" }} />
      </Paragraph>
    ),
    button: ({ text, type, ghost, to, disabled }) => (
      <Paragraph>
        <Button type={type} ghost={ghost} disabled={disabled}>
          <Link to={to}>{text}</Link>
        </Button>
      </Paragraph>
    ),
    link: ({ to, text }) => (
      <Paragraph>
        <a href={to} target="_blank" rel="noreferrer noopener">
          {text}
        </a>
      </Paragraph>
    ),
    linkExternal: ({ text, to }) => (
      <Paragraph>
        <a href={to} target="_blank" rel="noopener noreferrer">
          <ExportOutlined style={{ transform: "rotate(-90deg)" }} /> {text}{" "}
          <span style={{ fontSize: "9px" }}>(opent in een nieuw venster)</span>
        </a>
      </Paragraph>
    ),
    carousel: ({ slides }) => (
      <Carousel
        autoplay
        style={{
          height: "400px",
          backgroundColor: "#364d79",
          textAlign: "center",
        }}
        effect="fade"
      >
        {slides}
      </Carousel>
    ),
    description: ({ items }) => (
      <Descriptions
        bordered
        size="small"
        column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
      >
        {items}
      </Descriptions>
    ),
    descriptionItem: ({ label, text }) => (
      <Descriptions.Item label={label} span={3}>
        {text}
      </Descriptions.Item>
    ),
    list: ({ items, size, header }) => (
      <ContentList
        size={size}
        header={<Text strong>{header}</Text>}
        bordered
        items={items}
        style={{ marginTop: "16px", marginBottom: "8px" }}
      />
    ),
    paragraph: ({ children, text }) => (
      <Paragraph>
        {text} {children}
      </Paragraph>
    ),
    result: ({ title, status }) => <Result title={title} status={status} />,
    title: ({ title, level }) => <Title level={level}>{title}</Title>,
    text: ({
      text,
      type,
      code,
      copyable,
      disabled,
      ellipsis,
      mark,
      keyboard,
      underline,
      strong,
    }) => (
      <Text
        type={type}
        code={code}
        copyable={copyable}
        disabled={disabled}
        ellipsis={ellipsis}
        mark={mark}
        keyboard={keyboard}
        underline={underline}
        strong={strong}
      >
        {text}
      </Text>
    ),
    ltaBloedRisico: () => <SelectBloedRisico />,
    ltaTromboRisico: () => <TableTromboRisico />,
    abcdeApproach: () => <ABCDEApproach />,
    IVaccess: () => <IVaccess />,
    listAction: ({ header, items, item, children }) => (
      <ContentListAction
        size="small"
        header={<Text strong>{header}</Text>}
        bordered
        items={items}
        item={item}
        children={children}
      />
    ),
    OptionsSelectComponent: ({ steps, title }) => (
      <OptionsSelectComponent steps={steps} title={title} onNext={onNext} />
    ),
    OptionsItemsPointsComponent: ({
      steps,
      cutoffValue,
      nodeLinkOne,
      nodeLinkOneLabel,
      nodeLinkTwo,
      nodeLinkTwoLabel,
      title,
    }) => (
      <OptionsItemsPointsComponent
        steps={steps}
        cutoffValue={cutoffValue}
        nodeLinkOne={nodeLinkOne}
        nodeLinkOneLabel={nodeLinkOneLabel}
        nodeLinkTwo={nodeLinkTwo}
        nodeLinkTwoLabel={nodeLinkTwoLabel}
        onNext={onNext}
        title={title}
      />
    ),
    ContentLinkExternalFk: ({ title, url }) => (
      <ContentLinkExternalFk title={title} url={url} />
    ),
    ContentLinkExternalKf: ({ title, url }) => (
      <ContentLinkExternalKf title={title} url={url} />
    ),
    ContentLinkExternalAcuteBoekje: ({ title, url }) => (
      <ContentLinkExternalAcuteBoekje title={title} url={url} />
    ),
    ContentLinkExternalRldb: ({ title, url }) => (
      <ContentLinkExternalRldb title={title} url={url} />
    ),
    // LTA
    modalContent: ({ trigger, title, content }) => (
      <ModalContent trigger={trigger} title={title} content={content} />
    ),
    overview: ({}) => <Overview />,
  };

  return (
    <>
      <Row className="chart-basic-schematic">
        <ReactFromJSON entry={entry} mapping={mapping} />
      </Row>
    </>
  );
}
