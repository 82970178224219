import React from "react";
import { List, Typography } from "antd";
const { Text } = Typography;

const ListActionComponent = ({ header, item, children }) => {
  return (
    <>
      <List
        size="small"
        header={
          <Text strong style={{ padding: "6px 0" }}>
            {header}
          </Text>
        }
        bordered
        style={{ borderBottomWidth: "0" }}
      >
        <List.Item actions={[]}>
          {item} {children}
        </List.Item>
      </List>
    </>
  );
};

export default ListActionComponent;
