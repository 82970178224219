import React from "react";
import { Typography, Modal } from "antd";
import YouTube from "react-youtube";
import { InfoCircleOutlined } from "@ant-design/icons";
const { Link } = Typography;

class IVaccess extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      current: 0,
    };
  }

  showModal = () => {
    this.setState({
      current: 0,
      visible: true,
    });
  };

  handleOk = (e) => {
    this.setState({
      current: 0,
      visible: false,
    });
  };

  handleCancel = (e) => {
    this.setState({
      current: 0,
      visible: false,
    });
  };

  _onPause = (event) => {
    event.target.pauseVideo();
    this.handleCancel();
  };

  render() {
    return (
      <>
        <Link
          type="link"
          onClick={this.showModal}
          style={{
            color: "rgba(0, 0, 0, 0.65)",
            textDecoration: "underline",
            textDecorationStyle: "wavy",
            textDecorationColor: "#228be6",
          }}
        >
          IV-access <InfoCircleOutlined style={{ color: "#228be6" }} />
        </Link>
        <Modal
          title="IV-access"
          visible={this.state.visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          footer={[]}
        >
          <div className="video-container">
            <YouTube
              videoId="6zcsYUqaWCE"
              onPause={this._onPause}
              onEnd={this._onPause}
            />
          </div>
        </Modal>
      </>
    );
  }
}

export default IVaccess;
