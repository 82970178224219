import React from "react";
import { Button, Divider, Typography, Steps, Modal, List, Alert } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
const { Paragraph, Text, Link } = Typography;
const { Step } = Steps;
const steps = [
  {
    title: "Airway (A)",
    content: [
      <List
        bordered
        header={<Text strong>Look</Text>}
        style={{ marginBottom: "16px" }}
      >
        <List.Item>Inside the mouth</List.Item>
        <List.Item>Remove obvious objects/ dentures</List.Item>
      </List>,
      <List
        bordered
        header={<Text strong>Listen</Text>}
        style={{ marginBottom: "16px" }}
      >
        <List.Item>Stridor</List.Item>
        <List.Item>Snoring</List.Item>
        <List.Item>Gurgling implies airway compromise</List.Item>
      </List>,
      <List
        bordered
        header={<Text strong>Do</Text>}
        style={{ marginBottom: "16px" }}
      >
        <List.Item>Suction under direct vision if secretions present</List.Item>
        <List.Item>
          Jaw thrust/head tilt/chin lift (with cervical spine control in trauma)
        </List.Item>
        <List.Item>
          Insert and oropharyngeal or nasopharyngeal airway as tolerated
        </List.Item>
      </List>,
      <Paragraph>
        <Alert
          showIcon
          type="warning"
          description="If airway still compromised call arrest team!"
        />
      </Paragraph>,
      <List
        bordered
        header={<Text strong>Do</Text>}
        style={{ marginBottom: "16px" }}
      >
        <List.Item>
          Give oxygen (Maintain an oxygen saturation of 94–98% – always give
          oxygen initially in the acutely unwell patient)
        </List.Item>
      </List>,
      <Paragraph>
        <Alert
          showIcon
          message="Ask nurse to put monitoring on now – this will speed things up"
        />
      </Paragraph>,
      <Paragraph>
        <Alert
          showIcon
          message="If the patient is peri-arrest ask for the crash trolley now"
        />
      </Paragraph>,
    ],
  },
  {
    title: "Breathing (B)",
    content: [
      <Paragraph>
        <Alert
          showIcon
          type="warning"
          description="If poor or absent respiratory effort call cardiac arrest team"
        />
      </Paragraph>,
      <List
        bordered
        header={<Text strong>Look</Text>}
        style={{ marginBottom: "16px" }}
      >
        <List.Item>
          For chest expansion (is it even between left and right?)
        </List.Item>
        <List.Item>Fogging of mask</List.Item>
      </List>,
      <List
        bordered
        header={<Text strong>Listen</Text>}
        style={{ marginBottom: "16px" }}
      >
        <List.Item>
          To lungs for air entry (is it even between left and right?)
        </List.Item>
        <List.Item>
          If any concern percuss as well (is it even between left and right –
          especially thinking of tension pneumothorax)
        </List.Item>
      </List>,
      <List
        bordered
        header={<Text strong>Do</Text>}
        style={{ marginBottom: "16px" }}
      >
        <List.Item>
          Non-rebreather mask and 15l/min O<sup>2</sup> initially in all
          patients
        </List.Item>
        <List.Item>
          Bag valve mask (BVM) if poor or absent breathing effort
        </List.Item>
      </List>,
      <Paragraph>
        <Alert showIcon message="Monitor O2 sats and respiratory rate" />
      </Paragraph>,
      <Paragraph>
        <Alert
          showIcon
          type="warning"
          description="If this is clinically a tension pneumothorax and the patient is peri-arrest then perform needle decompression immediately"
        />
      </Paragraph>,
    ],
  },
  {
    title: "Circulation (C)",
    content: [
      <Paragraph>
        <Alert
          showIcon
          type="warning"
          description="If no pulse call cardiac arrest team"
        />
      </Paragraph>,
      <List
        bordered
        header={<Text strong>Look</Text>}
        style={{ marginBottom: "16px" }}
      >
        <List.Item>For pallor</List.Item>
        <List.Item>Cyanosis</List.Item>
        <List.Item>Distended neck veins</List.Item>
      </List>,
      <List
        bordered
        header={<Text strong>Listen</Text>}
        style={{ marginBottom: "16px" }}
      >
        <List.Item>
          For a central pulse (carotid/femoral) rate and rhythm
        </List.Item>
        <List.Item>Check for capillary refill time</List.Item>
      </List>,
      <List
        bordered
        header={<Text strong>Monitoring</Text>}
        style={{ marginBottom: "16px" }}
      >
        <List.Item>
          Defibrillator ECG leads and blood pressure if not already done
        </List.Item>
      </List>,
      <List bordered header={<Text strong>Do</Text>}>
        <List.Item>
          Get venous access (x2 large bore) and send bloods if time allows (FBC,
          U&E, LFT, coagulation, group and screen, troponin if appropriate)
        </List.Item>
        <List.Item>
          Get VBG with the bloods and/or ABG if sats under 95%
        </List.Item>
        <List.Item>Give fluids</List.Item>
      </List>,
    ],
  },
  {
    title: "Disability (D)",
    content: [
      <List bordered style={{ marginBottom: "16px" }}>
        <List.Item>Assess AVPU (and GCS if you have time)</List.Item>
        <List.Item>Check fingerprick glucose</List.Item>
      </List>,
      <List
        bordered
        header={<Text strong>Do</Text>}
        style={{ marginBottom: "16px" }}
      >
        <List.Item>
          Give glucose if under 4mmol/l (give 50ml of 50% glucose [or 100ml 20%]
          IV)
        </List.Item>
      </List>,
      <List
        bordered
        header={<Text strong>Look</Text>}
        style={{ marginBottom: "16px" }}
      >
        <List.Item>
          For pupil size and reaction to light; unusual posturing
        </List.Item>
      </List>,
      <List
        bordered
        header={<Text strong>Feel</Text>}
        style={{ marginBottom: "16px" }}
      >
        <List.Item>For tone in all four limbs and plantar reflexes</List.Item>
      </List>,
    ],
  },
  {
    title: "Exposure (E)",
    content: [
      <List bordered style={{ marginBottom: "16px" }}>
        <List.Item>Check temperature</List.Item>
      </List>,
      <List
        bordered
        header={<Text strong>Do</Text>}
        style={{ marginBottom: "16px" }}
      >
        <List.Item>
          Warm patient if hypothermic hypothermia and no indication for
          therapeutic cooling
        </List.Item>
      </List>,
      <List bordered>
        <List.Item>
          Look all over body including groin and back for rash or injuries
        </List.Item>
        <List.Item>Cover patient with a blanket and respect dignity</List.Item>
      </List>,
    ],
  },
];

class ABCDEApproach extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      current: 0,
    };
  }

  onChange = (value) => {
    const current = value;
    this.setState({ current });
  };

  next() {
    const current = this.state.current + 1;
    this.setState({ current });
  }

  prev() {
    const current = this.state.current - 1;
    this.setState({ current });
  }

  showModal = () => {
    this.setState({
      current: 0,
      visible: true,
    });
  };

  handleOk = (e) => {
    console.log(e);
    this.setState({
      current: 0,
      visible: false,
    });
  };

  handleCancel = (e) => {
    console.log(e);
    this.setState({
      current: 0,
      visible: false,
    });
  };

  render() {
    const { current } = this.state;
    return (
      <>
        <Link
          type="link"
          onClick={this.showModal}
          style={{
            color: "rgba(0, 0, 0, 0.65)",
            textDecoration: "underline",
            textDecorationStyle: "wavy",
            textDecorationColor: "#228be6",
          }}
        >
          ABCDE-approach <InfoCircleOutlined style={{ color: "#228be6" }} />
        </Link>
        <Modal
          title="ABCDE-approach"
          visible={this.state.visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          footer={[
            <div>
              {current > 0 && (
                <Button style={{ margin: "0 8px" }} onClick={() => this.prev()}>
                  Previous step
                </Button>
              )}
              {current < steps.length - 1 && (
                <Button type="primary" onClick={() => this.next()}>
                  Next step
                </Button>
              )}
              {current === steps.length - 1 && (
                <Button type="primary" onClick={() => this.handleCancel()}>
                  Done & close
                </Button>
              )}
            </div>,
          ]}
        >
          <div>
            <Steps current={current} onChange={this.onChange} size="small">
              {steps.map((item) => (
                <Step key={item.title} title={item.title} />
              ))}
            </Steps>
            <div
              className="steps-content"
              style={{ padding: "24px 0 0 0", backgroundColor: "#fff" }}
            >
              {steps[current].content}
              <Divider dashed />
              <Text style={{ fontSize: "75%" }}>
                Source:{" "}
                <a
                  href="http://www.oxfordmedicaleducation.com/emergency-medicine/abcde-assessment/"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  Oxford Medical Education
                </a>
              </Text>
            </div>
          </div>
        </Modal>
      </>
    );
  }
}

export default ABCDEApproach;
