import React, { useState } from "react";
import { Button, Tooltip } from "antd";
import { useDebounceFn } from "ahooks";

export default function ButtonWithIndicator({
  onNext,
  k,
  v,
  disabled,
  size,
  tooltip,
}) {
  const [isClicked, setIsClicked] = useState(false);
  const { run } = useDebounceFn(
    (k) => {
      onNext(k);
    },
    {
      wait: 250,
    }
  );

  return (
    <Tooltip placement="right" title={tooltip}>
      <Button
        loading={isClicked}
        onClick={() => {
          setIsClicked(true);
          run(k);
        }}
        style={{ marginTop: "6px", marginRight: "6px", whitespace: "normal" }}
        type="primary"
        disabled={disabled}
        size={size}
      >
        {v}
      </Button>
    </Tooltip>
  );
}
