import React, { Component } from "react";
import { Checkbox, Divider, Statistic, Typography } from "antd";
const { Text } = Typography;

const ITEM_GROUPS = [
  {
    id: "group_one",
    choices: [
      {
        id: "choice_one",
        title:
          "Hemodynamisch instabiliteit; (systolische bloeddruk < 100 mm Hg en/of hartfrequentie > 100 slagen per minuut",
        score: 1,
      },
    ],
  },
  {
    id: "group_two",
    choices: [
      {
        id: "choice_one",
        title: "Klinische conditie waarbij opname op de ICU geïndiceerd is",
        score: 1,
      },
    ],
  },
  {
    id: "group_three",
    choices: [
      {
        id: "choice_one",
        title: "Noodzaak voor trombolyse of embolectomie",
        score: 1,
      },
    ],
  },
  {
    id: "group_four",
    choices: [
      {
        id: "choice_one",
        title: "Hypoxemie met noodzaak van zuurstoftoediening",
        score: 1,
      },
    ],
  },
  {
    id: "group_five",
    choices: [
      {
        id: "choice_one",
        title:
          "Diagnose longembolie tijdens al bestaande antistollingsbehandeling vastgesteld",
        score: 1,
      },
    ],
  },
  {
    id: "group_six",
    choices: [
      {
        id: "choice_one",
        title: "Noodzaak van intraveneuze pijnmedicatie",
        score: 1,
      },
    ],
  },
  {
    id: "group_seven",
    choices: [
      {
        id: "choice_one",
        title: "Trombocytopenie (< 75×10⁹/l)",
        score: 1,
      },
    ],
  },
  {
    id: "group_eight",
    choices: [
      {
        id: "choice_one",
        title: "Ernstige leverinsufficiëntie",
        score: 1,
      },
    ],
  },
  {
    id: "group_nine",
    choices: [
      {
        id: "choice_one",
        title: "Ernstige nierinsufficiëntie",
        score: 1,
      },
    ],
  },
  {
    id: "group_ten",
    choices: [
      {
        id: "choice_one",
        title:
          "Ontbreken van goede opvang thuis en goede toegang tot medische zorg",
        score: 1,
      },
    ],
  },
  {
    id: "group_eleven",
    choices: [
      {
        id: "choice_one",
        title: "Zwangerschap",
        score: 1,
      },
    ],
  },
  {
    id: "group_twelve",
    choices: [
      {
        id: "choice_one",
        title:
          "Actieve bloeding of hoog risico van bloeding (link naar zie tabel bloedingsrisico in “de behandelduur van de antistollingstherapie bij veneuze trombo embolieen”).",
        score: 1,
      },
    ],
  },
];

export default class HestiaCriteria extends Component {
  constructor(props) {
    super(props);

    const selectedChoices = {};
    for (const itemGroup of ITEM_GROUPS) {
      selectedChoices[itemGroup.id] = null;
    }

    this.state = {
      selectedChoices,
    };
  }

  render = () => {
    return (
      <>
        <div className="wrapper-interactive">
          <h4>HESTIA-criteria</h4>
          <Divider />
          {this._renderGroups()}
          <Statistic title="Score:" value={this._renderScore()} />
        </div>
      </>
    );
  };

  _renderGroups = () => {
    return ITEM_GROUPS.map((group) => {
      return (
        <div key={group.id}>
          <Text>{group.title}</Text>
          {group.choices.map((choice) => {
            return (
              <div style={{ padding: "12px 0" }}>
                <Checkbox
                  key={choice.id}
                  id={choice.title}
                  onChange={() => this._selectChoice(group, choice)}
                  checked={this.state.selectedChoices[group.id] === choice.id}
                  style={{ marginRight: "16px" }}
                />
                <label for={choice.title} style={{ color: "white" }}>
                  {choice.title}{" "}
                  {/* <Tag style={{ marginLeft: "15px" }}>{choice.score}</Tag> */}
                </label>
              </div>
            );
          })}
        </div>
      );
    });
  };

  _renderScore = () => {
    let score = 0;
    for (const group of ITEM_GROUPS) {
      for (const choice of group.choices) {
        if (choice.id === this.state.selectedChoices[group.id]) {
          score += choice.score;
        }
      }
    }

    return score;
  };

  _selectChoice = (group, choice) => {
    const groupId = group.id;
    const choiceId = choice.id;
    const selectedChoiceId = this.state.selectedChoices[groupId];
    const newChoiceId = selectedChoiceId === choiceId ? null : choiceId;

    this.setState({
      selectedChoices: Object.assign(this.state.selectedChoices, {
        [groupId]: newChoiceId,
      }),
    });
  };
}
