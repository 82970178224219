import React, { Component } from "react";
import _ from "lodash";
import { Col, Button, Row, Typography } from "antd";
import { QuestionCircleFilled } from "@ant-design/icons";
const { Title, Text } = Typography;

export default class Node extends Component {
  constructor(props) {
    super(props);
  }

  render = () => {
    const { nodeId, isLastOnPath, isOnPath, isEndNode } = this.props;

    if (!isOnPath) {
      return <Col id={nodeId} key={nodeId} span={24} offset={0}></Col>;
    } else if (isOnPath && !isLastOnPath) {
      return (
        <Col
          id={nodeId}
          key={nodeId}
          span={20}
          offset={2}
          style={{ padding: "8px 0" }}
        >
          <Row>
            <Col span={2}>
              <QuestionCircleFilled
                style={{ color: "#fff", fontSize: "20px" }}
              />
            </Col>
            <Col span={22}>
              {this.props.nodeTitle}
              {this.linksButtonsActive()}
            </Col>
          </Row>
        </Col>
      );
    } else if (isEndNode) {
      return (
        <Col id={nodeId} key={nodeId} span={20} offset={2}>
          <div
            className="node-card node-isEndNode"
            style={{ margin: "10px 0" }}
          >
            <Title level={1}>{this.props.nodeTitle}</Title>
            {this.props.children}
            <div className="node-links">{this.linksButtons()}</div>
          </div>
        </Col>
      );
    } else if (isLastOnPath) {
      return (
        <Col id={nodeId} key={nodeId} span={20} offset={2}>
          <div className="node-card">
            <Title level={1}>{this.props.nodeTitle}</Title>
            {this.props.children}
            <div className="node-links">{this.linksButtons()}</div>
          </div>
        </Col>
      );
    }
  };

  linksButtons = () => {
    return _.map(this.props.links, (v, k) => {
      return (
        <Button
          type="primary"
          key={`${v}_${k}`}
          onClick={() => this.props.onNext(k)}
          style={{
            backgroundColor: "#fff",
            borderColor: "#fff",
            color: "#000",
            borderRadius: "2px",
            height: "36px",
            margin: "8px 16px 8px 0",
            whitespace: "normal",
          }}
        >
          {v}
        </Button>
      );
    });
  };

  linksButtonsActive = () => {
    const { onNext, linksActive } = this.props;
    return _.map(this.props.links, (v, k) => {
      const buttonActive = {
        color: "#000",
        fontWeight: "500",
        margin: "0 8px",
      };
      const buttonInactive = {
        display: "none",
      };
      const buttonStyle = k === linksActive ? buttonActive : buttonInactive;
      if (onNext) {
        return (
          <>
            <Text style={buttonStyle}>{v}</Text>
          </>
        );
      }
    });
  };
}
