import React from "react";
import { Col, Row, Button, Tooltip, Divider, Tag, Typography } from "antd";
import LinksButtons from "../../ui/LinksButtons";
import LinksButtonsActiveSingle from "../../ui/LinksButtonsActiveSingle";
import { ReloadOutlined, CheckCircleOutlined } from "@ant-design/icons";
import TextLoop from "react-text-loop";

const { Title } = Typography;

export default function Node({
  children,
  disableDefaultOptionsButtons,
  extraContent,
  isEndNode,
  isLastOnPath,
  isOnPath,
  links,
  linksActive,
  nodeId,
  nodeTitle,
  onGoTo,
  onNext,
  onReset,
  options,
}) {
  const onNodeGoTo = () => onGoTo(nodeId);

  if (!isOnPath) {
    return null;
  } else if (isOnPath && !isLastOnPath) {
    return (
      <Col
        id={nodeId}
        key={nodeId}
        xs={{ span: 22, offset: 1 }}
        md={{ span: 20, offset: 2 }}
        lg={{ span: 12, offset: 6 }}
        className="node-wrapper"
        style={{ paddingBottom: "10px", position: "relative" }}
      >
        <div
          id={`${nodeId}-top`}
          style={{
            position: "absolute",
            left: "12px",
            width: "1px",
            height: "1px",
          }}
        />
        <Row wrap={false}>
          <Col flex="39px">
            <CheckCircleOutlined
              style={{
                position: "relative",
                top: "6px",
                left: "1px",
                fontSize: "24px",
                backgroundColor: "white",
                borderRadius: "100%",
                zIndex: "100",
                // marginTop: "7.5px"
              }}
            />
          </Col>
          <Col flex="auto">
            <Title
              level={4}
              style={{ fontSize: "16px", cursor: "pointer" }}
              onClick={onNodeGoTo}
              ellipsis
            >
              <span style={{ marginRight: "15px" }}>{nodeTitle}</span>
              <LinksButtonsActiveSingle
                links={links}
                linksActive={linksActive}
                onNext={onNext}
                size="small"
              />
            </Title>
          </Col>
        </Row>
        <div
          id={`${nodeId}-bottom`}
          style={{
            position: "absolute",
            top: "36px",
            left: "12px",
            width: "1px",
            height: "1px",
          }}
        />
      </Col>
    );
  } else if (isEndNode) {
    return (
      <Col
        id={nodeId}
        key={nodeId}
        xs={{ span: 20, offset: 2 }}
        lg={{ span: 12, offset: 6 }}
        className="node-wrapper"
        style={{ marginTop: "30px", position: "relative" }}
      >
        <div
          id={`${nodeId}-top`}
          style={{
            position: "absolute",
            top: "0px",
            left: "36px",
            width: "1px",
            height: "1px",
          }}
        />
        <div
          className="node-card step-last-step"
          style={{
            padding: "30px",
            border: "2px #0D6739 solid",
            backgroundColor: "#ebfbee",
            borderRadius: "5px",
            boxShadow: "6px 6px 10px #dcdcdc",
          }}
        >
          <Title level={4} style={{ fontSize: "18px", marginBottom: "15px" }}>
            <Tag color="#0D6739">Einde</Tag>
            {nodeTitle}
          </Title>
          <Divider style={{ borderColor: "#0D6739", borderWidth: "2px" }} />
          {children}
          <Tooltip placement="top" title="Herstart algoritme">
            <Button
              shape="circle"
              onClick={() => onReset()}
              icon={<ReloadOutlined />}
              style={{ position: "absolute", top: "12px", right: "12px" }}
            />
          </Tooltip>
        </div>
      </Col>
    );
  } else if (isLastOnPath) {
    return (
      <Col
        id={nodeId}
        key={nodeId}
        xs={{ span: 22, offset: 1 }}
        lg={{ span: 14, offset: 5 }}
        className="node-wrapper lastOnPath"
        style={{ marginTop: "15px" }}
      >
        <div
          id={`${nodeId}-top`}
          style={{
            position: "absolute",
            left: "24px",
            width: "1px",
            height: "1px",
          }}
        />
        <div
          className="node-card"
          style={{
            padding: "30px",
            border: "2px #882941 solid",
            borderRadius: "5px",
            boxShadow: "4px 4px 10px #dcdcdc",
            background:
              "linear-gradient(188deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0.8909090909090909) 50%, rgba(243,233,236,1) 100%)",
          }}
        >
          <Title level={3} style={{ width: "85%", fontSize: "18px" }}>
            {nodeTitle}
          </Title>
          {!options && <>{children}</>}
          {extraContent && <>{extraContent}</>}
          {options && <div className="node-links">{options}</div>}
          {!disableDefaultOptionsButtons && (
            <>
              <div className="node-links">
                <Divider
                  orientation="left"
                  plain
                  style={{
                    margin: "15px 0 10px 0",
                    fontSize: "12px",
                    borderColor: "#882941",
                  }}
                >
                  <TextLoop interval={5000}>
                    <>Beschikbare opties:</>
                    <>Maak uw keuze om door te gaan:</>
                  </TextLoop>
                </Divider>
                <LinksButtons links={links} onNext={onNext} />
              </div>
            </>
          )}
        </div>
      </Col>
    );
  }
}
