import React, { useState } from "react";
import { Button } from "antd";
import { useDebounceFn } from "ahooks";

export default function ButtonWithIndicator({ onNext, k, v, disabled, size }) {
  const [isClicked, setIsClicked] = useState(false);
  const { run } = useDebounceFn(
    (k) => {
      onNext(k);
    },
    {
      wait: 250,
    }
  );

  return (
    <Button
      loading={isClicked}
      onClick={() => {
        setIsClicked(true);
        run(k);
      }}
      style={{
        height: "41px",
        padding: "0 15px",
        marginTop: "15px",
        marginRight: "15px",
        whitespace: "normal",
        border: "2px solid white",
        borderRadius: "0",
        backgroundColor: "#1d396e",
        color: "white",
        fontSize: "18px",
      }}
      disabled={disabled}
      size={size}
    >
      {v}
    </Button>
  );
}
