import React from "react";
import {
  Alert,
  Button,
  Carousel,
  Descriptions,
  Divider,
  Result,
  Typography,
} from "antd";
import ContentModal from "../ui/ContentModal";
import { Link } from "gatsby";
import ContentListAction from "../ui/ContentListAction";
import List from "../ui/ContentList";
import { ExportOutlined } from "@ant-design/icons";
import ContentModalWithButtons from "../ui/ContentModalWithButtons";

const { Paragraph, Text, Title } = Typography;

/* eslint-disable react/display-name */
export const ContentComponents = {
  alert: ({ type, message }) => (
    <Paragraph>
      <Alert message={message} type={type} showIcon style={{ margin: "0" }} />
    </Paragraph>
  ),
  button: ({ text, type, ghost, to, disabled }) => (
    <Paragraph>
      <Button type={type} ghost={ghost} disabled={disabled}>
        <Link to={to}>{text}</Link>
      </Button>
    </Paragraph>
  ),
  link: ({ to, text }) => (
    <Paragraph>
      <a href={to} target="_blank" rel="noreferrer noopener">
        {text}
      </a>
    </Paragraph>
  ),
  carousel: ({ slides }) => (
    <Carousel
      autoplay
      style={{
        height: "400px",
        backgroundColor: "#364d79",
        textAlign: "center",
      }}
      effect="fade"
    >
      {slides}
    </Carousel>
  ),
  diagram: ({}) => <></>,
  divider: () => <Divider style={{ margin: "12px 0" }} />,
  description: ({ items }) => (
    <Descriptions
      bordered
      size="small"
      column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
    >
      {items}
    </Descriptions>
  ),
  descriptionItem: ({ label, text }) => (
    <Descriptions.Item label={label} span={3}>
      {text}
    </Descriptions.Item>
  ),
  image: ({ src, alt }) => (
    <div
      style={{
        textAlign: "center",
      }}
    >
      <img src={src} alt={alt} style={{ height: "400px", margin: "0 auto" }} />
    </div>
  ),
  list: ({ items, header }) => (
    <List size="small" header={header} bordered items={items} />
  ),
  listAction: ({ header, items, item, children }) => (
    <ContentListAction
      size="small"
      header={header}
      bordered
      items={items}
      item={item}
      children={children}
    />
  ),
  linkExternal: ({ text, to }) => (
    <Paragraph>
      <a href={to} target="_blank" rel="noopener noreferrer">
        <ExportOutlined style={{ transform: "rotate(-90deg)" }} /> {text}{" "}
        <span style={{ fontSize: "9px" }}>(opent in een nieuw venster)</span>
      </a>
    </Paragraph>
  ),
  linkExternalSimple: ({ text, to }) => (
      <a href={to} target="_blank" rel="noopener noreferrer">
        {text}
      </a>
  ),
  modalContent: ({ trigger, title, content }) => (
    <>
    <ContentModal trigger={trigger} title={title} content={content} />
    </>
  ),
  // contentModalWithButtons: ({ trigger, title, content }) => (
  //   <ContentModalWithButtons trigger={trigger} title={title} content={content} />
  // ),
  paragraph: ({ children, text, ellipsis }) => (
    <Paragraph ellipsis={ellipsis}>
      {text} {children}
    </Paragraph>
  ),
  result: ({ title, status }) => <Result title={title} status={status} />,
  text: ({
    text,
    type,
    code,
    copyable,
    disabled,
    ellipsis,
    mark,
    keyboard,
    underline,
    strong,
    style,
  }) => (
    <Text
      type={type}
      code={code}
      copyable={copyable}
      disabled={disabled}
      ellipsis={ellipsis}
      mark={mark}
      keyboard={keyboard}
      underline={underline}
      strong={strong}
    >
      {text}
    </Text>
  ),
  title: ({ title, level }) => <Title level={level}>{title}</Title>,
};
