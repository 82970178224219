import React, { Component } from "react";
import { Checkbox, Divider, Statistic, Typography } from "antd";
import ContentAlert from "../ui/ContentAlert";
const { Text } = Typography;

const ITEM_GROUPS = [
  {
    id: "group_one",
    title: "Trombocytopenie",
    choices: [
      {
        id: "choice_two",
        title: "> 50% daling trombocyten en nadir > 20*10⁹ /L",
        score: 2,
      },
      {
        id: "choice_one",
        title: "30-50% daling trombocyten of nadir 10-19*10⁹ /L",
        score: 1,
      },
      {
        id: "choice_zero",
        title: "< 30% daling trombocyten of nadir < 10*10⁹ /L",
        score: 0,
      },
    ],
  },
  {
    id: "group_two",
    title: "Tijdstip dalen trombocyten na start heparine",
    choices: [
      {
        id: "choice_two",
        title: "Dag 5 - 10, of ≤ 1 dag bij recent heparine gebruik",
        score: 2,
      },
      {
        id: "choice_one",
        title: "> 10 dagen of onduidelijk tijdsbeloop",
        score: 1,
      },
      {
        id: "choice_zero",
        title: "≤4 dagen zonder recent gebruik heparine",
        score: 0,
      },
    ],
  },
  {
    id: "group_three",
    title: "Trombose en andere symptomen",
    choices: [
      {
        id: "choice_two",
        title:
          "Bewezen trombose, huidnecrose, of acute systemische reactie na i.v. heparine",
        score: 2,
      },
      {
        id: "choice_one",
        title: "Progressieve of recidiverende trombose",
        score: 1,
      },
      {
        id: "choice_zero",
        title: "Geen",
        score: 0,
      },
    ],
  },
  {
    id: "group_four",
    title: "Andere oorzaak trombocytopenie",
    choices: [
      {
        id: "choice_two",
        title: "Geen duidelijke andere oorzaak",
        score: 2,
      },
      {
        id: "choice_one",
        title: "Andere oorzaak mogelijk",
        score: 1,
      },
      {
        id: "choice_zero",
        title: "Andere oorzaak zeker",
        score: 0,
      },
    ],
  },
];

export default class HestiaCriteria extends Component {
  constructor(props) {
    super(props);

    const selectedChoices = {};
    for (const itemGroup of ITEM_GROUPS) {
      selectedChoices[itemGroup.id] = null;
    }

    this.state = {
      selectedChoices,
    };
  }

  render = () => {
    return (
      <>
        <div className="wrapper-interactive">
          <h4>4T HIT Risicoscore</h4>
          <Divider />
          {this._renderGroups()}
          <Statistic title="Score:" value={this._renderScore()} />
          <ContentAlert
            showIcon
            type="info"
            message={this._renderScoreText()}
            style={{ marginTop: "10px" }}
          />
        </div>
      </>
    );
  };

  _renderGroups = () => {
    return ITEM_GROUPS.map((group) => {
      return (
        <div key={group.id}>
          <div style={{ padding: "15px 0" }}>
            <Text style={{ color: "white", fontWeight: 400 }}>
              {group.title}
            </Text>
          </div>
          {group.choices.map((choice) => {
            return (
              <div style={{ padding: "12px 0" }}>
                <Checkbox
                  key={choice.id}
                  id={choice.title}
                  onChange={() => this._selectChoice(group, choice)}
                  checked={this.state.selectedChoices[group.id] === choice.id}
                  style={{ marginRight: "16px" }}
                />
                <label for={choice.title} style={{ color: "white" }}>
                  {choice.title}{" "}
                  {/* <Tag style={{ marginLeft: "15px" }}>
                    <Text strong style={{ fontSize: "10px" }}>
                      {choice.score}
                    </Text>
                  </Tag> */}
                </label>
              </div>
            );
          })}
        </div>
      );
    });
  };

  _renderScore = () => {
    let score = 0;
    for (const group of ITEM_GROUPS) {
      for (const choice of group.choices) {
        if (choice.id === this.state.selectedChoices[group.id]) {
          score += choice.score;
        }
      }
    }

    return score;
  };

  _renderScoreText = () => {
    let score = 0;
    let scoreText = "";

    for (const group of ITEM_GROUPS) {
      for (const choice of group.choices) {
        if (choice.id === this.state.selectedChoices[group.id]) {
          score += choice.score;
        }
      }
    }

    if (score < 4) {
      scoreText = "lage waarschijnlijkheid, heparine/LMWH continueren";
    } else if (score === 4 || score === 5) {
      scoreText =
        "matige waarschijnlijkheid, HIT-antistoffen bepalen en als positief dan heparine/LMWH staken en andere antistolling starten, indien HIT-antistoffen negatief dan kan heparine/LMWH gecontinueerd worden met goede follow-up van de kliniek";
    } else if (score > 5) {
      scoreText =
        "hoge waarschijnlijkheid, alle heparine/LMWH moet gestaakt en andere antistolling dient gestart te worden";
    }

    return scoreText;
  };

  _selectChoice = (group, choice) => {
    const groupId = group.id;
    const choiceId = choice.id;
    const selectedChoiceId = this.state.selectedChoices[groupId];
    const newChoiceId = selectedChoiceId === choiceId ? null : choiceId;

    this.setState({
      selectedChoices: Object.assign(this.state.selectedChoices, {
        [groupId]: newChoiceId,
      }),
    });
  };
}
