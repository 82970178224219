import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import { Row, Col, List, Tag, Typography } from "antd";

const { Text, Title } = Typography;

export default function NivTableOfContentsNewlyAdded() {
  const data = useStaticQuery(graphql`
    query NivTableOfContentsProductionQuery {
      allMdx(
        filter: {
          frontmatter: {
            client: { eq: "niv_acuteboekje" }
            title: { nin: "Overview" }
            status: { eq: "In productie" }
          }
        }
        sort: { fields: frontmatter___slug }
      ) {
        nodes {
          frontmatter {
            edit
            slug
            status
            title
            type
          }
        }
      }
    }
  `);
  return (
    <>
      <Row justify="center" align="middle" className="section">
        <Col xs={23} sm={22} md={22} lg={20} xl={12}>
          <Title level={2}>In productie</Title>
          <List bordered style={{ backgroundColor: "#fff" }}>
            <List.Item
              actions={[
                <Text style={{ fontSize: "11px", fontWeight: "bold" }}>
                  Type
                </Text>,
                <Text style={{ fontSize: "11px", fontWeight: "bold" }}>
                  Aangepast
                </Text>,
              ]}
            >
              <Text style={{ fontSize: "14px", fontWeight: "bold" }}>
                Titel
              </Text>
            </List.Item>
            {data.allMdx.nodes.map(({ frontmatter }) => (
              <List.Item
                actions={[
                  <Tag color="#87d068">{frontmatter.type}</Tag>,
                  <Text style={{ fontSize: "11px" }}>{frontmatter.edit}</Text>,
                ]}
              >
                <Link
                  style={{ color: "#882941", textDecoration: "underline" }}
                  to={frontmatter.slug}
                  key={frontmatter.slug}
                >
                  {frontmatter.title}
                </Link>
              </List.Item>
            ))}
          </List>
        </Col>
      </Row>
    </>
  );
}
