import React, { Component } from "react";
import _ from "lodash";
import { Col, Button, Typography } from "antd";
import { LoadingOutlined, CheckSquareOutlined } from "@ant-design/icons";
const { Title } = Typography;

export default class Node extends Component {
  constructor(props) {
    super(props);
  }

  render = () => {
    const { nodeId, isLastOnPath, isOnPath, isEndNode } = this.props;

    if (!isOnPath) {
      return <Col id={nodeId} key={nodeId} span={24} offset={0}></Col>;
    } else if (isOnPath && !isLastOnPath) {
      return <Col id={nodeId} key={nodeId} span={24} offset={0}></Col>;
    } else if (isEndNode) {
      return (
        <Col id={nodeId} key={nodeId} xs={22} sm={20} md={16} lg={8} xl={6}>
          <div
            className="node-card node-isEndNode"
            style={{ margin: "10px 0" }}
          >
            <Title level={1}>{this.props.nodeTitle}</Title>
            {this.props.children}
            <div className="node-links">{this.linksButtons()}</div>
          </div>
        </Col>
      );
    } else if (isLastOnPath) {
      return (
        <Col id={nodeId} key={nodeId} xs={22} sm={20} md={16} lg={12} xl={8}>
          <div className="node-card" style={{ margin: "10px 0" }}>
            <Title level={1}>{this.props.nodeTitle}</Title>
            {this.props.children}
            <div className="node-links">{this.linksButtons()}</div>
          </div>
        </Col>
      );
    }
  };

  linksButtons = () => {
    return _.map(this.props.links, (v, k) => {
      return (
        <Button
          type="primary"
          key={`${v}_${k}`}
          onClick={() => this.props.onNext(k)}
          style={{
            backgroundColor: "#008374",
            borderColor: "#008374",
            borderRadius: "4px",
            height: "50px",
            margin: "10px 10px 5px 0",
            whitespace: "normal",
            fontFamily: "Lato, sans-serif",
          }}
        >
          {v}
        </Button>
      );
    });
    <LoadingOutlined />;
  };

  linksButtonsActive = () => {
    const { onNext, linksActive } = this.props;
    return _.map(this.props.links, (v, k) => {
      const buttonActive = {
        backgroundColor: "#ebfbee",
        color: "#52c41a",
        borderColor: "#52c41a",
        marginTop: "6px",
        marginRight: "6px",
      };
      const buttonInactive = {
        marginTop: "6px",
        marginRight: "6px",
      };
      const buttonStyle = k === linksActive ? buttonActive : buttonInactive;
      const buttonIcon = k === linksActive ? <CheckSquareOutlined /> : null;
      if (onNext) {
        return (
          <>
            <Button
              type="primary"
              icon={buttonIcon}
              key={`${v}_${k}`}
              disabled
              style={buttonStyle}
            >
              {v}
            </Button>
          </>
        );
      }
    });
  };
}
