import React from "react";
import { Alert, Typography, Button, notification } from "antd";
import { BrowserView, MobileView } from "react-device-detect";
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  InfoCircleOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";

const { Paragraph } = Typography;

const ContentAlert = ({ message, type }) => {
  const Icon = ({ type }) => {
    if (type === "info") {
      return (
        <InfoCircleOutlined
          style={{
            color: "#1d396e",
            fontSize: "30px",
            position: "absolute",
            left: "20px",
            top: "7px",
          }}
        />
      );
    }

    if (type === "warning") {
      return (
        <ExclamationCircleOutlined
          style={{
            color: "#FF8400",
            fontSize: "30px",
            position: "absolute",
            left: "20px",
            top: "7px",
          }}
        />
      );
    }

    if (type === "error") {
      return (
        <CloseCircleOutlined
          style={{
            color: " #FF0000",
            fontSize: "30px",
            position: "absolute",
            left: "20px",
            top: "7px",
          }}
        />
      );
    }

    if (type === "success") {
      return (
        <CheckCircleOutlined
          style={{
            color: "#c9d22a",
            fontSize: "30px",
            position: "absolute",
            left: "20px",
            top: "7px",
          }}
        />
      );
    }
  };

  return (
    <>
      <Alert
        message={message}
        type={type}
        icon={<Icon type={type} />}
        showIcon
        style={{ margin: "16px 0" }}
      />
    </>
  );
};

export default ContentAlert;
