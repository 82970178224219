import React, { Component } from "react";
import _ from "lodash";
import { Col, Button, Tooltip, Divider, Typography, Space, Tag } from "antd";
import TrackVisibility from "react-on-screen";
import {
  BrowserView,
  CustomView,
  TabletView,
  isMobileOnly,
} from "react-device-detect";
import { EditOutlined, CheckOutlined, ReloadOutlined } from "@ant-design/icons";
import LinksButtons from "../../ui/LinksButtons";
import LinksButtonsActive from "../../ui/LinksButtonsActive";
import NodeArrowAnchors from "../../ui/NodeArrowAnchors";
import TextLoop from "react-text-loop";
const { Title } = Typography;

export default class Node extends Component {
  constructor(props) {
    super(props);
  }

  render = () => {
    const {
      alignSelf,
      isEndNode,
      isFirstOnPath,
      isLastOnPath,
      isOnPath,
      nodeId,
      offset,
      onGoTo,
      span,
    } = this.props;

    const onNodeGoTo = () => onGoTo(nodeId);

    if (!isOnPath) {
      return (
        <Col
          id={nodeId}
          onClick={onNodeGoTo}
          span={span}
          offset={offset}
          className={`node-wrapper not-on-path`}
          style={{
            zIndex: "100",
            alignSelf: `${alignSelf}`,
          }}
        >
          <div className={`node-card`}>
            <Title
              level={3}
              style={{ width: "85%", fontSize: "16px", marginBottom: "6px" }}
            >
              {this.props.nodeTitle}
            </Title>
            {this.props.children}
            <>
              <Divider
                orientation="left"
                plain
                style={{
                  margin: "15px 0 15px 0",
                  fontSize: "12px",
                  borderColor: "#ced4da",
                }}
              ></Divider>
              <Space wrap>
                <LinksButtons
                  links={this.props.links}
                  disabled="true"
                  size="small"
                />
              </Space>
            </>
          </div>
          <NodeArrowAnchors nodeId={nodeId} />
        </Col>
      );
    } else if (isOnPath && !isLastOnPath) {
      return (
        <Col
          id={`${nodeId}`}
          span={span}
          offset={offset}
          className="node-wrapper"
          style={{
            zIndex: "250",
            alignSelf: `${alignSelf}`,
          }}
        >
          <div
            className="node-card"
            style={{
              border: "2px solid #0D6739",
              borderRadius: "5px",
              boxShadow: "0 0 5px #dcdcdc",
            }}
          >
            <Tooltip placement="top" title="Wijzig deze stap">
              <Button
                shape="circle"
                onClick={onNodeGoTo}
                icon={<EditOutlined />}
                className="btn-edit"
              />
            </Tooltip>
            <Title
              level={3}
              style={{ width: "85%", fontSize: "16px", marginBottom: "6px" }}
            >
              {this.props.nodeTitle}
            </Title>
            {this.props.children}
            <>
              <Divider
                orientation="left"
                plain
                style={{
                  margin: "15px 0 10px 0",
                  fontSize: "11px",
                  borderColor: "#0D6739",
                }}
              >
                Uw keuze:
              </Divider>
              {!this.props.options && (
                <Space wrap>
                  <LinksButtonsActive
                    links={this.props.links}
                    linksActive={this.props.linksActive}
                    onNext={this.props.onNext}
                  />
                </Space>
              )}
            </>
          </div>
          <NodeArrowAnchors nodeId={nodeId} />
        </Col>
      );
    } else if (isEndNode) {
      return (
        <>
          <Col
            id={nodeId}
            span={span}
            offset={offset}
            className="node-wrapper"
            style={{ alignSelf: `${alignSelf}`, zIndex: "50000" }}
          >
            <div
              className="node-card"
              style={{
                border: "2px #0D6739 solid",
                backgroundColor: "#ebfbee",
                borderRadius: "5px",
                boxShadow: "6px 6px 10px #dcdcdc",
              }}
            >
              <div className="show-for-small">
                <Title level={3} style={{ width: "85%", fontSize: "18px" }}>
                  <Tag color="#0D6739">Einde</Tag>
                  {this.props.nodeTitle}
                </Title>
              </div>
              <BrowserView>
                <div className="node-links green" style={{ zIndex: "60000" }}>
                  <Title
                    level={4}
                    style={{
                      fontSize: "16px",
                      padding: "6px 0 0 0",
                      width: "85%",
                    }}
                  >
                    <Tag color="#0D6739">Einde</Tag>
                    {this.props.nodeTitle}
                  </Title>
                  {this.props.children}
                  {this.props.extraContent && <>{this.props.extraContent}</>}
                  {this.navigationButtons()}
                </div>
              </BrowserView>
              <TabletView>
                <div className="node-links green" style={{ zIndex: "60000" }}>
                  <Title
                    level={4}
                    style={{
                      fontSize: "16px",
                      padding: "6px 0 0 0",
                      width: "85%",
                    }}
                  >
                    <Tag color="#0D6739">Einde</Tag>
                    {this.props.nodeTitle}
                  </Title>
                  {this.props.children}
                  {this.props.extraContent && <>{this.props.extraContent}</>}
                  {this.navigationButtons()}
                </div>
              </TabletView>
              <CustomView condition={isMobileOnly}>
                <div className="node-links show-for-mobile green">
                  <Title
                    level={4}
                    style={{
                      fontSize: "24px",
                      padding: "6px 0",
                    }}
                  >
                    <CheckOutlined style={{ color: "#0D6739" }} />{" "}
                    {this.props.nodeTitle}
                  </Title>
                  {this.props.children}
                  {this.props.extraContent && <>{this.props.extraContent}</>}
                  {this.navigationButtons()}
                </div>
              </CustomView>
            </div>
            <NodeArrowAnchors nodeId={nodeId} />
          </Col>
        </>
      );
    } else {
      return (
        <Col
          id={nodeId}
          span={span}
          offset={offset}
          className="node-wrapper node-focused"
          style={{
            zIndex: "1000",
            alignSelf: `${alignSelf}`,
          }}
        >
          <div
            className="node-card"
            style={{
              border: "2px #882941 solid",
              background:
                "linear-gradient(188deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0.8909090909090909) 50%, rgba(243,233,236,1) 100%)",
              borderRadius: "5px",
              boxShadow: "4px 4px 10px #dcdcdc",
            }}
          >
            <div className="show-for-small">
              <Title level={3} style={{ width: "85%", fontSize: "18px" }}>
                {this.props.nodeTitle}
              </Title>
              <Divider
                orientation="left"
                plain
                style={{
                  margin: "15px 0 10px 0",
                  fontSize: "12px",
                  borderColor: "#882941",
                }}
              >
                Maak uw keuze
                <span className="show-for-small"> (zie onder):</span>
              </Divider>
            </div>
            <>
              <BrowserView>
                <div className="node-links">
                  <Title
                    level={4}
                    style={{
                      fontSize: "18px",
                      marginBottom: "15px",
                    }}
                  >
                    {this.props.nodeTitle}
                  </Title>
                  {!this.props.options && <>{this.props.children}</>}
                  {this.props.extraContent && <>{this.props.extraContent}</>}
                  {this.props.options && (
                    <div className="node-links">{this.props.options}</div>
                  )}
                  {!this.props.disableDefaultOptionsButtons && (
                    <>
                      <Divider
                        orientation="left"
                        plain
                        style={{
                          margin: "15px 0 10px 0",
                          fontSize: "12px",
                          borderColor: "#882941",
                        }}
                      >
                        <TextLoop interval={5000}>
                          <>Beschikbare opties:</>
                          <>Maak uw keuze om door te gaan:</>
                        </TextLoop>
                      </Divider>
                      <LinksButtons
                        links={this.props.links}
                        onNext={this.props.onNext}
                      />
                    </>
                  )}
                </div>
              </BrowserView>
              <TabletView>
                <div className="node-links">
                  <Title
                    level={4}
                    style={{
                      fontSize: "18px",
                      marginBottom: "15px",
                    }}
                  >
                    {this.props.nodeTitle}
                  </Title>
                  {!this.props.options && <>{this.props.children}</>}
                  {this.props.extraContent && <>{this.props.extraContent}</>}
                  {this.props.options && (
                    <div className="node-links">{this.props.options}</div>
                  )}
                  {!this.props.disableDefaultOptionsButtons && (
                    <>
                      <Divider
                        orientation="left"
                        plain
                        style={{
                          margin: "15px 0 10px 0",
                          fontSize: "12px",
                          borderColor: "#882941",
                        }}
                      >
                        <TextLoop interval={5000}>
                          <>Beschikbare opties:</>
                          <>Maak uw keuze om door te gaan:</>
                        </TextLoop>
                      </Divider>
                      <LinksButtons
                        links={this.props.links}
                        onNext={this.props.onNext}
                      />
                    </>
                  )}
                </div>
              </TabletView>
              <CustomView condition={isMobileOnly}>
                <TrackVisibility offset={75}>
                  {({ isVisible }) =>
                    isVisible ? (
                      <>
                        <div
                          className="node-links show-for-mobile"
                          style={{
                            background:
                              "linear-gradient(188deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0.8909090909090909) 50%, rgba(243,233,236,1) 100%)",
                            backgroundColor: "white",
                          }}
                        >
                          {!isFirstOnPath && <>{this.navigationButtons()}</>}
                          <Title
                            level={4}
                            style={{
                              fontSize: "24px",
                              padding: "6px 0",
                              marginTop: "4px",
                            }}
                          >
                            {this.props.nodeTitle}
                          </Title>
                          {!this.props.options && <>{this.props.children}</>}
                          {this.props.extraContent && (
                            <>{this.props.extraContent}</>
                          )}
                          {this.props.options && (
                            <div className="node-links">
                              <Title
                                level={4}
                                style={{
                                  fontSize: "18px",
                                  marginBottom: "15px",
                                }}
                              >
                                {this.props.nodeTitle}
                              </Title>
                              {this.props.options}
                            </div>
                          )}
                          <Divider
                            orientation="left"
                            plain
                            style={{
                              margin: "15px 0 10px 0",
                              fontSize: "12px",
                              borderColor: "#882941",
                            }}
                          >
                            <TextLoop interval={5000}>
                              <>Beschikbare opties:</>
                              <>Maak uw keuze om door te gaan:</>
                            </TextLoop>
                          </Divider>
                          <LinksButtons
                            links={this.props.links}
                            onNext={this.props.onNext}
                          />
                        </div>
                      </>
                    ) : null
                  }
                </TrackVisibility>
              </CustomView>
            </>
          </div>
          <NodeArrowAnchors nodeId={nodeId} />
        </Col>
      );
    }
  };

  navigationButtons = () => {
    return (
      <>
        <Tooltip placement="top" title="Herstart algoritme">
          <Button
            shape="circle"
            onClick={() => this.props.onReset()}
            icon={<ReloadOutlined />}
            className="btn-edit"
          />
        </Tooltip>
      </>
    );
  };
}
