import React from "react";
import { Card, Col } from "antd";
import LinksButtonActive3ie from "../../ui/LinksButtonsActive";
import NodeArrowAnchors from "./components/NodeArrowAnchors";
import NodeTypeIndicator from "../../ui/NodeTypeIndicator";
export default function NodeCardOnPath({
  nodeId,
  children,
  onNext,
  links,
  linksActive,
  span,
  offset,
  nodeType,
}) {
  return (
    <Col
      id={nodeId}
      key={nodeId}
      span={span}
      offset={offset}
      className="node-wrapper"
    >
      <Card
        title={<NodeTypeIndicator nodeType={nodeType} />}
        size="small"
        style={{
          border: "2px solid #8FB132",
          borderRadius: "4px",
          boxShadow:
            "rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px",
        }}
      >
        {children}
        <LinksButtonActive3ie
          links={links}
          linksActive={linksActive}
          onNext={onNext}
          size="small"
          color="#52c41a"
        />
      </Card>
      <NodeArrowAnchors nodeId={nodeId} />
    </Col>
  );
}
