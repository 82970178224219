import React, { Component } from "react";
import { Typography, Carousel } from "antd";
const { Title } = Typography;

export default class CarouselModalModuleBPlaatjesremmer extends Component {
  constructor(props) {
    super(props);
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
    this.carousel = React.createRef();
  }
  next() {
    this.carousel.next();
  }
  previous() {
    this.carousel.prev();
  }

  render() {
    const props = {};
    const { onClose } = this.props;
    const onCloseModal = () => this.onClose;
    return (
      <>
        <Carousel ref={(node) => (this.carousel = node)} {...props}>
          <div className="styles-katnh">
            <Title
              level={4}
              style={{
                fontFamily: "Roboto Slab",
                fontSize: "20px",
                textDecoration: "none",
                color: "#1d396e",
                paddingBottom: "20px",
              }}
            >
              U gebruikt een plaatjesremmer (1/2)
            </Title>

            <img
              src="/client/katnh/small_dots.svg"
              alt="divider"
              style={{ height: "2px" }}
            />
            <ul
              style={{
                marginBottom: "20px",
                fontSize: "15px",
                marginLeft: "1px",
              }}
            >
              <li style={{ listStyleType: "disc", padding: "3px 0" }}>
                Als U alleen aspirine gebruikt, kan dit vaak worden
                gecontinueerd
              </li>
              <li style={{ listStyleType: "disc", padding: "3px 0" }}>
                Bij ingrepen met zeer hoog bloedingsrisico wordt aspirine 7
                dagen voor de ingreep/ operatie tijdelijk gestopt
              </li>
              <li style={{ listStyleType: "disc", padding: "3px 0" }}>
                Bij gebruik van twee of meer plaatjesremmers wordt er altijd
                eerst overlegd met uw arts
              </li>
              <li style={{ listStyleType: "disc", padding: "3px 0" }}>
                plaatjesremmers worden meestal 24-48 uur na de ingreep/ operatie
                weer gestart
              </li>
            </ul>
          </div>
          <div className="styles-katnh">
            <Title
              level={4}
              style={{
                fontFamily: "Roboto Slab",
                fontSize: "20px",
                textDecoration: "none",
                color: "#1d396e",
                paddingBottom: "20px",
              }}
            >
              U gebruikt een plaatjesremmer (2/2)
            </Title>
            <img
              src="/client/katnh/small_dots.svg"
              alt="divider"
              style={{ height: "2px", marginBottom: "5px" }}
            />
            <ul
              style={{
                marginBottom: "20px",
                fontSize: "15px",
                marginLeft: "1px",
              }}
            >
              <li style={{ listStyleType: "disc", padding: "3px 0" }}>
                Stoppen met plaatjesremmers is meestal niet mogelijk na een
                recent hartinfarct, CVA, TIA, trombosebeen of longembolie
              </li>
              <li style={{ listStyleType: "disc", padding: "3px 0" }}>
                Dit betekent dat de ingreep/ operatie, indien mogelijk, enkele
                maanden moet worden uitgesteld
              </li>
            </ul>
          </div>
        </Carousel>
        <div className="button-close" onClick={this.previous}>
          <img
            src="/client/katnh/small_dots.svg"
            alt="divider"
            style={{ height: "2px" }}
          />
          <span
            style={{
              display: "block",
              padding: "0 0 8px 0",
              fontSize: "14px",
            }}
          >
            Vorige
          </span>
          <img
            src="/client/katnh/arrow_blue.svg"
            alt="arrow"
            style={{
              height: "17px",
              marginTop: "5px",
              transform: "rotate(180deg)",
            }}
            className="icon-blue"
          />
          <img
            src="/client/katnh/arrow_white.svg"
            alt="arrow"
            style={{
              height: "17px",
              marginTop: "5px",
              transform: "rotate(180deg)",
            }}
            className="icon-white"
          />
        </div>
        <div className="button-close" onClick={this.next}>
          <img
            src="/client/katnh/small_dots.svg"
            alt="divider"
            style={{ height: "2px" }}
          />
          <span
            style={{
              display: "block",
              padding: "0 0 8px 0",
              fontSize: "14px",
            }}
          >
            Volgende
          </span>
          <img
            src="/client/katnh/arrow_blue.svg"
            alt="arrow"
            style={{ height: "17px", marginTop: "5px" }}
            className="icon-blue"
          />
          <img
            src="/client/katnh/arrow_white.svg"
            alt="arrow"
            style={{ height: "17px", marginTop: "5px" }}
            className="icon-white"
          />
        </div>
      </>
    );
  }
}
