import React from "react";
import { Card, Col, Skeleton, Tag } from "antd";
import LinksButtons from "../../../../components/chart/links/button/LinksButtons";
import NodeArrowAnchors from "./components/NodeArrowAnchors";
import NodeTypeIndicator from "../../ui/NodeTypeIndicator";

export default function NodeCardWithGrid({
  children,
  disableDefaultOptionsButtons,
  extraContent,
  links,
  nodeId,
  nodeTitle,
  offset,
  nodeType,
  onNext,
  options,
  span,
  showAllNodes,
}) {
  const textNodeTitle = showAllNodes === true ? <>{nodeTitle}</> : false;
  return (
    <Col
      className="node-wrapper node-inactive"
      id={nodeId}
      key={nodeId}
      offset={offset}
      span={span}
      style={{ marginTop: "80px" }}
    >
      <Card
       title={<NodeTypeIndicator nodeType={nodeType} />}
        style={{
          borderWidth: "2px",
          borderRadius: "8px",
        }}
        size="small"
      >
        <>
          <Skeleton loading={!showAllNodes}>
            {!options && <>{children}</>}
            {extraContent && <>{extraContent}</>}
            {options && <>{options}</>}
            {!disableDefaultOptionsButtons && (
              <>
                <LinksButtons
                  links={links}
                  onNext={onNext}
                  disabled="true"
                  size="small"
                />
              </>
            )}
          </Skeleton>
        </>
      </Card>
      <NodeArrowAnchors nodeId={nodeId} />
    </Col>
  );
}
