import React, { useState } from "react";
import {
  Alert,
  Row,
  Col,
  Divider,
  Card,
  Select,
  Descriptions,
  List,
  Tag,
  Typography,
} from "antd";

const { Paragraph, Text, Title } = Typography;
const Option = Select.Option;

export const data = [
  {
    index: 0,
    title: "Hypertensief spoedgeval met retinopathie",
    tijdlijn: "Enkele uren, MAP -25%",
    keuze: "Labetalol",
    alternatief: "Nicardipine, Urapidil, Nitroprusside",
  },
  {
    index: 1,
    title: "Hypertensieve encefalopathie",
    tijdlijn: "Onmiddellijk, MAP -25%",
    keuze: "Labetalol, Nicardipine",
    alternatief: "Nitroprusside",
  },
  {
    index: 2,
    title:
      "Acuut herseninfarct  en bloeddruk ≥220/120 mmHg en geen indicatie tot trombolyse",
    tijdlijn: "1 uur, MAP -15%",
    keuze: "Labetalol, Nicardipine",
    alternatief: "Nitroprusside",
    alert:
      "Conform de aanbevelingen uit de richtlijn Herseninfarct en hersenbloeding 2019",
  },
  {
    index: 3,
    title:
      "Acuut herseninfarct met indicatie voor trombolyse en bloeddruk ≥185/110 mmHg (indien bloeddrukverlaging gewenst)",
    tijdlijn: "1 uur, MAP -15%",
    keuze: "Labetalol, Nicardipine",
    alternatief: "Nitroprusside",
    alert:
      "Conform de aanbevelingen uit de richtlijn Herseninfarct en hersenbloeding 2019",
  },
  {
    index: 4,
    title: "Acute hersenbloeding <6 uur onset en SBD ≥150 mmHg",
    tijdlijn: "Onmiddellijk, SBD <140 mmHg in 1 uur",
    keuze: "Nicardipine, Labetalol",
    alternatief: "",
    alert:
      "Conform de aanbevelingen uit de richtlijn Herseninfarct en hersenbloeding 2019",
  },
  {
    index: 5,
    title: "Acuut coronair syndroom",
    tijdlijn: "Onmiddellijk, SBD <140 mmHg",
    keuze: "Nitroglycerine in combinatie met orale bèta-blokkade",
    alternatief: "",
  },
  {
    index: 6,
    title: "Acuut hypertensief hartfalen met longoedeem",
    tijdlijn:
      "onmiddellijk, MAP -25% of SBD <140 mmHg indien dit eerder wordt bereikt",
    keuze: "Nitroglycerine (en Niet-Invasieve Ventilatie)",
    alternatief: "Nitroprusside",
  },
  {
    index: 7,
    title: "Acute aortadissectie/ruptuur",
    tijdlijn: "Onmiddellijk, SBD 100-110 mmHg EN hartslag 60/min",
    keuze: "Esmolol OF Metoprolol EN Clevidipine OF Nitroprusside",
    alternatief: "Labetalol Esmolol OF Metoprolol EN Nicardipine",
  },
  {
    index: 8,
    title:
      "Catecholaminerge overstimulatie (feochromocytoom, cocaine, (meth)amfetamine/XTC intoxicatie)",
    tijdlijn: "Onmiddelijk, SBP<160 mmHg",
    keuze:
      "Fentolamine OF Nicardipine (naast benzodiazepinen bij cocaïne, XTC of amfetamine intoxicatie)",
    alternatief: "",
  },
];

export default function SelectTableBehandelingSpecifiekeHypertensieveSpoedgevallen() {
  const [current, setCurrent] = useState(0);
  return (
    <>
      <Card>
        <Row>
          <Col xs={24}>
            <Title level={4}>Klinische presentatie:</Title>
            <Select
              size="large"
              style={{ width: "100%", marginTop: "8px" }}
              onChange={setCurrent}
              showSearch
              autoFocus
              defaultValue="Hypertensief spoedgeval met retinopathie"
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
              placeholder="Selecteer een DOAC.."
            >
              {data.map((item) => (
                <Option value={item.index} key={item.title} title={item.title}>
                  {item.title}
                </Option>
              ))}
            </Select>
            {data[current].alert ? (
              <Alert
                message={data[current].alert}
                type="info"
                showIcon
                style={{ margin: "8px 0" }}
              />
            ) : null}
          </Col>
          <Col xs={24}>
            <Divider />
            <Title level={4}>Informatie:</Title>
            <Descriptions
              column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
              bordered
              size="small"
              style={{ background: "white", marginTop: "16px" }}
            >
              <Descriptions.Item label="Tijdlijn en streefwaarde">
                {data[current].tijdlijn}
              </Descriptions.Item>
              <Descriptions.Item label="1ste keus">
                {data[current].keuze}
              </Descriptions.Item>
              <Descriptions.Item label="Alternatief">
                {data[current].alternatief}
              </Descriptions.Item>
            </Descriptions>
          </Col>
        </Row>
      </Card>
    </>
  );
}
