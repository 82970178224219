import React from "react";

import { Row, Col, Divider, Select, Descriptions, Typography } from "antd";

import ContentAlert from "../ui/ContentAlert";

const Title = Typography.Title;
const Option = Select.Option;

const steps = [
  {
    index: 0,
    title: "Acetylsalicylzuur (ASA), Carbasalaatcalcium",
    merknaam: "Aspirine ®, Ascal ®, Asasantin (asz + dirpyridamol) ®",
    chemischeKlasse: "Salicylaten",
    toedingen: "Oraal",
    doseringOplaad: "240-300 mg po/ 500 mg iv",
    doseringOnderhoud: "80 mg acetylsalicylzuur - 100 mg carbasalaatcalcium",
    nierfunctie: "Geen aanpassing",
    activatie: "-",
    tijd: "2 uur",
    duur: "5-7 dagen",
  },
  {
    index: 1,
    title: "Dipyridamol",
    merknaam: "Persantin ®",
    chemischeKlasse: "Remmer fosfodi-ësterase/remming",
    toedingen: "Oraal",
    doseringOplaad: "-",
    doseringOnderhoud: "150-200 mg 2 dd",
    nierfunctie: "Geen aanpassing",
    activatie: "-",
    tijd: "5-7 uur",
    duur: "24 uur",
  },
  {
    index: 2,
    title: "Clopidogrel",
    merknaam: "Plavix ®, Grepid ®, Iscover ®",
    chemischeKlasse: "ADP-receptorantagonisten / P2Y12-remmer",
    toedingen: "Oraal",
    doseringOplaad: "300-600 mg po",
    doseringOnderhoud: "75 mg",
    nierfunctie:
      "Alleen in overleg met nefroloog. Bij creatineklaring kleiner dan 10 ml/min aanpassen van de dosis of het doseerinterval",
    activatie: "Prodrug, variabel lever metabolisme",
    tijd: "12 uur",
    duur: "5-7 dagen",
  },
  {
    index: 3,
    title: "Prasugrel",
    merknaam: "Efient ®",
    chemischeKlasse: "ADP-receptorantagonisten / P2Y12-remmer",
    toedingen: "Oraal",
    doseringOplaad: "60 mg po",
    doseringOnderhoud: "10 mg",
    nierfunctie:
      "Alleen in overleg met nefroloog. Bij creatineklaring kleiner dan 10 ml/min aanpassen van de dosis of het doseerinterval",
    activatie: "Prodrug, variabel lever metabolisme",
    tijd: "16-18 uur",
    duur: "5-7 dagen",
  },
  {
    index: 4,
    title: "Ticagrelor",
    merknaam: "Brilique ®",
    chemischeKlasse: "ADP-receptorantagonisten / P2Y12-remmer",
    toedingen: "Oraal",
    doseringOplaad: "180 mg po",
    doseringOnderhoud: "90 mg 2 dd **",
    nierfunctie:
      "Alleen in overleg met nefroloog. Bij creatineklaring kleiner dan 10 ml/min aanpassen van de dosis of het doseerinterval",
    activatie: "Zowel zelf als metaboliet actief",
    tijd: "18-26 uur ***",
    duur: "3-5 dagen",
    alert:
      "** Bij bepaalde patiëntencategorieën wordt na een jaar overgestapt op  2 x 60 mg.",
    alertSecond: "*** <24 uur na inname trombocytentransfusie niet effectief.",
  },
];

class Stepper extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      current: 0,
    };
  }

  onChange = (value) => {
    const current = value;
    this.setState({ current });
  };

  render() {
    const { current } = this.state;

    return (
      <div className="wrapper-interactive">
        <Row>
          <Col xs={24}>
            <Title level={4}>TAR:</Title>
            <Select
              size="large"
              style={{ width: "100%", marginTop: "8px" }}
              onChange={this.onChange}
              showSearch
              autoFocus
              defaultValue="Acetylsalicylzuur (ASA), Carbasalaatcalcium"
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
              placeholder="Selecteer een TAR.."
            >
              {steps.map((item) => (
                <Option value={item.index} key={item.title} title={item.title}>
                  {item.title}
                </Option>
              ))}
            </Select>
          </Col>
          <Col xs={24}>
            <Divider />
            <Title level={4}>Informatie:</Title>
            <Descriptions
              column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
              bordered
              size="small"
              style={{ background: "white", marginTop: "16px" }}
            >
              <Descriptions.Item label="Merknaam">
                {steps[current].merknaam}
              </Descriptions.Item>
              <Descriptions.Item label="Chemische klasse">
                {steps[current].chemischeKlasse}
              </Descriptions.Item>
              <Descriptions.Item label="Toediening">
                {steps[current].toedingen}
              </Descriptions.Item>
              <Descriptions.Item label="Dosering oplaad">
                {steps[current].doseringOplaad}
              </Descriptions.Item>
              <Descriptions.Item label="Dosering onderhoud">
                {steps[current].doseringOnderhoud}
              </Descriptions.Item>
              <Descriptions.Item label="Nierfunctie stadium 5 (MDRD < 15 mL/min">
                {steps[current].nierfunctie}
              </Descriptions.Item>
              <Descriptions.Item label="Tijd vanaf toediening minder effect plaatjestransfusie *">
                {steps[current].tijd}
              </Descriptions.Item>
              <Descriptions.Item label="Duur effect">
                {steps[current].duur}
              </Descriptions.Item>
            </Descriptions>
            <ContentAlert
              message="* Geneesmiddel of actieve metaboliet is nog >25% van pieklevel aanwezig in plasma."
              type="info"
              showIcon
            />
            {steps[current].alert ? (
              <ContentAlert
                message={steps[current].alert}
                type="info"
                showIcon
              />
            ) : null}
            {steps[current].alertSecond ? (
              <ContentAlert
                message={steps[current].alertSecond}
                type="info"
                showIcon
              />
            ) : null}
          </Col>
        </Row>
      </div>
    );
  }
}

export default Stepper;
