import { Button, Col, Row, Typography, Tooltip } from "antd";
import ChartBoink0JaarTmGroep8 from "../../../../src/client/boink/0-jaar-tm-groep-8.json";
import { FullscreenOutlined } from '@ant-design/icons';
import * as React from 'react';
export default {
  Button,
  Col,
  Row,
  Typography,
  Tooltip,
  ChartBoink0JaarTmGroep8,
  FullscreenOutlined,
  React
};