import React, { useState } from "react";
import {
  Card,
  List,
  Statistic,
  Typography,
  Popover,
  Button,
  Drawer,
} from "antd";
import Chadsvasc from "./Chadsvasc.js";
const { Title, Text } = Typography;

const gridStyle = {
  width: "50%",
  textAlign: "center",
};

const Risicofactoren = () => (
  <List size="small" bordered>
    <List.Item>Atriumfibrilleren</List.Item>
    <List.Item>Linker ventrikel ejectiefractie &lt; 35%</List.Item>
    <List.Item>
      Voorgeschiedenis van een doorgemaakte trombo-embolie (cerebro vasculair
      accident, transient ischemic attack, of arteriële embolie)
    </List.Item>
  </List>
);

const TableTromboRisico = () => {
  const [open, setOpen] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };
  return (
    <>
      <Title level={3}>Hoog risico</Title>
      <Card style={{ margin: "20px 0" }}>
        <Card.Grid style={gridStyle}>
          <Statistic
            title="Hoog risico voor arteriële trombo-embolie"
            value={"> 10% per jaar"}
            valueStyle={{ color: "#cf1322" }}
          />
        </Card.Grid>
        <Card.Grid style={gridStyle}>
          <Statistic
            title="Hoog risico voor veneuze trombo-embolie"
            value={"> 10% per maand"}
            valueStyle={{ color: "#cf1322" }}
          />
        </Card.Grid>
      </Card>
      <Title level={3}>Klinische status hoog risico</Title>
      <List size="large" bordered>
        <List.Item>
          Geïsoleerd atriumfibrilleren, zonder klepgebrek, met CHA
          <sup>2</sup>DS
          <sup>2</sup>-VASc-score: <Text strong>8-9</Text>
          <br />
          <a onClick={showDrawer}>
            Bekijk CHA
            <sup>2</sup>DS
            <sup>2</sup>-VASc-score
          </a>
          <Drawer
            placement="right"
            onClose={onClose}
            open={open}
            drawerStyle={{ backgroundColor: "white" }}
            className="modal-katnh styles-katnh"
            destroyOnClose
          >
            <div style={{ color: "white" }}>
              <img
                src="/client/katnh/small_dots.svg"
                alt="divider"
                style={{ height: "3px", margin: "16px 0" }}
              />
              <Chadsvasc />
              <img
                src="/client/katnh/small_dots.svg"
                alt="divider"
                style={{ height: "3px", margin: "16px 0" }}
              />
            </div>
          </Drawer>
        </List.Item>
        <List.Item>
          Geïsoleerd atriumfibrilleren met reumatische hartziekte
        </List.Item>
        <List.Item>
          Atriumfibrilleren met mechanische hartklep of recent (&lt;6 maanden)
          herseninfarct/TIA ongeacht de CHA<sup>2</sup>DS<sup>2</sup>
          -VASc-score.
          <br />
          <a onClick={showDrawer}>
            Bekijk CHA
            <sup>2</sup>DS
            <sup>2</sup>-VASc-score
          </a>
        </List.Item>
        <List.Item>
          Bij biokleppen (inclusief TAVI) &lt; 3 maanden geleden geplaatst,
          alleen op indicatie van de operateur
        </List.Item>
        <List.Item>
          Mechanische hartklep in mitralis, tricuspidalis of pulmonalis positie
        </List.Item>
        <List.Item>
          Aortamechanoprothesen met extra{" "}
          <Popover content={<Risicofactoren />} title="Risicofactoren">
            {" "}
            <a>risicofactor</a>
          </Popover>{" "}
          in overleg met de operateur
        </List.Item>
        <List.Item>
          Hartklepprothese (inclusief{" "}
          <Popover
            content={
              "De eerste drie maanden na chirurgie een bioklep beschouwen als zijnde ‘mechanoprothese’."
            }
            title="Bioklep"
          >
            <a href="#">bioklep</a>
          </Popover>
          ) met extra{" "}
          <Popover content={<Risicofactoren />} title="Risicofactoren">
            {" "}
            <a>risicofactor</a>
          </Popover>
        </List.Item>
        <List.Item>
          Mechanische hartklep oud model: caged ball, tilting disc
          (Starr-Edwards, Björk-Shiley)
        </List.Item>
        <List.Item>Intracardiale trombus</List.Item>
        <List.Item>
          &lt; 3 maanden na een veneuze trombo-embolie (VTE) (eerste episode of
          recidief)
        </List.Item>
        <List.Item>Recidief VTE onder antistolling</List.Item>
        <List.Item>
          Recidief VTE bij eerdere tijdelijke onderbreking van antistolling (ook{" "}
          {">"} 3 maanden laatste event)
        </List.Item>
        <List.Item>
          Recidiverend herseninfarct/TIA bij symptomatische ACI stenose
        </List.Item>
      </List>
      <img
        src="/client/katnh/small_dots.svg"
        alt="divider"
        style={{ height: "3px", margin: "16px 0" }}
      />
      <Title level={3}>Laag risico</Title>
      <Card style={{ margin: "20px 0" }}>
        <Card.Grid style={gridStyle}>
          <Statistic
            title="Laag risico voor arteriële trombo-embolie"
            value={"< 10% per jaar"}
            valueStyle={{ color: "#3f8600" }}
          />
        </Card.Grid>
        <Card.Grid style={gridStyle}>
          <Statistic
            title="Laag risico voor veneuze trombo-embolie"
            value={"< 10% per maand"}
            valueStyle={{ color: "#3f8600" }}
          />
        </Card.Grid>
      </Card>
      <Title level={3}>Klinische status laag risico</Title>
      <List size="large" bordered>
        <List.Item>
          Geïsoleerd atriumfibrilleren, CHA<sup>2</sup>DS<sup>2</sup>
          -VASc-score: <Text strong>0-7</Text> en geen recent (&lt; 6 maanden)
          herseninfarct/ TIA
          <br />
          <a onClick={showDrawer}>
            Bekijk CHA
            <sup>2</sup>DS
            <sup>2</sup>-VASc-score
          </a>
        </List.Item>
        <List.Item>
          Mechanische hartklep in aortapositie &gt; 3 maanden geleden geplaatst
          zonder extra
          <Popover
            content={<Risicofactoren />}
            title="Extra risicofactoren hartklepprothese"
          >
            {" "}
            <a>risicofactor</a>
          </Popover>
        </List.Item>
        <List.Item>Bioklep {">"} 3 maanden geleden geplaatst</List.Item>
        <List.Item>
          Recidiverend TIA/herseninfarct zonder cardiale emboliebron
        </List.Item>
        <List.Item>Eenmalig TIA/herseninfarct</List.Item>
        <List.Item>
          &gt; 3 maanden na eerste of recidief VTE en geen recidief onder
          antistolling
        </List.Item>
      </List>
    </>
  );
};

export default TableTromboRisico;
