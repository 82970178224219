import React from "react";
import { Card, Col, Divider, Tag } from "antd";
import LinksButtons from "../links/button/LinksButtons";
import TextLoop from "react-text-loop";
import NodeArrowAnchors from "./components/NodeArrowAnchors";

export default function NodeCardActive({
  children,
  disableDefaultOptionsButtons,
  extraContent,
  links,
  nodeId,
  nodeTitle,
  nodeType,
  onNext,
  options,
  language,
  showNodeType
}) {
  const textDivider =
    language === "nl" ? "Beschikbare opties" : "Available options";
  const textDividerAnimation =
    language === "nl"
      ? "Selecteer een optie om door te gaan"
      : "Select an option to proceed";

  return (
    <Col
      className="wrapper-step"
      id={nodeId}
      key={nodeId}
      offset={1}
      span={22}
      style={{ position: "relative" }}
    >
      <Card
        title={nodeTitle}
        style={{
          border: "2px solid #EBD247",
          borderRadius: "4px",
          boxShadow:
            "rgba(17, 17, 26, 0.1) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 48px",
          cursor: "help",
        }}
        className="node-active"
      >
        {showNodeType && <><Tag>{nodeType}</Tag></>}
        {!options && <>{children}</>}
        {extraContent && <>{extraContent}</>}
        {options && <>{options}</>}
        {!disableDefaultOptionsButtons && (
          <>
            <Divider
              orientation="left"
              plain
              style={{
                margin: "16px 0 10px 0",
                fontSize: "12px",
                borderColor: "#ced4da",
              }}
            >
              <TextLoop interval={5000}>
                <>{textDivider}:</>
                <>{textDividerAnimation}:</>
              </TextLoop>
            </Divider>
            <LinksButtons links={links} onNext={onNext} />
          </>
        )}
      </Card>
      <NodeArrowAnchors nodeId={nodeId} />
    </Col>
  );
}
