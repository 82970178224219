import React, { useState } from "react";
import { Modal, Button, Typography } from "antd";
import { useDebounceFn } from "ahooks";

const { Link, Paragraph } = Typography;

export default function ContentModalWithButtons({
  content,
  key,
  links,
  onNext,
  title,
  trigger,
  width,
}) {
  const [showDrawer, setShowDrawer] = useState(false);
  const { run } = useDebounceFn(
    (k) => {
      onNext(k);
    },
    {
      wait: 250,
    }
  );

  return (
    <>
      <Paragraph>
        <Link
          type="link"
          onClick={() => {
            setShowDrawer(true);
          }}
          style={{ paddingLeft: "3px" }}
        >
          {trigger}
        </Link>
      </Paragraph>
      <Modal
        className="content-modal-with-buttons"
        style={{
          border: "2px solid #EBD247",
          borderRadius: "4px",
          padding: "0",
        }}
        footer={[
          <>
            {links.link.map((item) => (
              <Button
                type="primary"
                key={key}
                onClick={() => {
                  run(item.nodeId);
                }}
              >
                {item.text}
              </Button>
            ))}
          </>,
        ]}
        onCancel={() => {
          setShowDrawer(false);
        }}
        onClose={() => {
          setShowDrawer(false);
        }}
        title={title}
        visible={showDrawer}
        width={width}
      >
        {content}
      </Modal>
    </>
  );
}
