import React from "react";

import { Row, Col, Divider, Select, Descriptions, Typography } from "antd";
import ContentAlert from "../ui/ContentAlert";

const Title = Typography.Title;
const Option = Select.Option;

const steps = [
  {
    index: 0,
    categorie: "Eerste",
    details0: "-",
  },
  {
    index: 1,
    categorie: "Recidief",
    details1: "-",
  },
  {
    index: 2,
    categorie: "Bij maligniteit",
    details2: "-",
  },
];

export const IndicatieEersteDvt = () => (
  <>
    <Title>
      Uitgelokt na tijdelijke risicofactor (bv. na operatie, immobilisatie,
      vliegreis)
    </Title>
    <Descriptions
      bordered
      border
      size="small"
      style={{ background: "white", marginBottom: "15px" }}
    >
      <Descriptions.Item span={3} label="Standaard behandelduur">
        3 maanden
      </Descriptions.Item>
    </Descriptions>
    <Divider />
    <Title>
      Niet uitgelokt, zonder tijdelijke risicofactor ('idiopathisch')
    </Title>

    <Descriptions
      bordered
      border
      size="small"
      style={{ background: "white", marginBottom: "15px" }}
    >
      <Descriptions.Item span={3} label="Standaard behandelduur">
        Minimaal 3 maanden
      </Descriptions.Item>
      <Descriptions.Item span={3} label="Afwegingen">
        Continueren afhankelijk van bloedingsrisico
      </Descriptions.Item>
    </Descriptions>
    <Title level={4} style={{ paddingLeft: "15px", color: "white" }}>
      Laag bloedingsrisico
    </Title>
    <Descriptions
      bordered
      border
      size="small"
      style={{ background: "white", marginBottom: "15px" }}
    >
      <Descriptions.Item span={3} label="Standaard behandelduur">
        Onbepaalde tijd
      </Descriptions.Item>
      <Descriptions.Item span={3} label="Afwegingen">
        Jaarlijks bloedingsrisico heroverwegen
      </Descriptions.Item>
    </Descriptions>
    <Title level={4} style={{ paddingLeft: "15px", color: "white" }}>
      Hoog bloedingsrisico
    </Title>
    <Descriptions
      bordered
      border
      size="small"
      style={{ background: "white", marginBottom: "15px" }}
    >
      <Descriptions.Item span={3} label="Standaard behandelduur">
        3 maanden
      </Descriptions.Item>
    </Descriptions>
  </>
);

export const IndicatieRecidiefDvt = () => (
  <>
    <Title>Uitgelokt na tijdelijke risicofactor</Title>
    <Descriptions
      bordered
      border
      size="small"
      style={{ background: "white", marginBottom: "15px" }}
    >
      <Descriptions.Item span={3} label="Standaard behandelduur">
        Onbeperkte tijd
      </Descriptions.Item>
      <Descriptions.Item span={3} label="Afwegingen">
        Bij hoog bloedingsrisico ten minste 3 maanden
      </Descriptions.Item>
    </Descriptions>
    <Divider />
    <Title>Niet uitgelokt, zonder tijdelijke risicofactor</Title>
    <Descriptions
      bordered
      border
      size="small"
      style={{ background: "white", marginBottom: "15px" }}
    >
      <Descriptions.Item span={3} label="Standaard behandelduur">
        Onbepaalde tijd
      </Descriptions.Item>
      <Descriptions.Item span={3} label="Afwegingen">
        Bij hoog bloedingsrisico ten minste 3 maanden
      </Descriptions.Item>
    </Descriptions>
  </>
);

export const IndicatieMaligniteit = () => (
  <>
    <Descriptions bordered border size="small" style={{ background: "white" }}>
      <Descriptions.Item span={3} label="Standaard behandelduur">
        6 maanden DOAC of LMWH
      </Descriptions.Item>
      <Descriptions.Item span={3} label="Afwegingen">
        Bij hoog bloedingsrisico ten minste 3 maanden
      </Descriptions.Item>
    </Descriptions>
    <ContentAlert
      type="info"
      message="Zolang er sprake is van actieve maligniteit dan wel chemotherapie of adjuvante behandeling na 6 maanden de antistolling continueren met DOAC, LMWH of VKA"
    />
  </>
);

class Stepper extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      current: 0,
    };
  }

  onChange = (value) => {
    const current = value;
    this.setState({ current });
  };

  render() {
    const { current } = this.state;

    return (
      <div className="wrapper-interactive">
        <Row>
          <Col xs={24}>
            <Title level={4}>Selecteer indicatie {this.props.title}:</Title>
            <Select
              size="large"
              style={{ width: "100%", marginTop: "8px" }}
              onChange={this.onChange}
              showSearch
              defaultValue="Eerste"
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
              placeholder="Selecteer indicatie"
            >
              {steps.map((item) => (
                <Option
                  value={item.index}
                  key={item.categorie}
                  title={item.categorie}
                >
                  {item.categorie}
                </Option>
              ))}
            </Select>
          </Col>
          <Col xs={24}>
            <Divider />
            <Title level={4}>Details:</Title>
            {steps[current].details0 ? <IndicatieEersteDvt /> : null}
            {steps[current].details1 ? <IndicatieRecidiefDvt /> : null}
            {steps[current].details2 ? <IndicatieMaligniteit /> : null}
          </Col>
        </Row>
      </div>
    );
  }
}

export default Stepper;
