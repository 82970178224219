import React, { Component } from "react";
import _ from "lodash";
import { Col, Button, Tooltip, Space, Typography, Card, Divider } from "antd";
import TrackVisibility from "react-on-screen";
import { BrowserView, CustomView, isMobileOnly } from "react-device-detect";
import LinksButtons from "../../chart/links/button/LinksButtons";
import {
  CheckSquareOutlined,
  CheckOutlined,
  EditOutlined,
  UndoOutlined,
} from "@ant-design/icons";

const { Title } = Typography;

export default class Node extends Component {
  render = () => {
    const {
      alignSelf,
      isEndNode,
      isLastOnPath,
      isOnPath,
      nodeId,
      offset,
      onGoTo,
      span,
    } = this.props;

    const onNodeGoTo = () => onGoTo(nodeId);

    if (!isOnPath) {
      return (
        <Col
          id={nodeId}
          span={span}
          offset={offset}
          className="node-wrapper"
          style={{ alignSelf: `${alignSelf}` }}
        >
          <div
            id={`${nodeId}-top`}
            style={{
              position: "absolute",
              top: "0px",
              left: "0px",
              width: "70px",
              height: "1px",
            }}
          />
          <Card
            title={this.props.nodeTitle}
            style={{ borderWidth: "2px", opacity: "0.75" }}
            size="small"
            className="node-inactive"
          >
            {this.props.children}
            {this.props.extraContent && <>{this.props.extraContent}</>}
            {/* {!this.props.disableDefaultOptionsButtons && (
              <>
                <Space wrap>{this.linksButtonsActive()}</Space>
              </>
            )} */}
            <Space wrap>{this.linksButtonsActive()}</Space>
          </Card>
          <div
            id={`${nodeId}-bottom`}
            style={{
              position: "absolute",
              bottom: "0px",
              left: "0px",
              width: "70px",
              height: "0px",
            }}
          />
        </Col>
      );
    } else if (!isLastOnPath) {
      return (
        <Col
          id={nodeId}
          span={span}
          offset={offset}
          className="node-wrapper"
          style={{ alignSelf: `${alignSelf}` }}
        >
          <div
            id={`${nodeId}-top`}
            style={{
              position: "absolute",
              top: "0px",
              left: "0px",
              width: "70px",
              height: "1px",
            }}
          />
          <Card
            title={this.props.nodeTitle}
            size="small"
            style={{
              border: "2px solid #40c057",
              borderRadius: "4px",
              boxShadow:
                "rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px",
            }}
            extra={
              <>
                <Tooltip placement="top" title="Edit this step">
                  <Button size="small" onClick={onNodeGoTo}>
                    <EditOutlined /> Edit
                  </Button>
                </Tooltip>
              </>
            }
          >
            {this.props.children}
            {this.props.extraContent && <>{this.props.extraContent}</>}
            {this.props.options && (
              <>
                <Divider
                  orientation="left"
                  plain
                  style={{
                    margin: "0px 0 8px 0",
                    fontSize: "10px",
                    borderColor: "#40c057",
                  }}
                >
                  Selected:
                </Divider>
                <Space wrap>{this.linksButtonsActiveSingle()}</Space>
              </>
            )}
            {!this.props.disableDefaultOptionsButtons && (
              <>
                <Divider
                  orientation="left"
                  plain
                  style={{
                    margin: "0px 0 8px 0",
                    fontSize: "10px",
                    borderColor: "#40c057",
                  }}
                >
                  Selected:
                </Divider>
                <Space wrap>{this.linksButtonsActive()}</Space>
              </>
            )}
          </Card>
          <div
            id={`${nodeId}-bottom`}
            style={{
              position: "absolute",
              bottom: "0px",
              left: "0px",
              width: "70px",
              height: "0px",
            }}
          />
        </Col>
      );
    } else if (isEndNode) {
      return (
        <>
          <Col
            id={nodeId}
            span={span}
            offset={offset}
            className="node-wrapper"
            style={{ alignSelf: `${alignSelf}`, zIndex: "1000" }}
          >
            <div
              id={`${nodeId}-top`}
              style={{
                position: "absolute",
                top: "0px",
                left: "0px",
                width: "120px",
                height: "1px",
              }}
            />
            <Card
              title={
                <>
                  <CheckOutlined style={{ color: "#40c057" }} />{" "}
                  {this.props.nodeTitle}
                </>
              }
              style={{
                border: "4px solid #40c057",
                borderRadius: "4px",
                boxShadow:
                  "rgba(17, 17, 26, 0.1) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 48px",
                cursor: "help",
              }}
            >
              <BrowserView>
                {this.props.children}
                {this.props.extraContent && <>{this.props.extraContent}</>}
                <Tooltip placement="top" title="Restart this chart">
                  <Button size="small" onClick={() => this.props.onReset()}>
                    <UndoOutlined /> Restart
                  </Button>
                </Tooltip>
              </BrowserView>
              <CustomView condition={isMobileOnly}>
                <TrackVisibility offset={50}>
                  {({ isVisible }) =>
                    isVisible ? (
                      <>
                        <div
                          style={{
                            position: "fixed",
                            left: "0",
                            bottom: "0",
                            backgroundColor: "white",
                            width: "100%",
                            padding: "24px",
                            boxShadow: "-6px -6px 10px rgba(17, 17, 26, 0.1)",
                            borderTop: "4px solid #40c057",
                            zIndex: "5000",
                          }}
                        >
                          <Title level={4}>
                            <CheckOutlined style={{ color: "#40c057" }} />{" "}
                            {this.props.nodeTitle}
                          </Title>
                          {this.props.children}
                          {this.props.extraContent && (
                            <>{this.props.extraContent}</>
                          )}
                          <Tooltip placement="top" title="Restart this chart">
                            <Button
                              size="small"
                              onClick={() => this.props.onReset()}
                            >
                              <UndoOutlined /> Restart
                            </Button>
                          </Tooltip>
                        </div>
                      </>
                    ) : null
                  }
                </TrackVisibility>
              </CustomView>
            </Card>
            <div
              id={`${nodeId}-bottom`}
              style={{
                position: "absolute",
                bottom: "0px",
                left: "0px",
                width: "70px",
                height: "0px",
              }}
            />
          </Col>
        </>
      );
    } else {
      return (
        <Col
          id={nodeId}
          span={span}
          offset={offset}
          className="node-wrapper"
          style={{ zIndex: "1000", alignSelf: `${alignSelf}` }}
        >
          <div
            id={`${nodeId}-top`}
            style={{
              position: "absolute",
              top: "0px",
              left: "0px",
              width: "70px",
              height: "1px",
            }}
          />
          <Card
            title={this.props.nodeTitle}
            style={{
              border: "2px solid #EBD247",
              borderRadius: "4px",
              cursor: "crosshair",
              boxShadow:
                "rgba(17, 17, 26, 0.1) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 48px",
            }}
            className="node-active"
          >
            <BrowserView>
              {!this.props.options && <>{this.props.children}</>}
              {this.props.extraContent && <>{this.props.extraContent}</>}
              {this.props.options && <>{this.props.options}</>}
              {!this.props.disableDefaultOptionsButtons && (
                <>
                  <Divider
                    orientation="left"
                    plain
                    style={{
                      margin: "0px 0 16px 0",
                      fontSize: "12px",
                      borderColor: "#ced4da",
                    }}
                  >
                    Available options:
                  </Divider>
                  <LinksButtons
                    links={this.props.links}
                    onNext={this.props.onNext}
                  />
                </>
              )}
            </BrowserView>
            {/* {!this.props.useStartDemoProp === true this.props.startDemo > 0 && ( */}
            <CustomView condition={isMobileOnly}>
              {!this.props.useStartDemoProp && (
                <TrackVisibility offset={50}>
                  {({ isVisible }) =>
                    isVisible ? (
                      <>
                        <div
                          style={{
                            position: "fixed",
                            left: "0",
                            bottom: "0",
                            backgroundColor: "white",
                            width: "100%",
                            padding: "24px",
                            boxShadow: "-6px -6px 10px rgba(17, 17, 26, 0.1)",
                            borderTop: "2px solid #EBD247",
                          }}
                        >
                          <Title level={4}>{this.props.nodeTitle}</Title>
                          {!this.props.options && <>{this.props.children}</>}
                          {this.props.extraContent && (
                            <>{this.props.extraContent}</>
                          )}
                          {this.props.options && <>{this.props.options}</>}
                          {!this.props.disableDefaultOptionsButtons && (
                            <>
                              <Divider
                                orientation="left"
                                plain
                                style={{
                                  margin: "16px 0 16px 0",
                                  fontSize: "12px",
                                  borderColor: "#ced4da",
                                }}
                              >
                                Available options:
                              </Divider>
                              <LinksButtons
                                links={this.props.links}
                                onNext={this.props.onNext}
                              />
                            </>
                          )}
                        </div>
                      </>
                    ) : null
                  }
                </TrackVisibility>
              )}
              {this.props.useStartDemoProp && this.props.startDemo > 0 && (
                <TrackVisibility offset={50}>
                  {({ isVisible }) =>
                    isVisible ? (
                      <>
                        <div
                          style={{
                            position: "fixed",
                            left: "0",
                            bottom: "0",
                            backgroundColor: "white",
                            width: "100%",
                            padding: "24px",
                            boxShadow: "-6px -6px 10px rgba(17, 17, 26, 0.1)",
                            borderTop: "2px solid #EBD247",
                          }}
                        >
                          <Title level={4}>{this.props.nodeTitle}</Title>
                          {!this.props.options && <>{this.props.children}</>}
                          {this.props.extraContent && (
                            <>{this.props.extraContent}</>
                          )}
                          {this.props.options && <>{this.props.options}</>}
                          {!this.props.disableDefaultOptionsButtons && (
                            <>
                              <Divider
                                orientation="left"
                                plain
                                style={{
                                  margin: "16px 0 16px 0",
                                  fontSize: "12px",
                                  borderColor: "#ced4da",
                                }}
                              >
                                Available options:
                              </Divider>
                              <LinksButtons
                                links={this.props.links}
                                onNext={this.props.onNext}
                              />
                            </>
                          )}
                        </div>
                      </>
                    ) : null
                  }
                </TrackVisibility>
              )}
            </CustomView>
            {/* )} */}
          </Card>
          <div
            id={`${nodeId}-bottom`}
            style={{
              position: "absolute",
              bottom: "0px",
              left: "0px",
              width: "70px",
              height: "0px",
            }}
          />
        </Col>
      );
    }
  };

  // linksButtons = () => {
  //   return _.map(this.props.links, (v, k) => {
  //     return (
  //       <Button key={k} type="primary" onClick={() => this.props.onNext(k)}>
  //         {v}
  //       </Button>
  //     );
  //   });
  // };

  linksButtonsActive = () => {
    const { onNext, linksActive } = this.props;
    return _.map(this.props.links, (v, k) => {
      const buttonActive = {
        backgroundColor: "#ebfbee",
        color: "#40c057",
        borderColor: "#40c057",
      };
      const buttonInactive = {};
      const buttonStyle = k === linksActive ? buttonActive : buttonInactive;
      const buttonIcon = k === linksActive ? <CheckSquareOutlined /> : null;
      if (onNext) {
        return (
          <>
            <Button
              type="primary"
              icon={buttonIcon}
              key={`${v}_${k}`}
              disabled
              style={buttonStyle}
            >
              {v}
            </Button>
          </>
        );
      }
    });
  };

  linksButtonsActiveSingle = () => {
    const { onNext, linksActive } = this.props;
    return _.map(this.props.links, (v, k) => {
      const buttonActive = {
        backgroundColor: "#ebfbee",
        color: "#40c057",
        borderColor: "#40c057",
      };
      const buttonInactive = {
        display: "none",
      };
      const buttonStyle = k === linksActive ? buttonActive : buttonInactive;
      const className = k === linksActive ? "active" : "inactive";
      const buttonIcon = k === linksActive ? <CheckSquareOutlined /> : null;
      if (onNext) {
        return (
          <>
            <Button
              type="primary"
              icon={buttonIcon}
              key={`${v}_${k}`}
              disabled
              style={buttonStyle}
              className={className}
            >
              {v}
            </Button>
          </>
        );
      }
    });
  };
}
