import React from "react";

import ABCDEApproach from "../../../client/examples/widgets/abcde-approach";
import IVaccess from "../../../client/examples/widgets/iv-access";
import Prevalentie from "../../../client/examples/widgets/Prevalentie";

/* eslint-disable react/display-name */
export const ClientExamplesComponents = {
  abcdeApproach: () => <ABCDEApproach />,
  IVaccess: () => <IVaccess />,
  Prevalentie: () => <Prevalentie />,
};
