import React from "react";
import { Typography, Popover, Tag } from "antd";
import { StaticImage } from "gatsby-plugin-image";
import { ExportOutlined } from "@ant-design/icons";

const { Text } = Typography;

const ContentLinkExternalKf = ({ title, url }) => {
  return (
    <>
      <Popover
        color="#02a8e1"
        placement="topRight"
        content={
          <div>
            <a
              href={url}
              target="_blank"
              rel="noreferrer noopener"
              style={{
                color: "#fff",
                marginBottom: "24px",
              }}
            >
              <>
                <StaticImage
                  src="../../../images/logo/logoKF.png"
                  alt="Logo KF"
                  width={150}
                  style={{ marginRight: "8px", top: "-2px" }}
                />
                {"> "}
              </>
              {title}
            </a>
            <br />
            <Text
              style={{ fontSize: "12px", color: "#fff", marginRight: "10px" }}
            >
              <ExportOutlined style={{ transform: "rotate(-90deg)" }} /> opent
              in een nieuw venster
            </Text>
          </div>
        }
        trigger="hover"
      >
        <Tag style={{ margin: "0" }}>
          <Text
            style={{
              textDecoration: "underline",
              textDecorationColor: "#02a8e1",
            }}
          >
            {title}
            <ExportOutlined
              style={{
                transform: "rotate(-90deg)",
                color: "#02a8e1",
                marginLeft: "2px",
              }}
            />
          </Text>
        </Tag>
      </Popover>
    </>
  );
};

export default ContentLinkExternalKf;
