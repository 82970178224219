import React, { Component } from "react";
import ReactFromJSON from "react-from-json";
import Node from "./ChartNivSchematicNodes";
import {
  Alert,
  Button,
  Carousel,
  Descriptions,
  Result,
  Row,
  Typography,
} from "antd";
import { Link } from "gatsby";
import * as Scroll from "react-scroll";
import Arrows from "../../../../components/chart/links/arrow/Arrows";
import ContentList from "../../../../components/ui/ContentList";
import OptionsSelectComponent from "../OptionsSelectComponent";
import OptionsItemsPointsComponent from "../OptionsItemsPointsComponent";
import OptionsItemsPointsComponentWithSelect from "../OptionsItemsPointsComponentWithSelect";
import "../../styles/chart-schematic.scss";
import ContentLinkExternalKf from "../../../../components/chart/ui/ContentLinkExternalKf.js";
import ContentLinkExternalFk from "../../../../components/chart/ui/ContentLinkExternalFk.js";
import ContentLinkExternalAcuteBoekje from "../../../../components/chart/ui/ContentLinkExternalAcuteBoekje";
import ContentLinkExternalRldb from "../../../../components/chart/ui/ContentLinkExternalRldb";

const scroller = Scroll.scroller;
const { Paragraph, Text, Title } = Typography;

class ChartNivSchematic extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstNodeId: this.props.firstNodeId,
      isLastOnPath: this.props.firstNodeId,
      isPrevious: 0,
      nodePath: [this.props.firstNodeId],
    };
  }

  // Next Node
  onNext = (nodeId) => {
    const nodeCurrentPosition = this.state.nodePath.indexOf(nodeId);
    if (nodeCurrentPosition === -1) {
      const addNextNode = this.state.nodePath.concat([nodeId]);
      this.setState({
        nodePath: addNextNode,
      });
      const nextNodeId = nodeId.toString();
      this.setState({
        isLastOnPath: nextNodeId,
      });
      this.setState({
        isPrevious:
          this.state.nodePath[this.state.nodePath.length - 1].toString(),
      });
      const windowHeight = window.innerHeight;
      const scrollOffset = 0.33 * windowHeight;
      setTimeout(function () {
        scroller.scrollTo(nextNodeId, {
          duration: 200,
          delay: 75,
          smooth: true,
          offset: -scrollOffset,
          isDynamic: true,
        });
      }, 50);
      if ("parentIFrame" in window) {
        setTimeout(function () {
          window.parentIFrame.scrollToOffset(0, -80);
        }, 125);
      }
    } else {
      this.onGoTo(nodeId);
    }
  };

  // Go To Node
  onGoTo = (nodeId) => {
    const nodeCurrentPosition = this.state.nodePath.indexOf(nodeId);
    if (nodeCurrentPosition !== -1) {
      const goToNode = this.state.nodePath.slice(0, nodeCurrentPosition + 1);
      this.setState({
        nodePath: goToNode,
      });
      const goToNodeId = nodeId.toString();
      this.setState({
        isLastOnPath: goToNodeId,
      });
      const windowHeight = window.innerHeight;
      const scrollOffset = 0.33 * windowHeight;
      setTimeout(function () {
        scroller.scrollTo(goToNodeId, {
          duration: 200,
          delay: 75,
          smooth: true,
          offset: -scrollOffset,
          isDynamic: true,
        });
      }, 50);
      if ("parentIFrame" in window) {
        setTimeout(function () {
          window.parentIFrame.scrollToOffset(0, -80);
        }, 125);
      }
    }
  };

  // Previous Node (back)
  onPrevious = (nodeId) => {
    const nodeCurrentPosition = this.state.nodePath.indexOf(nodeId);
    const previousNode = this.state.nodePath.slice(0, nodeCurrentPosition);
    if (previousNode.length > 0) {
      this.setState({
        nodePath: previousNode,
      });
      const previousNodeId =
        this.state.nodePath[this.state.nodePath.length - 2].toString();
      this.setState({
        isLastOnPath: previousNodeId,
      });
      const windowHeight = window.innerHeight;
      const scrollOffset = 0.33 * windowHeight;
      scroller.scrollTo(previousNodeId, {
        duration: 200,
        delay: 125,
        smooth: true,
        offset: -scrollOffset,
        isDynamic: true,
      });
    }
  };

  // Reset Nodes
  onReset = () => {
    const resetNode = this.state.firstNodeId;
    this.setState({
      nodePath: [resetNode],
    });
    const resetNodeId = this.state.firstNodeId.toString();
    const windowHeight = window.innerHeight;
    const scrollOffset = 0.25 * windowHeight;
    scroller.scrollTo(resetNodeId, {
      duration: 200,
      delay: 125,
      smooth: true,
      offset: -scrollOffset,
      isDynamic: true,
    });
    this.setState({
      isLastOnPath: resetNodeId,
    });
    if ("parentIFrame" in window) {
      setTimeout(function () {
        window.parentIFrame.scrollToOffset(0, -80);
      }, 125);
    }
  };

  render = () => {
    const mapping = {
      chart: ({ nodes }) => <>{nodes}</>,
      node: ({
        alignSelf,
        content,
        disableDefaultOptionsButtons,
        extraContent,
        extraContentTitle,
        isEndNode,
        links,
        nodeId,
        nodeTitle,
        nodeType,
        offset,
        options,
        span,
      }) => (
        <>
          <Node
            alignSelf={alignSelf}
            disableDefaultOptionsButtons={disableDefaultOptionsButtons}
            enableScrolling
            extraContent={extraContent}
            extraContentTitle={extraContentTitle}
            isEndNode={isEndNode}
            isFirstOnPath={this.state.firstNodeId === nodeId}
            isLastOnPath={this.state.isLastOnPath === nodeId}
            isOnPath={this.state.nodePath.indexOf(nodeId) !== -1}
            isPrevious={this.state.isPrevious}
            key={nodeId}
            links={links}
            linksActive={
              this.state.nodePath[this.state.nodePath.length - 1] !== nodeId
                ? this.state.nodePath[this.state.nodePath.indexOf(nodeId) + 1]
                : null
            }
            nodeId={nodeId}
            nodeTitle={nodeTitle}
            nodeType={nodeType}
            offset={offset}
            onGoTo={this.onGoTo}
            onNext={this.onNext}
            onPrevious={this.onPrevious}
            onReset={this.onReset}
            options={options}
            span={span}
          >
            {content}
          </Node>
          <Arrows
            arrowColorActive="#0D6739"
            arrowColorInactive="#dee2e6"
            isPrevious={this.state.isPrevious}
            links={links}
            linksActive={
              this.state.nodePath[this.state.nodePath.length - 1] !== nodeId
                ? this.state.nodePath[this.state.nodePath.indexOf(nodeId) + 1]
                : null
            }
            nodeId={nodeId}
            gridBreak="100%-8"
          />
        </>
      ),
      row: ({ children }) => <>{children}</>,
      col: ({ children }) => <>{children}</>,
      alert: ({ type, message }) => (
        <Alert
          message={message}
          type={type}
          showIcon
          style={{ margin: "15px 0" }}
        />
      ),
      button: ({ text, type, ghost, to, disabled }) => (
        <Paragraph>
          <Button type={type} ghost={ghost} disabled={disabled}>
            <Link to={to}>{text}</Link>
          </Button>
        </Paragraph>
      ),
      carousel: ({ slides }) => (
        <Carousel
          autoplay
          style={{
            height: "400px",
            backgroundColor: "#364d79",
            textAlign: "center",
          }}
          effect="fade"
        >
          {slides}
        </Carousel>
      ),
      description: ({ items }) => (
        <Descriptions
          bordered
          size="small"
          column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
        >
          {items}
        </Descriptions>
      ),
      descriptionItem: ({ label, text }) => (
        <Descriptions.Item label={label} span={3}>
          {text}
        </Descriptions.Item>
      ),
      list: ({ items, size, header }) => (
        <ContentList
          size={size}
          header={header}
          bordered
          items={items}
          style={{ marginTop: "16px", marginBottom: "8px" }}
        />
      ),
      paragraph: ({ children, text }) => (
        <Paragraph>
          {text} {children}
        </Paragraph>
      ),
      result: ({ title, status }) => <Result title={title} status={status} />,
      title: ({ title, level }) => <Title level={level}>{title}</Title>,
      text: ({
        code,
        copyable,
        disabled,
        ellipsis,
        keyboard,
        mark,
        strong,
        text,
        type,
        underline,
      }) => (
        <Text
          code={code}
          copyable={copyable}
          disabled={disabled}
          ellipsis={ellipsis}
          keyboard={keyboard}
          mark={mark}
          strong={strong}
          type={type}
          underline={underline}
        >
          {text}
        </Text>
      ),
      image: ({ src, alt }) => (
        <div
          style={{
            // height: '250px',
            textAlign: "center",
          }}
        >
          <img
            src={src}
            alt={alt}
            style={{ height: "400px", margin: "0 auto" }}
          />
        </div>
      ),
      diagram: ({ src, alt }) => <></>,
      ContentLinkExternalFk: ({ title, url }) => (
        <ContentLinkExternalFk title={title} url={url} />
      ),
      ContentLinkExternalKf: ({ title, url }) => (
        <ContentLinkExternalKf title={title} url={url} />
      ),
      ContentLinkExternalAcuteBoekje: ({ title, url }) => (
        <ContentLinkExternalAcuteBoekje title={title} url={url} />
      ),
      ContentLinkExternalRldb: ({ title, url }) => (
        <ContentLinkExternalRldb title={title} url={url} />
      ),
      OptionsSelectComponent: ({ steps, title }) => (
        <OptionsSelectComponent
          steps={steps}
          title={title}
          onNext={this.onNext}
        />
      ),
      OptionsItemsPointsComponent: ({
        cutoffValue,
        nodeLinkOne,
        nodeLinkOneLabel,
        nodeLinkTwo,
        nodeLinkTwoLabel,
        steps,
        title,
      }) => (
        <OptionsItemsPointsComponent
          cutoffValue={cutoffValue}
          nodeLinkOne={nodeLinkOne}
          nodeLinkOneLabel={nodeLinkOneLabel}
          nodeLinkTwo={nodeLinkTwo}
          nodeLinkTwoLabel={nodeLinkTwoLabel}
          onNext={this.onNext}
          steps={steps}
          title={title}
        />
      ),
      OptionsItemsPointsComponentWithSelect: ({
        cutoffValue,
        nodeLinkOne,
        nodeLinkOneLabel,
        nodeLinkTwo,
        nodeLinkTwoLabel,
        selectOptions,
        steps,
        title,
        title2,
      }) => (
        <OptionsItemsPointsComponentWithSelect
          cutoffValue={cutoffValue}
          nodeLinkOne={nodeLinkOne}
          nodeLinkOneLabel={nodeLinkOneLabel}
          nodeLinkTwo={nodeLinkTwo}
          nodeLinkTwoLabel={nodeLinkTwoLabel}
          onNext={this.onNext}
          selectOptions={selectOptions}
          steps={steps}
          title={title}
          title2={title2}
        />
      ),
    };

    return (
      <>
        <Row align="top" className="chart-schematic-niv">
          <ReactFromJSON entry={this.props.entry} mapping={mapping} />
        </Row>
      </>
    );
  };
}

export default ChartNivSchematic;
