import React from "react";
import { Card, Col, Button, Tooltip, Divider, Tag, Typography } from "antd";
import { CheckOutlined, UndoOutlined } from "@ant-design/icons";
import NodeArrowAnchors from "./components/NodeArrowAnchors";

const { Title } = Typography;

export default function NodeCardEndWithGrid({
  nodeId,
  nodeTitle,
  children,
  onReset,
  span,
  offset,
  nodeType,
  language,
  extraContent,
}) {
  const textButtonRestart = language === "nl" ? "Herstarten" : "Restart";
  const textButtonRestartTooltip =
    language === "nl" ? "Herstart deze chart" : "Restart this chart";

  return (
    <Col
      id={nodeId}
      key={nodeId}
      xs={{ span: 22, offset: 1 }}
      md={{ span: span, offset: offset }}
      className="node-wrapper"
    >
      <Card
        title={
          <>
            <Tag color="#8FB132" style={{ textTransform: "capitalize" }}>
              <CheckOutlined /> {nodeType}{" "}
            </Tag>
          </>
        }
        style={{
          border: "4px solid #8FB132",
          borderRadius: "4px",
          boxShadow:
            "rgba(17, 17, 26, 0.1) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 48px",
          cursor: "crosshair",
        }}
      >
        {children}
        {extraContent && <>{extraContent}</>}
        <Divider
          orientation="left"
          plain
          style={{
            margin: "16px 0 10px 0",
            fontSize: "12px",
            borderColor: "#ced4da",
          }}
        />
        <Tooltip placement="top" title={textButtonRestartTooltip}>
          <Button size="small" onClick={() => onReset()}>
            <UndoOutlined /> {textButtonRestart}
          </Button>
        </Tooltip>
      </Card>
      <NodeArrowAnchors nodeId={nodeId} />
    </Col>
  );
}
